export default [
//  { roleId: 0, title: 'Süper Admin Hesabı'},
 { roleId: 1, title: 'İşyeri Sahibi Hesabı'},
 { roleId: 2, title: 'Yönetici Hesabı'},
 { roleId: 3, title: 'Çalışan Hesabı'},
 { roleId: 4, title: 'Personel Hesabı'}
]


// süper admin 0
// admin 1
// manager 2
// employee 3
// tyro 4