import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { JustDate } from '../../../plugins/SET_DATE_FORMATTER'
import Loading from '../../shared/loading'
import { theme } from '../../../plugins/select.theme'
import { GET_CUSTOMER_LIST } from '../../../store/module/customers/action'

import AddCustomer from '../Customers/AddCustomer'
import CustomerTags from '../Customers/CustomerTags'
import { GET_REMAIN_INFO_OF_ACTIVE_COMPANY } from '../../../store/module/company/action'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import PaymentActions from './PaymentActions'
export const Room = (props) => {
    let [EditRezervationLoading, setEditRezervationLoading] = useState(true);
    let [CustomerTagModal, setCustomerTagModal] = useState(false);
    let [EmptyRezModal, setEmptyRezModal] = useState(false);
    let [RezervationLoading, setRezervationLoading] = useState(false);
    let [LastAvailableRezervationTime, setLastAvailableRezervationTime] = useState(0);
    const [SelectedProcessing, setSelectedProcessing] = useState(null);
    let [customerItem, setCustomerItem] = useState(null)
    let [alertBox, setAlertBox] = useState({ status: false, message: '', variant: 'danger' });
    let [times, setTimes] = useState([]);
    const [room, setRoom] = useState(props.room)
    const [AddCustomerModal, setAddCustomerModal] = useState(false);
    let [IsLoadingAction, setIsLoadingAction] = useState(false)
    let [confirmMessage, setConfirmMessage] = useState({
        status: false,
        message: '',
        variant: 'danger',
        method: 'delete',
        obj: null
    })
    const [EditRezervation, setEditRezervation] = useState({
        statusRezervation: false,
        "RoomId": props.room.Id,
        "CustomerId": -1,
        "Note": "",
        "Price": null,
        "Duration": Number(props.room.Interval),
        "IsCanceled": 0,
        "CancelText": null,
        "Processes": "",
        "RezervationDate": "",
        "RezervationTime": 0,
        "Currency": ''
    })
    useEffect(() => {
        if (props.RezervationModal) {
            setEditRezervationLoading(true);
            setTimes(props.times);
            setRoom(props.room);
            setTimeout(() => {
                SHOW_REZERVATION_DETAIL(props.item, props.index)
            }, 150);
        }
    }, [props.RezervationModal])
    async function SEND_EMPTY_REZERVATION() {
        setAlertBox({
            variant: 'danger',
            status: false,
            message: ''
        })
        setRezervationLoading(true)
        const response = await window.$api.post('Rezervations/Create', {
            statusRezervation: false,
            "RoomId": props.room.Id,
            "CustomerId": null,
            "Note": "",
            "Price": 0,
            "Duration": Number(props.room.Interval),
            "IsCanceled": 0,
            "CancelText": null,
            "Processes": "",
            "RezervationDate": "2022-06-15",
            "RezervationTime": EditRezervation.time
        })
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Rezzervasyonunuz başarıyla kayıt edildi. Oda rezzervasyonları yenileniyor. Lütfen bekleyin...'
            })
            let date = "2022-06-15"
            try {
                let params = (new URL(document.location)).searchParams;
                let dateQuery = params.get("date");
                if (dateQuery !== null) {
                    date = JustDate(dateQuery)
                }
            } catch (error) {
                date = "2022-06-15"
            }
            // props.GET_REZERVATION_BY_ROOM(date, props.room.Id);
            setTimeout(() => {
                setEmptyRezModal(false);
                props.setRezervationModal(false)
                setAlertBox({
                    variant: 'danger',
                    status: false,
                    message: ''
                })
            }, 2500);
        } else {
            setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Rezzervasyonunuz eklenemedi. Lütfen sayfayı yenileyip tekrar deneyin.'
            })
        }
        setRezervationLoading(false)

    }
    function SET_CUSTOMER(e) {
        setCustomerItem(e);
    }
    function CALCULATE_END_REZERVATION_DATE() {
        if (!EditRezervation.statusRezervation) return '...'
        else return convertM2H(EditRezervation.RezervationTime + EditRezervation.Duration)
    }
    async function GET_CUSTOMER_LIST_W_SEARCH(search, callback) {

        const response = await window.$api.get(`Customers/GetAll?searchKey=${search}`)
        if (response.message === 'OK' && response.result === 'OK') {
            callback(response.data)
        } else callback()
    }
    function CHECK_AVAILABLE_REZERVATION() {
        return ((EditRezervation.RezervationTime + EditRezervation.Duration) <= LastAvailableRezervationTime)
    }
    function convertH2M(timeInHour) {
        var timeParts = timeInHour.split(":");
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }
    function GUESS_PROCESSING_DURATION(e) {
        return e === null || e.length === 0 ? props.room.Interval : e.map(function (a) { return a.Duration; })
            .reduce(function (a, b) { return a + b; })
    }
    function GUESS_PROCESSING_PRICE(e) {
        return e === null || e.length === 0 ? props.room.Interval : e.map(function (a) { return a[`Price${EditRezervation.Currency}`]; })
            .reduce(function (a, b) { return a + b; }).toFixed(2)
    }
    function SET_SELECTED_PROCESSING(e) {
        setSelectedProcessing(e);
        setEditRezervation({
            ...EditRezervation,
            Price: GUESS_PROCESSING_PRICE(e),
            Duration: GUESS_PROCESSING_DURATION(e)
        })
    }
    function SET_STATE_NEW_REZERVATION(key, val) {
        setEditRezervation({
            ...EditRezervation,
            [key]: val
        })
    }
    function convertM2H(n) {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return (rhours < 10 ? `0${rhours}` : rhours) + ":" + (rminutes < 10 ? `0${rminutes}` : rminutes);
    }
    function SET_REZERVATION(data) {
        const timeData = data.timeData;
        const index = data.index
        setCustomerItem(timeData.rez.Customer);
        setSelectedProcessing(timeData.rez.ProcessesData)
        setEditRezervation({ ...EditRezervation, statusRezervation: true, ...timeData, ...timeData.rez, Price: timeData.rez.Price.toFixed(2) });
        const arr = [...times]
        arr.splice(0, index + 1)
        const result = arr.filter(a => a.status);
        console.log("evet burası result", result, times);
        if (result.length !== 0) {
            setLastAvailableRezervationTime(result[0].rez.RezervationTime); // bir sonraki rezzervasyona kadar rezzervasyona izin ver.
        } else {
            setLastAvailableRezervationTime(convertH2M(room.ClosedAt)); // firma kapanış saatine kadar olan saate kadar rezzervasyon kabul et.
        }
        // setLastAvailableMinute()
        props.setRezervationModal(true);
        setTimeout(() => {
            setEditRezervationLoading(false)
        }, 500);
    }
    function SHOW_REZERVATION_DETAIL(item, i) {
        setAlertBox({
            variant: 'danger',
            status: false,
            message: ''
        })
        setConfirmMessage({
            ...confirmMessage,
            status: false,
        })
        if (!item.disabled)
            SET_REZERVATION(item, i)
        else return
    }
    async function SEND_UPDATE_REZ_W_CONFIRM_MESSAGE() {
        setIsLoadingAction(true)
        const response = await window.$api[confirmMessage.method](`Rezervations/${EditRezervation.Id}`, confirmMessage.obj);
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Rezzervasyonunuz başarıyla kayıt edildi. Oda rezzervasyonları yenileniyor. Lütfen bekleyin...'
            })
            props.GET_REZERVATION_BY_ROOM(EditRezervation.RezervationDate.split('T')[0], props.room.Id)
            if (confirmMessage.obj.IsCompleted) {
                props.GET_REMAIN_INFO_OF_ACTIVE_COMPANY(props.company, props.history);

            }
            setTimeout(() => {
                props.setRezervationModal(false)
            }, 2500);
        } else {
            setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Rezzervasyonunuz eklenemedi. Lütfen sayfayı yenileyip tekrar deneyin.'
            })
        }
        setIsLoadingAction(false)
    }
    return (
        <Modal size={confirmMessage.status ? 'md' : 'xl'} centered className="fade" onHide={() => props.setRezervationModal(false)} show={props.RezervationModal}>
            <Modal.Header>
                <Modal.Title> <i className='flaticon-381-bookmark'></i> Rezzervasyon Detayı</Modal.Title>
                <button
                    className="btn btn-close"
                    onClick={() => props.setRezervationModal(false)}
                >

                </button>
            </Modal.Header>
            <Modal.Body>
                {EditRezervationLoading ? <div className='text-center'>
                    <Loading description='Rezzervasyon Yükleniyor..' variant='primary'></Loading>
                </div> :
                    confirmMessage.status ? <div>
                        {
                            alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                        }
                        <div>
                            {confirmMessage.message}
                        </div>
                        {
                            confirmMessage.variant === 'danger' ?
                                <div className='form-group my-4'>
                                    <label>Rezzervasyon İptal Mesajı (SMS)</label>
                                    <textarea maxLength={250} disabled value={confirmMessage.obj.CancelText} type="text" onInput={(e) => setConfirmMessage({
                                        ...confirmMessage,
                                        obj: {
                                            ...confirmMessage.obj,
                                            CancelText: e.target.value
                                        }
                                    })} placeholder='Rezzervasyonun iptal sebebi varsa buraya girin...' className='form-control'></textarea>
                                    <div className='d-flex justify-content-end text-light'> <small>{confirmMessage.obj.CancelText.length}/250</small></div>
                                    <div> <small>* Rezzervasyon iptal sebebi müşteriye kısa mesaj olarak gönderilecektir.</small></div>
                                </div> : ''
                        }
                        {
                            alertBox.status ? <></> :
                                <div className='d-flex align-items-end justify-content-end mt-4'>
                                    <button onClick={() => setConfirmMessage({
                                        ...confirmMessage,
                                        status: false,
                                    })} className={`btn btn-light mx-2`}>İptal Et</button>
                                    <button disabled={IsLoadingAction} onClick={() => SEND_UPDATE_REZ_W_CONFIRM_MESSAGE()} className={`d-flex btn btn-${confirmMessage.variant}`}>
                                        {
                                            IsLoadingAction ? <Loading size="sm"></Loading> : ''
                                        }
                                        Güncelle
                                    </button>
                                </div>
                        }
                    </div> :
                        <div>
                            <Modal centered className="fade" onHide={() => setEmptyRezModal(false)} show={EmptyRezModal}>
                                <Modal.Header>
                                    <Modal.Title> <i className='flaticon-381-bookmark'></i> Rezzervasyon Kapatma </Modal.Title>
                                    <button
                                        className="btn btn-close"
                                        onClick={() => setEmptyRezModal(false)}
                                    >

                                    </button>
                                </Modal.Header>
                                <Modal.Body>
                                    {
                                        alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                                    }
                                    <div className='row'>
                                        <div className='col-6'>
                                            <small className='text-light'>Başlangıç Saati</small>
                                            <h2>
                                                <span className='text-primary'>{convertM2H(EditRezervation.RezervationTime)}</span>
                                            </h2>
                                        </div>
                                        <div className='col-6'>
                                            <small className='text-light'>Ort. Bitiş Saati</small>
                                            <h2>
                                                <span className='text-primary'>{CALCULATE_END_REZERVATION_DATE()}</span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        Yukarıdaki saatler arasını rezzervasyona kapatmak üzeresiniz. Bu işlemi gerçekleştirmek istiyor musunuz?
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button
                                        onClick={() => setEmptyRezModal(false)}
                                        className='btn btn-light'>Kapat</button>
                                    <button onClick={() => SEND_EMPTY_REZERVATION()} disabled={RezervationLoading} className='btn btn-secondary d-flex'>
                                        {
                                            RezervationLoading ? <Loading size="sm"></Loading> : ''
                                        }
                                        Evet, Rezzervasyona kapat</button>
                                </Modal.Footer>
                            </Modal>
                            {
                                EditRezervation.statusRezervation ? <div className='container-fluid'>
                                    <div className='row justify-content-center align-items-start'>
                                        <div className='col-12 col-lg-9 col-xl-8 position-sticky' style={{ top: '0px' }}>
                                            {
                                                EditRezervation.CustomerPackage === null || EditRezervation.CustomerPackage === undefined ?
                                                    '' : <div className='alert alert-secondary mt-4 text-center'>
                                                        <strong>Önemli Duyuru: </strong>
                                                        Müşteriye <strong>{EditRezervation.CustomerPackage.Detail} </strong> ile rezzervasyon oluşturulmuştur.
                                                    </div>
                                            }
                                            {
                                                EditRezervation.IsCanceled === 0 && EditRezervation.IsCompleted === 0 ?
                                                    <div>
                                                        <div className='alert alert-danger mt-3'>
                                                            {
                                                                EditRezervation.IsCanceled === 0 ?
                                                                    ' Eğer rezzervasyon iptal edildiyse aşağıdan rezzervasyonu iptal etmeyi unutmayın.' :
                                                                    'Aşağıdaki rezzervasyon iptal edilmiştir.'
                                                            }
                                                        </div>
                                                        {
                                                            EditRezervation.IsCanceled === 0 ?
                                                                <div className='d-flex align-items-end justify-content-end mb-4'>
                                                                    <button onClick={() => setConfirmMessage({
                                                                        status: true,
                                                                        message: 'Rezzervasyonu iptal etmek üzeresiniz. İptal edilen rezzervasyon yerine yeni rezzervasyon eklenebilir. Rezzervasyonu iptal etmek istiyor musunuz?',
                                                                        query: `customerId=${customerItem.Id}`,
                                                                        obj: {
                                                                            "IsCanceled": 1,
                                                                            "CustomerId": customerItem.Id,
                                                                            "CancelText": `Sayın ${customerItem.Fullname}, ${props.company.Name} firmamızdan ${JustDate(EditRezervation.RezervationDate)} Saat: ${convertM2H(EditRezervation.RezervationTime)} tarihinde oluşturulan rezzervasyonunuz iptal edilmiştir. Detaylı bilgi için firmamız ile iletişime geçebilirsiniz.`,
                                                                            "Processes": EditRezervation.ProcessesData
                                                                        },
                                                                        method: 'put',
                                                                        variant: 'danger'
                                                                    })} className='btn btn-danger btn-sm'>Rezzervasyonu İptal Et</button>
                                                                </div> : ''
                                                        }
                                                    </div> : ''
                                            }
                                            <div className='form-group my-4'>
                                                <label>Müşterinizi Seçin </label>
                                                <AsyncSelect
                                                    theme={theme}
                                                    isDisabled={props.CustomerNotChangable}
                                                    defaultValue={customerItem}
                                                    placeholder="Tel no veya isime göre arayabilirsiniz.."
                                                    cacheOptions
                                                    getOptionLabel={(opt) => opt.Fullname}
                                                    noOptionsMessage={() => 'Müşteri arayın..'}
                                                    loadOptions={GET_CUSTOMER_LIST_W_SEARCH}
                                                    defaultOptions
                                                    onChange={(e) => SET_CUSTOMER(e)}
                                                />
                                                <div> <small>* Telefon numarası veya Müşteri isim ve soyisimi ile arayabilirsiniz.</small></div>
                                            </div>
                                            {
                                                customerItem !== null ?
                                                    <div>
                                                        <label>Müşteriye uygulanacak hizmetleri seçin</label>

                                                        <Select
                                                            defaultValue={SelectedProcessing}
                                                            placeholder="Odanınızın Hizmetlerini Seçin"
                                                            isMulti={true}
                                                            name="Fullname"
                                                            isSearchable={false}
                                                            onChange={(e) => SET_SELECTED_PROCESSING(e)}
                                                            options={props.processingList}
                                                            getOptionLabel={(option) => <div>    <i className="flaticon-041-graph"></i> {option.Name} ({option.Duration}dk.) - <span className='text-success'>₺{option[`Price${EditRezervation.Currency}`].toFixed(2)}</span> </div>}
                                                            getOptionValue={(val) => val.Id}
                                                            theme={theme}
                                                        >
                                                        </Select>
                                                        {
                                                            SelectedProcessing !== null && SelectedProcessing.length !== 0 ?
                                                                <div>
                                                                    <div className='alert alert-secondary mt-3'>
                                                                        Seçmiş olduğunuz hizmetler ile işlemleriniz toplam süresi <strong className='text-success'>{GUESS_PROCESSING_DURATION(SelectedProcessing)}dk</strong> olacaktır.
                                                                    </div>
                                                                    <div className='alert alert-secondary mt-3'>
                                                                        Seçmiş olduğunuz hizmetler ile işlemleriniz toplam ücreti <strong className='text-success'>{GUESS_PROCESSING_PRICE(SelectedProcessing)} {EditRezervation.Currency}</strong> olacaktır.
                                                                    </div>
                                                                    <div className='mt-2'><small>* Aşağıdan bu rezzervasyona özel <span className='text-primary'>süreyi</span> ve <span className='text-primary'>ücretini</span> değiştirebilirsiniz.</small></div>
                                                                    <div> <small>Hizmet sürelerini ve ücretlerini <Link className='text-primary' to={`/${props.company.Id}/process`} >'Hizmetlerim'</Link> sayfasından değiştirebilirsiniz.</small></div>

                                                                </div> : <div className='mt-2 text-danger'><small>* En az 1 adet hizmet seçmeniz gerekmektedir.</small></div>

                                                        }
                                                        <div className='row'>
                                                            <div className='col-12 col-lg-6'>
                                                                {
                                                                    EditRezervation.CustomerPackage === null || EditRezervation.CustomerPackage === undefined ?
                                                                        <div className='form-group my-4'>
                                                                            <label>Rezzervasyon Ücreti ({EditRezervation.Currency})</label>
                                                                            <input value={EditRezervation.Price} type="number" onInput={(e) => SET_STATE_NEW_REZERVATION('Price', e.target.value > -1 ? Number(e.target.value).toFixed(2) : null)} placeholder='Rezzervasyon fiyatı girin..' className='form-control'></input>
                                                                        </div> : <div className='alert alert-secondary mt-4 text-center'>
                                                                            Paket seçtiğiniz için müşteri ödeme yapmayacaktır.
                                                                        </div>
                                                                }
                                                            </div>
                                                            <div className='col-12 col-lg-6'>
                                                                <div className='form-group my-4'>
                                                                    <label>Rezzervasyon Süreci (dk)</label>
                                                                    <input value={EditRezervation.Duration} type="number" onInput={(e) => SET_STATE_NEW_REZERVATION('Duration', Number(e.target.value))} placeholder='Rezzervasyon süresini girin..' className='form-control'></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            GUESS_PROCESSING_PRICE(SelectedProcessing) !== EditRezervation.Price || GUESS_PROCESSING_DURATION(SelectedProcessing) !== EditRezervation.Duration ?
                                                                <div> <a href='#'><small className='text-primary' onClick={() => SET_SELECTED_PROCESSING(SelectedProcessing)}> - Toplam Hizmet ücretini ({GUESS_PROCESSING_PRICE(SelectedProcessing)} {EditRezervation.Currency}) ve Toplam Hizmet Süresini ({GUESS_PROCESSING_DURATION(SelectedProcessing)}dk.) hizmetlere göre ayarlamak için tıklayın. </small></a></div> : ''
                                                        }
                                                        {
                                                            CHECK_AVAILABLE_REZERVATION() ?
                                                                '' :
                                                                <div className='alert alert-danger'>
                                                                    Bir sonraki rezzervasyon veya Oda kapanış saatiniz: <strong className='mx-3' style={{ fontSize: '20px' }}>{convertM2H(LastAvailableRezervationTime)}</strong>
                                                                    <div className='mt-2'>Oluşturmak istediğiniz rezzervasyonun
                                                                        bitiş saati <strong className='mx-3' style={{ fontSize: '20px' }}> {CALCULATE_END_REZERVATION_DATE()}</strong></div>

                                                                    <div className='mt-2'>Lütfen Rezzervasyon Saatini  değiştirin veya rezzervasyon sürecini kısaltın. Diğer durumlarda rezzervasyon oluşturamayacaksınız !!!</div>
                                                                </div>
                                                        }
                                                        <div className='form-group my-4'>
                                                            <label>Rezzervasyon Notu</label>
                                                            <textarea maxLength={250} value={EditRezervation.Note} type="text" onInput={(e) => SET_STATE_NEW_REZERVATION('Note', e.target.value)} placeholder='Rezzervasyon notu varsa girin..' className='form-control'></textarea>
                                                            <div className='d-flex justify-content-end text-light'> <small>{EditRezervation.Note.length}/250</small></div>
                                                            <div> <small>* Rezzervasyon notunuz en fazla 250 karakter olabilir.</small></div>

                                                        </div>
                                                        {
                                                            EditRezervation.IsCanceled === 0 && EditRezervation.IsCompleted === 0 && !CHECK_AVAILABLE_REZERVATION() ?
                                                                <div className='d-flex align-items-end justify-content-end'>
                                                                    <button disabled={RezervationLoading} className='btn btn-light btn-xxl mx-2'>
                                                                        Kapat
                                                                    </button>
                                                                    <button onClick={() => setConfirmMessage({
                                                                        status: true,
                                                                        message: 'Rezzervasyonunuzu güncellemek üzeresiniz. Bunu yapmak istediğinize emin misiniz?',
                                                                        query: ``,

                                                                        obj: {
                                                                            "CustomerId": customerItem.Id,
                                                                            "Note": EditRezervation.Note,
                                                                            "Duration": EditRezervation.Duration,
                                                                            CustomerId: customerItem.Id,
                                                                            Processes: SelectedProcessing.map(s => s.Id).join(','),
                                                                            Price: Number(EditRezervation.Price),
                                                                            RezervationDate: "2022-06-15",
                                                                            "RezervationTime": EditRezervation.RezervationTime
                                                                        },
                                                                        method: 'put',
                                                                        variant: 'success'
                                                                    })} className='btn btn-secondary mx-2 d-flex'>
                                                                        {
                                                                            IsLoadingAction ? <Loading size="sm"></Loading> : ''
                                                                        } Rezzervasyonu Güncelle
                                                                    </button>
                                                                </div> : ''
                                                        }
                                                    </div>
                                                    : <div className='alert mt-4 alert-secondary'>
                                                        <div className='text-center '>Rezzervasyon kaydı yapmak için öncelikle müşteriyi seçmelisiniz.</div>
                                                        <div className='text-center mt-4'>
                                                            Eğer yeni müşteri eklemek istiyorsanız
                                                        </div>
                                                        <div className='text-center mt-2'>
                                                            <button onClick={() => setAddCustomerModal(true)} className='btn btn-secondary btn-sm'>Hemen Yeni Müşteri Ekle</button>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <div className='col-12 col-lg-3 col-xl-4 bg-white' style={{ zIndex: 1 }}>
                                            {
                                                (EditRezervation.CustomerPackage === null || EditRezervation.CustomerPackage === undefined) && EditRezervation.IsCompleted === 0 ? <PaymentActions rezervation={EditRezervation}></PaymentActions> : ''
                                            }
                                            {
                                                EditRezervation.IsCompleted === 0 ?
                                                    <button onClick={() => setConfirmMessage({
                                                        status: true,
                                                        message: 'Rezzervasyonunuz tamamlandı ve ödemesi alındı olarak güncelliyorsunuz. Güncelledikten sonra rezzervasyon ücreti kazançlarınıza eklenecektir. Bunu yapmak istediğinize emin misiniz?',
                                                        query: ``,
                                                        obj: {
                                                            "IsCompleted": 1,
                                                            "CancelText": "",
                                                            "Processes": EditRezervation.ProcessesData
                                                        },
                                                        method: 'put',
                                                        variant: 'success'
                                                    })} className='btn btn-block my-3 btn-success'>Ödemeyi Al ({EditRezervation.Price} {EditRezervation.Currency})</button>
                                                    :
                                                    <button disabled className='btn btn-block my-3 btn-success'>Ödemesi Alındı</button>
                                            }
                                            <div className='mt-2 alert alert-secondary'>
                                                * 'Ödeme Al' yapılmayan rezzervasyonlar tamamlandı olarak görünmeyecek ve Firma kazançlarına eklenmeyecektir.
                                            </div>

                                            <h4>Özet</h4>
                                            <div>
                                                <div className='card my-1'>
                                                    <div className='card-body'>
                                                        <h6>Rezzervasyon</h6>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <small className='text-light'>Rezzervasyon Günü</small>
                                                                <h3>
                                                                    <span className='text-primary'>{JustDate(EditRezervation.RezervationDate)}</span>
                                                                </h3>
                                                            </div>
                                                            <div className='col-6'>
                                                                <small className='text-light'>Başlangıç Saati</small>
                                                                <h2>
                                                                    <span className='text-primary'>{convertM2H(EditRezervation.RezervationTime)}</span>
                                                                </h2>
                                                            </div>
                                                            <div className='col-6'>
                                                                <small className='text-light'>Ort. Bitiş Saati</small>
                                                                <h2>
                                                                    <span className='text-primary'>{CALCULATE_END_REZERVATION_DATE()}</span>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <small>* Rezzervasyon başlangıç saati seçtiğiniz saattir. </small>
                                                        </div>
                                                        <div className='alert alert-secondary mt-2 d-flex align-items-center'>
                                                            * Bir sonraki Rezzervasyon veya Oda Kapanış Saati: <span className='mx-2' style={{ fontSize: '2rem' }}> {convertM2H(LastAvailableRezervationTime)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card my-2'>
                                                    <div className='card-body'>
                                                        <Modal centered className="fade" onHide={() => setCustomerTagModal(false)} show={CustomerTagModal}>
                                                            <Modal.Header>
                                                                <Modal.Title>Müşteri Etiketleri</Modal.Title>
                                                                <button
                                                                    className="btn btn-close"
                                                                    onClick={() => setCustomerTagModal(false)}
                                                                >

                                                                </button>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <CustomerTags customer={customerItem}></CustomerTags>
                                                            </Modal.Body>
                                                        </Modal>
                                                        <h6>Müşteri Bilgisi</h6>
                                                        {
                                                            customerItem === null ? <div className='text-center my-4'>
                                                                <span>Henüz müşteri seçmediniz..</span>
                                                            </div> :
                                                                <div>
                                                                    <p> Adı Soyadı: {customerItem.Fullname}</p>
                                                                    <p> Tel. No.: {customerItem.Phone} </p>
                                                                    {
                                                                        !customerItem.LastRezervation || customerItem.LastRezervation === null ? ' Son Rezzervasyon: Müşterinin rezzervasyonu bulunamadı.' :
                                                                            <div>Son Rezzervasyon: {customerItem.LastRezervation.RezervationDate.includes('0001-01-01') ? 'Rezzervasyon bulunamadı.' : <div>{JustDate(customerItem.LastRezervation.RezervationDate)} {convertM2H(customerItem.LastRezervation.RezervationTime)}</div>}</div>
                                                                    }
                                                                    <div className='mt-4'>
                                                                        <button onClick={() => setCustomerTagModal(true)} className='btn-sm btn btn-secondary btn-block'>Müşteri Etiketleri</button>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='card my-2'>
                                                    <div className='card-body'>
                                                        <h6>Hizmetler</h6>
                                                        {
                                                            SelectedProcessing === null || SelectedProcessing.length === 0 ? '' : SelectedProcessing.map((p, i) => (
                                                                <span key={i} className='badge badge-primary m-1 light'>{p.Name}</span>
                                                            ))
                                                        }                                                <div>   <small>* Hizmetler sizin için ortalama rezzervasyon süresi ve ücreti çıkartacaktır.</small></div>
                                                        <div>   <small>* Bu süreyi ve ücreti değiştirebilirsiniz.</small></div>
                                                    </div>
                                                </div>

                                                {
                                                    EditRezervation.Price === null ? '' :
                                                        <div>
                                                            <div className='card'>
                                                                <div className='card-body'>
                                                                    <h6>Toplam Ücret</h6>
                                                                    {
                                                                        EditRezervation.CustomerPackage === null || EditRezervation.CustomerPackage === undefined ?
                                                                            GUESS_PROCESSING_PRICE(SelectedProcessing) === EditRezervation.Price ?
                                                                                <h2>
                                                                                    <span className='text-success'>{EditRezervation.Price} {EditRezervation.Currency}</span>
                                                                                </h2> :
                                                                                <div>
                                                                                    <div>
                                                                                        <span className='text-danger old-price'>{GUESS_PROCESSING_PRICE(SelectedProcessing)} {EditRezervation.Currency}</span>
                                                                                    </div>
                                                                                    <h2>
                                                                                        <span className='text-success'>{EditRezervation.Price} {EditRezervation.Currency}</span>
                                                                                    </h2>
                                                                                </div> :
                                                                            <div className='alert alert-secondary'>Müşteri <strong>{EditRezervation.CustomerPackage.Detail}</strong> ile rezzervasyon oluşturdu.</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> : <div className='text-center my-4'>Rezzervasyon bulunamadı.</div>
                            }
                            {
                                alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                            }
                            <Modal centered className="fade" onHide={() => setAddCustomerModal(false)} show={AddCustomerModal}>
                                <Modal.Header>
                                    <Modal.Title> <i className='flaticon-381-bookmark'></i> Yeni Müşteri Ekle </Modal.Title>
                                    <button
                                        className="btn btn-close"
                                        onClick={() => setAddCustomerModal(false)}
                                    >

                                    </button>
                                </Modal.Header>
                                <Modal.Body>
                                    <AddCustomer></AddCustomer>
                                </Modal.Body>
                            </Modal>
                        </div>
                }
            </Modal.Body>
        </Modal>
    )
}


const mapStateToProps = (state) => ({
    processingList: state.processing.processingList,
    company: state.company.activeCompany

})

const mapDispatchToProps = { GET_CUSTOMER_LIST, GET_REMAIN_INFO_OF_ACTIVE_COMPANY }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Room))