import React, { useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../../shared/loading'
import { SET_UPDATE_CUSTOMERS_TAG, SET_CUSTOMERS_TAG_LOADING, SET_ALERT_BOX } from '../../../../store/module/customersTag/action'
export const InsertProcessing = (props) => {
    const [item, setItem] = useState(props.item)
    const index = props.index;
    const UPDATE_IT = () => {
        if (item.Name === '' || item.Name.length > 50)
            return props.SET_ALERT_BOX({
                status: 'update',
                variant: 'danger',
                message: 'Tüm alanları eksiksiz doldurmanız gerekmektedir.'

            })
        props.SET_UPDATE_CUSTOMERS_TAG({ Name: item.Name, Id: item.Id }, index);
    }
    return (
        <div>
            <div>
                <div className='alert alert-secondary'>
                    * Kayıt edeceğiniz Etiketler rezzervasyon oluşturulurken size işlemin süresini ve toplam ücretini otomatik dolduracaktır.
                </div>
                <div className='form-group my-4'>
                    <input value={item.Name} type="text" onInput={(e) => setItem({ ...item, Name: e.target.value })} placeholder='Etiket Adı *' className='form-control'></input>
                    <div className='d-flex justify-content-end text-light'> <small>{item.Name.length}/50</small></div>
                    <div> <small>* Örn: Çay Sever, Kahve Sever, Siyaset konuşmayı sevmez vs.</small></div>
                    <div> <small>* Etiket Adı en fazla 50 karakter olmalıdır.</small></div>
                    {
                        item.Name.length < 1 && props.alertBox.status !== 'update' ? (<div className='alert alert-danger mt-2'> <small>* Etiket adı girmeniz zorunludur.</small></div>) : ''
                    }
                </div>            {
                    props.alertBox.status === 'update' ?
                        <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                }
                <div className='mt-4 d-flex justify-content-end'>
                    <button onClick={() => UPDATE_IT()} disabled={props.isLoading} className='btn btn-secondary d-flex'>
                        {
                            props.isLoading ?
                                <Loading size="sm"></Loading>
                                : ''
                        } Güncelle
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.customersTag.isLoadingUpdate,
    alertBox: state.customersTag.alertBox
})

const mapDispatchToProps = { SET_UPDATE_CUSTOMERS_TAG, SET_CUSTOMERS_TAG_LOADING, SET_ALERT_BOX }

export default connect(mapStateToProps, mapDispatchToProps)(InsertProcessing)