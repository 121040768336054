import React from 'react'
import { connect } from 'react-redux'
import InsertProcessing from './components/InsertProcessing'
import ListOfProcessing from './components/ListOfProcessing'

export const Processing = (props) => {

  return (
    <div className='container-fluid'>
      <div className='row justfiy-content-center align-items-start'>
        {
          props.activeUser.RoleId === 4 ? '' :
            <div className='col-12 col-lg-5 col-xl-4'>
              <InsertProcessing></InsertProcessing>
            </div>
        }
        <div className={`col-12 col-lg-${props.activeUser.RoleId === 4 ? '12' : '7'} col-xl-${props.activeUser.RoleId === 4 ? '12' : '8'}`}>
          <ListOfProcessing></ListOfProcessing>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  activeUser: state.user.activeUser.User

})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Processing)