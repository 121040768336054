import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PhoneInput from 'react-phone-number-input/input'
import Loading from '../../shared/loading'
import { SEND_NEW_CUSTOMER_TO_DB, SET_ALERT_BOX } from '../../../store/module/customers/action'
export const AddCustomer = (props) => {
    const [clickedButton, setClickedButton] = useState(false)
    const [Customer, setCustomer] = useState({
        Fullname: "",
        Phone: "",
        Note: "",
        Birthday: null
    })
    useEffect(() => {
        if (!props.isLoadingAction && props.modal && clickedButton) {
            setTimeout(() => {
                props.success(false);
                setClickedButton(false)
            }, 2500);
        }
    }, [
        props.isLoadingAction
    ])
    function SET_STATE_EDIT_CUSTOMER(key, val) {
        setCustomer({
            ...Customer,
            [key]: val
        })
    }
    function SEND_CUSTOMER_LIST_TO_DB() {
        props.SET_ALERT_BOX({
            variant: 'danger',
            status: false,
            message: ''
        })
        if (Customer.Fullname === '' || Customer.Phone.length < 13)
            return props.SET_ALERT_BOX({
                variant: 'danger',
                status: true,
                message: 'Tüm alanları eksiksiz doldurmalısın. Lütfen bilgileri kontrol edin.'
            })
        let CustomerData = Customer;
        if (Customer.Birthday === null) {
            delete CustomerData.Birthday
        }
        setClickedButton(true);
        props.SEND_NEW_CUSTOMER_TO_DB(CustomerData)
    }
    return (
        <div>
            <div className='form-group my-4'>
                <label>Müşterinin Adı ve Soyadı</label>
                <input maxLength={50} value={Customer.Fullname} type="text" onInput={(e) => SET_STATE_EDIT_CUSTOMER('Fullname', e.target.value)} placeholder='Örn. Gürhan Arslan' className='form-control'></input>
                <div className='d-flex justify-content-end text-light'> <small>{Customer.Fullname.length}/50</small></div>
                <div> <small>* Müşterinin Adı ve Soyadı girilmesi zorunludur.</small></div>
                <div> <small>* Müşterinin Adı ve Soyadı en fazla 50 karakter olabilir.</small></div>
                <p className='alert alert-secondary mt-2'> Müşteri Adı ve Soyadı bilgisini doğru girmeniz müşterilerinizi takip etmeniz için önemli olduğunu unutmayın!</p>
            </div>
            <div className='form-group my-4'>
                <label>Müşteri Notu</label>
                <textarea maxLength={240} value={Customer.Note} type="text" onInput={(e) => SET_STATE_EDIT_CUSTOMER('Note', e.target.value)} placeholder='Örn. Bir sonraki rezzervasyonda saçlarını sarıya boyamak istiyor.' className='form-control'></textarea>
                <div className='d-flex justify-content-end text-light'> <small>{Customer.Note.length}/240</small></div>
                <div> <small>* Müşteri Notu en fazla 240 karakter olabilir.</small></div>
                <p className='alert alert-secondary mt-2'> Müşteri Notu, müşterilerini firma çalışanları için hatırlatıcı bir not alanıdır. Bu kısım müşterinin oluşturduğu tüm rezzervasyonlarda firma çalışanlarına gösterilecektir.</p>
            </div>
            <div className='form-group my-4'>
                <label>Müşteri Telefon Numarası</label>
                <div className="input-group mb-3 ">
                    <span className="input-group-text">+90</span>
                    <PhoneInput
                        country="TR"
                        className="form-control"
                        placeholder="Örn. 0506 123 56 67"
                        value={Customer.Phone}
                        maxLength="14"
                        onChange={(e) => SET_STATE_EDIT_CUSTOMER('Phone', e)} />
                </div>

                <div> <small>* Müşterinin Telefon Numarası girilmesi zorunludur.</small></div>
                <div> <small>* Müşterinin Telefon Numarası başındaki 0 ile birlikte giriniz.</small></div>
                <div> <small>* Müşterinin Telefon Numarası kişisel cep telefonu olmalıdır. Girilen telefon numarası ile SMS işlemleri olacaktır.</small></div>
                <div> <small>* Müşteri Telefon Numaranız <small className='text-primary'>Türkiye formatlarında</small> olmasına dikkat ediniz.</small></div>
                <p className='alert alert-secondary mt-2'> Müşterinin telefonuna SMS ile bilgilendirmeler yapılacaktır. Bu yüzden telefon numarasının doğruluğunu kontrol ediniz.</p>

            </div>
            <div className='form-group my-4'>
                <label>Müşterinin Doğum Tarihi</label>
                <input maxLength={50} value={Customer.Birthday} type="date" onChange={(e) => SET_STATE_EDIT_CUSTOMER('Birthday', e.target.value)} placeholder='Örn. Gürhan Arslan' className='form-control'></input>
                <p className='alert alert-secondary mt-2'> Müşterilerin doğum günlerinde özel SMS gönderebilirsin! Müşterinin tüm rezzervasyonlarında doğum tarihi firma çalışanlarına gösterilecektir.</p>
            </div>
            {
                props.alertBox.status ? <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : ''
            }
            <div className='mt-4 d-flex align-items-center justify-content-end'>
                <button disabled={props.isLoadingAction} onClick={() => SEND_CUSTOMER_LIST_TO_DB()} className='btn btn-primary d-flex'>
                    {
                        props.isLoadingAction ? <Loading size="sm"></Loading> : ''
                    }
                    Kaydet</button>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    company: state.company.activeCompany,
    isLoadingAction: state.customers.isLoadingAction,
    alertBox: state.customers.alertBox
})

const mapDispatchToProps = { SEND_NEW_CUSTOMER_TO_DB, SET_ALERT_BOX }

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer)

