import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { SET_ALERT_MODAL } from '../../store/module/settings/action'

export const ThereisNoRoom = (props) => {
    return (
        <div className='d-flex align-items-center justify-content-center flex-column'>
            <h1 className='text-primary'>
                <i className='flaticon-381-warning'></i>
            </h1>
            <h4 className='alert alert-primary my-4 text-center'>
                Şu an hiç aktif odanız bulunmamaktadır. Rezzervasyon alabilmeniz için öncelikle oda açmanız gerekmektedir.
                <div className='mt-4'>
                    <Link to={`/${props.company.Id}/add-room`}> <button
                        onClick={() => props.SET_ALERT_MODAL({ status: false })}
                        className='btn btn-primary'>Hemen Yeni Oda Açın</button></Link>
                </div>
            </h4>
        </div>
    )
}

const mapStateToProps = (state) => ({
    company: state.company.activeCompany,
})

const mapDispatchToProps = { SET_ALERT_MODAL }

export default connect(mapStateToProps, mapDispatchToProps)(ThereisNoRoom)