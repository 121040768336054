import React from 'react'
import { connect } from 'react-redux'

export const CompanyRemove = (props) => {
  return (
    <div className='alert alert-primary'>
      <p>Merhaba {props.user.Fullname},</p>
      <br></br>
      <p>{props.company.Name} isimli firmanızı Rezzervasyon App'ten kaldırmak için öncelikle bize başvuru talebi oluşturmanız gerekmektedir.
        <br></br>
        Yapılan başvuru talebi sonucunda sizin ile iletişime geçeceğiz. Görüşmemizin sonucuna göre Rezzervasyon App yetkilileri hesabınız ile ilgili işlemleri yapacaktır. </p>
    <div className='d-flex justify-content-center mt-4'>
      <button className='btn btn-primary'>Firma Sil Başvuru Talebi Oluştur</button>
    </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  company: state.company.activeCompany,
  user: state.user.activeUser.User
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRemove)