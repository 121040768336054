import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { JustDate } from '../../../plugins/SET_DATE_FORMATTER';

export default function RezEvaluate({ item, icon, variant, type, removeItem }) {
    const [isLoadingAction, setIsLoadingAction] = useState(false);
    const [modal, setModal] = useState({ status: false, message: '', variant: '', icon: '' });
    function convertM2H(n) {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return (rhours < 10 ? `0${rhours}` : rhours) + ":" + (rminutes < 10 ? `0${rminutes}:00` : rminutes + ':00');
    }
    async function SET_UPDATE_REZ(Result) {
        setIsLoadingAction(true);
        const response = await window.$api.put('Rezervations/EvaluateRezRequest', {
            "RezervationId": item.RezervationId,
            "ReqType": type, // Create : 1, Cancel : 2
            Result,
            "MessageTxt": SET_MESSAGE(Result)
        })
        if (response.message === 'OK' && response.result === 'OK') {
            setModal({ status: true, message: 'İşleminiz başarıyla gerçekleştirildi.', variant: 'success', icon: 'check-circle' })
            removeItem(item.RezervationId)
            setTimeout(() => {
                setModal({ status: false })
            }, 2500);
        }
        else
            setModal({ status: true, message: 'İşleminiz gerçekleştirilemedi. Lütfen daha sonra tekrar deneyin.', variant: 'danger', icon: 'times-circle' })

        setIsLoadingAction(false);
    }
    function SET_MESSAGE(result) {
        if (result === 1 && type === 1) // create onay
            return `Sayın ${item.Customer.Fullname}, ${JustDate(item.RezervationDate)} ${convertM2H(item.RezervationTime)} tarihindeki rezervasyonunuz onaylanmıştır.`
        else if (result === 1 && type === 2) // cancel onay
            return `Sayın ${item.Customer.Fullname}, ${JustDate(item.RezervationDate)} ${convertM2H(item.RezervationTime)} tarihindeki rezervasyonunuz iptal edilmiştir.`
        else if (result === 0 && type === 1) // create red
            return `Sayın ${item.Customer.Fullname}, ${JustDate(item.RezervationDate)} ${convertM2H(item.RezervationTime)} tarihindeki rezervasyon talebiniz firmanın yoğunluğundan dolayı onaylanmamıştır.`
        else if (result === 0 && type === 2) // cancel red
            return `Sayın ${item.Customer.Fullname}, ${JustDate(item.RezervationDate)} ${convertM2H(item.RezervationTime)} tarihindeki rezervasyon talebiniz firma tarafında reddedilmiştir.
            İptal sebebini daha açık bir şekilde anlatmak için lütfen bizimle iletişime geçin.`
    }
    return (
        <div>
            <div className='row px-4 py-1 border-bottom align-items-center justify-content-center'>
                <div className={`col-12 py-3 bg-${variant}-light`}>
                    <div className='d-flex justify-content-between'>
                        <h3 className={`text-${variant} fas ${icon}`}> </h3>
                        <p>{variant === 'success' ? 'Randevu Talebi Oluşturma' : 'Randevu İptal Etme Talebi'}</p>
                    </div>
                    <div>
                        {
                            item.ProcessesData.map((p, index) => (
                                <span key={index} className='my-2 badge badge-primary light'>{p.Name}</span>
                            ))
                        }
                    </div>
                    <div className='mt-2'> <i className='fas fa-user'></i> {item.Customer.Fullname}</div>
                    <div className='mt-2'><a href={`tel:${item.Customer.Phone}`}> <i className='fas fa-phone'></i> {item.Customer.Phone}</a></div>
                    <div className='mt-2'> <a href={`mailto:${item.Customer.Email}`}> <small> <i className='fas fa-envelope'></i> {item.Customer.Email}</small> </a></div>
                    <h4 className='text-primary'>{JustDate(item.RezervationDate)} {convertM2H(item.RezervationTime)}</h4>
                    <div className='d-flex justify-content-end mt-2'>
                        <button disabled={isLoadingAction} onClick={() => SET_UPDATE_REZ(0)} className='btn mx-1 btn-sm btn-danger'>Reddet</button>
                        <button disabled={isLoadingAction} onClick={() => SET_UPDATE_REZ(1)} className='btn mx-1 btn-sm btn-success'>Kabul Et</button>
                    </div>
                </div>
            </div>
            <Modal centered className="fade" onHide={() => setModal({ status: false })} show={modal.status}>
                <Modal.Header>
                    <Modal.Title> <i className='flaticon-381-warning'></i> Mesaj</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setModal({ status: false })}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <h1 className='mb-4'> <i className={`text-${modal.variant} fas fa-${modal.icon}`} style={{fontSize: '72px'}}></i> </h1>
                        <strong>{modal.message}</strong>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


