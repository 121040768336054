import React, { useEffect, useState } from 'react'
import { Card, Accordion, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { JustDate } from '../../../plugins/SET_DATE_FORMATTER'
import { GET_CUSTOMER_LIST } from '../../../store/module/customers/action'
import Loading from '../../shared/loading'
import AddRezervation from '../Rezervation/AddRezervation'
import EditRezervation from '../Rezervation/EditRezervation'
export const Room = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    let [NewRezervationItems, setNewRezervationItems] = useState({ timeData: {}, index: -1 })
    let [RezervationDetailModal, setRezervationDetailModal] = useState(false);
    const [EditModal, setEditModal] = useState(false);
    let [times, setTimes] = useState([]);
    const [room, setRoom] = useState(props.room)
    const [showInformation, setShowInformation] = useState(false)
    const [activeBordered, setActiveBordered] = useState(-1);
    const [defaultAccordion, setDefaultAccordion] = useState([])
    function convertH2M(timeInHour) {
        var timeParts = timeInHour.split(":");
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }
    useEffect(() => {
        GET_REZERVATION_BY_ROOM(JustDate(props.date, 'en-CA'), props.room.Id);
    }, [props.date])
    async function GET_REZERVATION_BY_ROOM(date, roomId) {
        setIsLoading(true)
        const response = await window.$api.get(`Rezervations/GetAll?roomId=${roomId}&date=${date}&limit=20`);
        if (response.message === 'OK' && response.result === 'OK') {
            const timeList = SET_TIME(props.room.Interval, response.data === null ? [] : response.data);
            setTimes(timeList);
        } else setTimes([])
        setIsLoading(false);

    }
    function convertM2H(n) {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return (rhours < 10 ? `0${rhours}` : rhours) + ":" + (rminutes < 10 ? `0${rminutes}` : rminutes);
    }
    const startTime = convertH2M(room.OpenedAt);
    const endTime = convertH2M(room.ClosedAt);
    function SET_TIME(time = 30, rezervations = []) {
        let timeList = []
        let timer = startTime
        while (timer < endTime) {
            const start = timer // 600 => 10:00
            const end = timer + time // 630 => 10:30

            let lunchStart = -1
            let lunchEnd = -1
            if (props.room.OffHoursStart !== null) lunchStart = convertH2M(props.room.OffHoursStart)
            if (props.room.OffHoursEnd !== null) lunchEnd = convertH2M(props.room.OffHoursEnd)

            const rez = rezervations.find(r => r.RezervationTime >= start && r.RezervationTime < end) // rez saati (10:00)değişken start 10:00 end 10:30 arasında olan rezzervasyonları getir
            if (rez !== undefined) {
                const x = Math.ceil(rez.Duration / time);
                timeList.push({ time: timer, status: true, disabled: false, rez })
                timer += (time * x); // x katsayısı kadar ileriye zamanı götür x => rezzervasyonun kapladığı alan büyüklüğü
            } else if (lunchStart <= timer && lunchEnd > timer) {
                timeList.push({
                    time: timer, status: true, disabled: true, rez: {
                        ProcessesData: [],
                        RezervationTime: timer,
                        Price: 0,
                        Customer: {
                            Fullname: "Öğle Arası",
                            Phone: "-"
                        }
                    }
                })
                timer += time;
            }
            else {
                timeList.push({ time: timer, status: false, disabled: false, })
                timer += time;
            }
        }
        return timeList
    }
    function SET_REZERVATION(timeData, index) {
        setNewRezervationItems({ timeData, index })
        setTimeout(() => {
            setEditModal(true);
        }, 250);
    }
    function SHOW_REZERVATION_DETAIL(item, i) {
        if (new Date(props.date).getTime() + 86340000 < new Date(props.activeUser.CurrentDate).getTime() && !item.status)
            return
        if (item.rez && props.activeUser.RoleId !== 4) if (item.rez.Customer === null) return SET_REZERVATION(item, i)
        if (item.status && !item.disabled) {
            SET_REZERVATION_DETAIL(item, i)
        } else if (!item.disabled && props.activeUser.RoleId !== 4)
            SET_REZERVATION(item, i)
        else return
    }
    function SET_REZERVATION_DETAIL(timeData, index) {
        setNewRezervationItems({ timeData, index })
        setRezervationDetailModal(true);
    }
    useEffect(() => {
        setDefaultAccordion([{
            title: `${props.room.Owner.Fullname}`,
            description: `${props.room.Description}`,
            variant: "primary",
        }])
        setRoom(props.room)
        if (props.activeRoom !== -1) setActiveBordered(0)
        // GET_REZERVATION_BY_ROOM(props.date, props.room.Id)
    }, []);
    return (
        <div className={`col-xl-${props.full ? 12 : props.gridCount}`}>
            <Accordion
                className="accordion accordion-primary-solid"
            >
                {defaultAccordion.map((data, i) => (
                    <div id={`room-${props.room.Id}`} className="accordion-item" key={i}>
                        <Accordion.Toggle
                            className={`accordion-header ${activeBordered !== i ? "collapsed" : ""
                                }`}
                            as={Card.Text}
                            eventKey={`${i}`}
                            onClick={() =>
                                setActiveBordered(activeBordered === i ? -1 : i)
                            }
                        >
                            {" "}
                            <span className="accordion-header-text d-flex align-items-center">
                                <img className='avatar float-right' src={window.$api.image(props.room.Owner.ImagePath)}></img>
                                <span>
                                    <small>{data.description}</small> <br></br>
                                    <span> {data.title}</span>
                                </span>
                            </span>
                            <span className="accordion-header-indicator"></span>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                            eventKey={`${i}`}
                            className={activeBordered === 0 ? 'show accordion__body' : 'accordion__body'}
                        >
                            {
                                isLoading ? <div className='text-center'>
                                    <Loading variant='primary' description={`${JustDate(props.date)} tarihli rezzervasyonlar yükleniyor..`}></Loading>
                                </div> : <div className="accordion-body-text ">
                                    {
                                        showInformation ?
                                            <div className='my-4 card '>
                                                <div className='card-title p-4 d-flex justify-content-between align-items-center'>
                                                    <span>Oda Bilgileri</span>
                                                    <button onClick={() => setShowInformation(false)} className='btn btn-close'></button>
                                                </div>

                                                <div className='card-body p-0 px-4'>
                                                    <div className='row'>
                                                        <div className='col-12 col-md-12 '>
                                                            <div className='row align-items-center'>
                                                                <div className='col-lg-3 col-md-3 col-2 col-xl-3  '><img className='avatar-room' src={window.$api.image(room.Owner.ImagePath)} width="100%"></img>
                                                                    <div className='w-100 badge badge-primary mt-2'>Sahip</div>
                                                                </div>
                                                                <div className='col-9'>
                                                                    <div>{room.Owner.Fullname}</div>
                                                                    <div> <hr></hr> <span><i className='flaticon-381-smartphone-4'></i> {room.Owner.Phone}</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-12 '>
                                                            <div className='row align-items-center'>
                                                                <div className='col-lg-3 col-md-3 col-2 col-xl-3  '><img className='avatar-room' src={props.room.Tyro.Id === 0 ? window.$api.image('app/static/default.jpg') : window.$api.image(props.room.Tyro.ImagePath)} width="100%"></img>
                                                                    <div className='w-100 badge badge-secondary mt-2'>Personel</div>
                                                                </div>
                                                                <div className='col-9'>

                                                                    <div>{
                                                                        props.room.Tyro.Id === 0 ? 'Bu odanın personeli bulunmamaktadır.' : props.room.Tyro.Fullname
                                                                    }</div>
                                                                    <div> <hr></hr> <span><i className='flaticon-381-smartphone-4'></i> {room.Tyro.Phone}</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='alert alert-primary p-0 px-3 mt-2'>
                                                        <div className='px-1 my-3'><span className='text-primary'> Odanın Verdiği Hizmetler </span></div>
                                                        {
                                                            props.room.Processes === null ? "Hizmet Bulunamadı." :
                                                                <div className='my-2'>
                                                                    {
                                                                        props.room.Processes.map((p, i) => (
                                                                            <span key={i} className='badge badge-primary m-1 light'>{p.Name}</span>
                                                                        ))
                                                                    }
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className='mt-4'>
                                                        <p> Açılış Saati: <strong className='text-primary'> {room.OpenedAt}</strong> - Kapanış Saati: <strong className='text-primary'> {room.ClosedAt}</strong>  </p>
                                                        <p> Öğle Arası Başlangıç Saati: <strong className='text-primary'> {room.OffHoursStart}</strong> - Öğle Arası Bitiş Saati: <strong className='text-primary'> {room.OffHoursEnd}</strong>  </p>
                                                        <div><small>* Öğle Arası saatleri arasında rezzervasyon oluşturulamaz.</small> </div>
                                                        <div >
                                                            <p> <small>* Bu odada <strong className='text-primary'> {room.Interval}dk</strong> aralıkla rezzervasyon oluşturulabilir. </small> </p>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-end my-3'>
                                                        <button onClick={() => setShowInformation(false)} className='btn btn-primary btn-xs'>Detayı Gizle</button>
                                                        <Link to={`${props.company.Id}/room/${room.Id}`}><button className='btn btn-secondary btn-sm mx-2'>Daha Fazla Detay Göster</button></Link>
                                                    </div>
                                                </div>


                                            </div> : <div className='d-flex align-items-center justify-content-between pb-3'>
                                                <div>Çal. Saati: <span className='text-primary'>{room.OpenedAt} - {room.ClosedAt}</span>
                                                    <div><small>Rez. Aralığı: <span className='text-primary d-inline-block'>{room.Interval}dk</span></small></div></div>

                                                <button onClick={() => setShowInformation(true)} className='btn btn-info btn-xs'>
                                                    <i className='flaticon-050-info mx-2'></i>
                                                    Oda Bilgilerini Göster</button></div>
                                    }
                                    <div className='room-height'>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                {
                                                    times.map((time, i) => (
                                                        <div onClick={() => SHOW_REZERVATION_DETAIL(time, i)} key={i} className={` col-12  col-md-${props.gridCount === 12 ? 6 : props.gridCount === 6 ? 12 : 12} col-lg-${props.gridCount === 12 ? 4 : props.gridCount === 6 ? 12 : 12} col-xl-${props.gridCount === 12 ? 2 : props.gridCount === 6 ? 6 : 12}`}>
                                                            <div className={`${time.status ? time.disabled || time.rez.Customer === null ? 'alert alert-light ' : time.rez.IsCompleted === 1 ? 'alert alert-success' : 'has-rezervation' : ''} p-3 mb-2 rounded room-times d-flex flex-column justify-content-center align-items-center`}>
                                                                <div >{
                                                                    new Date(props.date).getDate() < 10 ?
                                                                        '0' + new Date(props.date).getDate()
                                                                        : new Date(props.date).getDate()
                                                                }.{(new Date(props.date).getMonth() + 1) < 10 ?
                                                                    '0' + (new Date(props.date).getMonth() + 1) : new Date(props.date).getMonth() + 1}.{new Date(props.date).getFullYear() - 2000}
                                                                </div>
                                                                <h1>{convertM2H(time.time)}</h1>
                                                                <div className='text-center'>
                                                                    {
                                                                        time.status ? time.disabled ? <div>
                                                                            <h5><span className='mx-1'> <i className=''>{time.rez.Customer === null ? 'Kapalı' : time.rez.Customer.Fullname}</i></span></h5>
                                                                        </div> : <div>
                                                                            <h5><span className='mx-1'> <i className=''>{time.rez.Customer === null ? 'Kapalı' : time.rez.Customer.Fullname}</i></span></h5>
                                                                        </div>
                                                                            : new Date(new Date(props.date).setHours(0, 0, 0, 0)).getTime() + 86340000 < new Date(props.activeUser.CurrentDate).getTime() ?
                                                                                <small><i> - Geçmiş tarihe randevu oluşturamazsınız..</i></small> :
                                                                                props.activeUser.RoleId !== 4 ? <small className='mx-1'> <i className='text-primary'> + oluştur</i></small> : <small>- Personel rezzervasyon oluşturamaz.</small>
                                                                    }

                                                                </div>
                                                                <div className={`detail ${time.status ? 'card bg-white' : ''}`}>
                                                                    <div>
                                                                        {
                                                                            time.status ? time.disabled ? <div>
                                                                                <div><small className='mx-1'> <i className=''>{time.rez.Customer === null ? 'Kapalı' : time.rez.Customer.Fullname}</i></small></div>
                                                                            </div> : <div>
                                                                                <div><small className='mx-1'> <i className=''>{time.rez.Customer === null ? 'Kapalı' : time.rez.Customer.Fullname}</i></small></div>
                                                                            </div>
                                                                                : ''
                                                                        }

                                                                    </div>
                                                                    {
                                                                        time.status && !time.disabled && props.showPrice ? <span>
                                                                            {
                                                                                time.rez.Customer === null ? <button className='btn btn-danger btn-xs'>Kaldır</button> :
                                                                                    time.rez.CustomerPackage === null || time.rez.CustomerPackage === undefined ?
                                                                                        <span className={` text-${time.rez.IsCompleted === 1 ? 'success' : 'secondary'}`}>
                                                                                            <strong>Ücret: </strong> ₺{time.rez.Price.toFixed(2)}</span> :
                                                                                        <div><span className='badge badge-primary'>{time.rez.CustomerPackage.Detail}</span></div>
                                                                            }
                                                                        </span > : ''

                                                                    }
                                                                    {
                                                                        time.status ?
                                                                            <span className='mb-3 text-info'><span className='mx-1'> <i className=''>{time.rez.Customer === null ? '---' : time.rez.Customer.Phone}</i></span></span>
                                                                            : ''
                                                                    }
                                                                    <div>
                                                                        {
                                                                            time.status ? time.rez.ProcessesData.map((pd, i) => (
                                                                                <span key={i} className='badge badge-secondary light m-1 rounded-0'>{pd.Name}</span>
                                                                            )) : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Accordion.Collapse>
                    </div>
                ))}
            </Accordion>
            {/* <Modal centered className="fade" onHide={() => setRezervationDetailModal(false)} show={RezervationDetailModal}>
                <Modal.Header>
                    <Modal.Title> <i className='flaticon-381-bookmark'></i> Rezzervasyon Detayı</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setRezervationDetailModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    {
                        RezervationDetailData === null ? '' : <RezervationDetail setRezervationDetailModal={setRezervationDetailModal} RezervationDetail={RezervationDetailData}></RezervationDetail>

                    }
                </Modal.Body>
            </Modal> */}
            <AddRezervation
                GET_REZERVATION_BY_ROOM={GET_REZERVATION_BY_ROOM}
                room={props.room}
                times={times}
                item={NewRezervationItems.timeData}
                date={props.date}
                index={NewRezervationItems.index}
                RezervationModal={EditModal}
                setRezervationModal={setEditModal}
            ></AddRezervation>
            <EditRezervation
                GET_REZERVATION_BY_ROOM={GET_REZERVATION_BY_ROOM}
                room={props.room}
                times={times}
                item={NewRezervationItems}
                index={NewRezervationItems}
                RezervationModal={RezervationDetailModal}
                setRezervationModal={setRezervationDetailModal}
            ></EditRezervation>
        </div>
    )
}


const mapStateToProps = (state) => ({
    processingList: state.processing.processingList,
    company: state.company.activeCompany,
    activeUser: state.user.activeUser.User

})

const mapDispatchToProps = { GET_CUSTOMER_LIST }

export default connect(mapStateToProps, mapDispatchToProps)(Room)