import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PhoneInput from 'react-phone-number-input/input'
import Loading from '../../shared/loading'
import { SEND_UPDATE_CUSTOMER_TO_DB, SET_ALERT_BOX } from '../../../store/module/customers/action'
export const EditCustomers = (props) => {
    const [isLoadedAuth, setIsLoadedAuth] = useState(false)
    const [Customers, setCustomers] = useState({
        Fullname: "",
        Phone: "",
        Note: "",
        Birthday: null
    })
    function SET_STATE_EDIT_CUSTOMER(key, val) {
        setCustomers({
            ...Customers,
            [key]: val
        })
    }
    useEffect(() => {
        if (props.editModal) {
            props.SET_ALERT_BOX({
                variant: 'danger',
                status: false,
                message: ''
            })
            setCustomers({
                ...props.EditCustomer,
                Birthday: props.EditCustomer.Birthday.includes('T') ? props.EditCustomer.Birthday.split('T')[0] : props.EditCustomer
            })
        }
    }, [props.editModal])
    function SEND_UPDATE_CUSTOMER_LIST_TO_DB() {
        props.SET_ALERT_BOX({
            variant: 'danger',
            status: false,
            message: ''
        })
        if (Customers.Fullname === '' || Customers.Phone.length < 13)
            return props.SET_ALERT_BOX({
                variant: 'danger',
                status: true,
                message: 'Tüm alanları eksiksiz doldurmalısın. Lütfen bilgileri kontrol edin.'
            })
        if (Customers.Note === '') Customers.Note = '-'
        props.SEND_UPDATE_CUSTOMER_TO_DB(Customers)
    }
    return (
        <div>
            <div className='form-group my-4'>
                <label>Müşterinin Adı ve Soyadı *</label>
                <input maxLength={50} value={Customers.Fullname} type="text" onInput={(e) => SET_STATE_EDIT_CUSTOMER('Fullname', e.target.value)} placeholder='Örn. Gürhan Arslan' className='form-control'></input>
                <div className='d-flex justify-content-end text-light'> <small>{Customers.Fullname.length}/50</small></div>
                <div> <small>* Müşterinin Adı ve Soyadı girilmesi zorunludur.</small></div>
                <div> <small>* Müşterinin Adı ve Soyadı en fazla 50 karakter olabilir.</small></div>
                <p className='alert alert-secondary mt-2'> Müşteri Adı ve Soyadı bilgisini doğru girmeniz müşterilerinizi takip etmeniz için önemli olduğunu unutmayın!</p>
            </div>
            <div className='form-group my-4'>
                <label>Not</label>
                <textarea maxLength={50} rows={5} value={Customers.Note} type="text" onInput={(e) => SET_STATE_EDIT_CUSTOMER('Note', e.target.value)} placeholder='Örn. Bir sonraki rezzervasyonda saçlarını sarıya boyamak istiyor.' className='form-control'></textarea>
                <div className='d-flex justify-content-end text-light'> <small>{Customers.Note.length}/240</small></div>
                <div> <small>* Müşteri Notu en fazla 240 karakter olabilir.</small></div>
                <p className='alert alert-secondary mt-2'> Müşteri Notu, müşterilerini firma çalışanları için hatırlatıcı bir not alanıdır. Bu kısım müşterinin oluşturduğu tüm rezzervasyonlarda firma çalışanlarına gösterilecektir.</p>
            </div>
            <div className='form-group my-4'>
                <label>Müşteri Telefon Numarası *</label>
                <div className="input-group mb-3 ">
                    <span className="input-group-text">+90</span>
                    <PhoneInput
                        country="TR"
                        className="form-control"
                        placeholder="Örn. 0506 123 56 67"
                        value={Customers.Phone}
                        maxLength="14"
                        onChange={(e) => SET_STATE_EDIT_CUSTOMER('Phone', e)} />
                </div>

                <div> <small>* Müşterinin Telefon Numarası girilmesi zorunludur.</small></div>
                <div> <small>* Müşterinin Telefon Numarası başındaki 0 ile birlikte giriniz.</small></div>
                <div> <small>* Müşterinin Telefon Numarası kişisel cep telefonu olmalıdır. Girilen telefon numarası ile SMS işlemleri olacaktır.</small></div>
                <div> <small>* Müşteri Telefon Numaranız <small className='text-primary'>Türkiye formatlarında</small> olmasına dikkat ediniz.</small></div>
                <p className='alert alert-secondary mt-2'> Müşterinin telefonuna SMS ile bilgilendirmeler yapılacaktır. Bu yüzden telefon numarasının doğruluğunu kontrol ediniz.</p>

            </div>
            <div className='form-group my-4'>
                <label>Müşterinin Doğum Tarihi</label>
                <input maxLength={50} value={Customers.Birthday} type="date" onChange={(e) => SET_STATE_EDIT_CUSTOMER('Birthday', e.target.value)} placeholder='Örn. Gürhan Arslan' className='form-control'></input>
                <p className='alert alert-secondary mt-2'> Müşterilerin doğum günlerinde özel SMS gönderebilirsin! Müşterinin tüm rezzervasyonlarında doğum tarihi firma çalışanlarına gösterilecektir.</p>
            </div>
            {
                props.alertBox.status ? <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : ''
            }
            <div className='mt-4 d-flex align-items-center justify-content-end'>
                <button disabled={props.isLoadingAction} onClick={() => SEND_UPDATE_CUSTOMER_LIST_TO_DB()} className='btn btn-secondary d-flex'>
                    {
                        props.isLoadingAction ? <Loading size="sm"></Loading> : ''
                    }
                    Kaydet</button>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    company: state.company.activeCompany,
    isLoadingAction: state.customers.isLoadingAction,
    alertBox: state.customers.alertBox
})

const mapDispatchToProps = { SEND_UPDATE_CUSTOMER_TO_DB, SET_ALERT_BOX }

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomers)

