import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux'
import Loading from '../components/shared/loading';
import { SET_REMOVE_COMPANY, SET_UPDATE_EXPIRED_PACKAGE_COMPANY } from '../store/module/company/action';
import api from '../plugins/Api/api';
import { withRouter } from 'react-router-dom';
const token = new api().token
export const ChooseCompanyPackage = (props) => {
    //create your forceUpdate hook
    useEffect(() => {
        INITIAL_PACKAGE_LIST()
    }, [])
    const [loading, setLoading] = useState(true);
    const [buyPackageButtonLoading, setBuyPackageButtonLoading] = useState(false);
    const [packageList, setPackageList] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [alertBox, setAlertBox] = useState({ status: false, message: "", variant: 'success', icon: "" });
    const [choosedPackage, setChoosedPackage] = useState({ status: false, Price: 0 })
    async function INITIAL_PACKAGE_LIST() {
        setLoading(true)
        const response = await window.$api.get('Packages/GetAll');
        if (response.message === 'OK' && response.result === 'OK')
            setPackageList(response.data);
        else {
            setPackageList([]);
        }
        setLoading(false)
    }
    function SET_PACKAGE(item) {
        setAlertBox({ status: false, message: "", variant: 'success', icon: "" })
        setChoosedPackage({ ...item, status: true })
        setConfirmModal(true);
    }
    function CHECK_PRICE(price) {
        if (price < 1) return (<div className='text-center'>
            <h2>ÜCRETSİZ</h2>
        </div>)
        else return (<div className='text-center'>
            <h4 className='main-price'>{(price + 10).toFixed(2)} TL</h4>
            <h2>{price.toFixed(2)} TL</h2>
        </div>)
    }
    function SET_REMOVE_COMPANY_IN_COMP(logout = false) {
        props.SET_REMOVE_COMPANY();
        localStorage.removeItem('company');
        if (!logout)
            props.history.push('/checking-company');
    }
    function CHECK_LOADING() {
        if (loading) return (<div className='text-center my-4'><Loading></Loading></div>)
        else if (packageList.length === 0) (
            <div>Herhangi bir paket bulunamadı.</div>
        )
        else return (
            <div>
                {
                    props.userCompanies.length > 1 ? <div className='d-flex justify-content-end my-4'>
                        <button onClick={() => SET_REMOVE_COMPANY_IN_COMP()} className="btn btn-primary">
                            <i className="mdi-swap-vertical mdi"></i>
                            Firmayı Değiştir</button>
                    </div> : ''
                }
                <div className='row justify-content-center align-items-center'>
                    {
                        packageList.map((item, i) => (

                            <div key={i} className='col-12 col-lg-4 col-xl-3 my-4 '>
                                <div className='pricing-card card'>
                                    <div className='text-center'>
                                        <div><i className='fa fa-home h2'></i></div>
                                        <div>{item.Name}</div>
                                    </div>
                                    <div>
                                        <ul>
                                            <li> <strong className='text-primary h4'>{item.BranchCount}</strong> Şube</li>
                                            <li> <strong className='text-primary h4'>{item.UserCount}</strong> Çalışan</li>
                                            <li> <strong className='text-primary h4'>{item.RoomCount}</strong> Oda</li>
                                            <li> <strong className='text-primary h4'>{item.RezervationCount}</strong> Rezzervasyon</li>
                                            <li> <strong className='text-primary h4'>{item.SmsCount}</strong> SMS</li>
                                        </ul>
                                    </div>
                                    {CHECK_PRICE(item.Price)}
                                    <button onClick={() => SET_PACKAGE(item)} className='btn btn-primary btn-sm'>Satın Al</button>
                                </div>
                            </div>

                        ))
                    }
                </div>
            </div>
        )
    }
    function BUY_PACKAGE_BUTTON() {
        if (buyPackageButtonLoading) return (<div><Loading size="sm"></Loading> <span>Evet, Satın Al</span></div>)
        else return (<span>Evet, Satın Al</span>)
    }
    function BUY_PACKAGE_ACTION() {
        if (alertBox.status) {
            return (<div className='text-center'>
                <h3 className='my-4'> <i className={`mdi mdi-${alertBox.icon} text-${alertBox.variant} h1`}></i></h3>
                <div>{alertBox.message}</div>
            </div>)
        } else return (<div>
            <h4 className='text-center mb-4 text-primary'>{choosedPackage.Name}</h4>
            <p className='rounded-pill p-2 border px-4'> <span className='text-primary'>Oda Sayısı: </span> {choosedPackage.RoomCount}</p>
            <p className='rounded-pill p-2 border px-4'> <span className='text-primary'>Çalışan Sayısı: </span> {choosedPackage.UserCount}</p>
            <p className='rounded-pill p-2 border px-4'> <span className='text-primary'>Rezzervasyon Sayısı: </span> {choosedPackage.RezervationCount}</p>
            <p className='rounded-pill p-2 border px-4'> <span className='text-primary'>SMS Sayısı: </span> {choosedPackage.SmsCount}</p>
            <hr></hr>
            <p className='rounded-pill p-2 border px-4'><span className='text-primary'>Ürün Fiyatı</span></p>
            {CHECK_PRICE(choosedPackage.Price)}</div>)
    }
    async function SEND_PACKAGE_TO_API_FOR_BUY_BY_COMPANY() {
        setAlertBox({ status: false, message: "", variant: 'success', icon: "" })
        setBuyPackageButtonLoading(true);
        const response = await window.$api.post(`CompanyPackages/Create`, {
            "packageId": choosedPackage.Id
        });
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({ status: true, message: "Paket alımınız başarıyla gerçekleştirildi. Sayfanız yenilenecektir. Lütfen bekleyin!", variant: 'success', icon: "check-circle" })
            SET_UPDATE_EXPIRED_PACKAGE_COMPANY(new Date())
            setTimeout(() => {
                window.location.reload();
            }, 2500);
        } else
            setAlertBox({ status: true, message: "Paket alım işleminiz gerçekleşmedi. Lütfen tekrar deneyiniz.", variant: 'danger', icon: "close-circle" })

        setBuyPackageButtonLoading(false)

    }
    function BUY_PACKAGE_ACITON_FOOTER() {
        if (alertBox.status) {
            return (<div></div>)
        } else return (
            <div><button
                onClick={() => setConfirmModal(false)}
                className="btn btn-muted btn-xs"
            >
                Kapat
            </button>
                <button disabled={buyPackageButtonLoading} onClick={() => SEND_PACKAGE_TO_API_FOR_BUY_BY_COMPANY()} className='btn-xs btn btn-secondary'>
                    {BUY_PACKAGE_BUTTON()}
                </button></div>
        )
    }
    return (
        <div className='checking-container'>
            <div className='container mt-4'>
                <h2 className='text-white'>
                    <i className='fa fa-arrow-right'></i>
                    Firma Paketini Seç</h2>
                <p>Rezzervasyon sistemine katılmak için öncelikle bir paket satın alman gerekmektedir.</p>
                {/* if(loading) return  <Loading></Loading>  */}
                {CHECK_LOADING()}
            </div>
            <Modal centered className="fade" onHide={() => setConfirmModal(false)} show={confirmModal}>
                <Modal.Header>
                    <Modal.Title>Paket satın almak istiyor musunuz?</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setConfirmModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    {BUY_PACKAGE_ACTION()}
                </Modal.Body>
                <Modal.Footer>
                    {BUY_PACKAGE_ACITON_FOOTER()}
                </Modal.Footer>
            </Modal>
        </div>
    )
}


const mapStateToProps = (state) => ({
    company: state.company.activeCompany,
    userCompanies: state.user.activeUser.Companies
})

const mapDispatchToProps = { SET_UPDATE_EXPIRED_PACKAGE_COMPANY, SET_REMOVE_COMPANY }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseCompanyPackage))