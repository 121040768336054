import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PhoneInput from 'react-phone-number-input/input'
import Loading from '../../shared/loading'
import WorkerRoles from '../../../plugins/WorkerRoles'
import { theme } from '../../../plugins/select.theme'
import Select from 'react-select'
import { SEND_UPDATE_WORKER_TO_DB, SET_ALERT_BOX } from '../../../store/module/workers/action'
import { SET_CREATE_IMAGE } from '../../../plugins/SET_CREATE_IMAGE'
export const EditWorker = (props) => {
    const [isLoadedAuth, setIsLoadedAuth] = useState(false)
    const [worker, setWorker] = useState({
        Fullname: "",
        Phone: "",
        Password: "",
        CompanyId: props.company.Id,
        Role: { roleId: -1, title: '' }
    })
    const [image, setImage] = useState({
        status: false,
        file: [],
        uri: window.$api.image('app/static/default.jpg')
    })
    function SET_STATE_EDIT_WORKER(key, val) {
        setWorker({
            ...worker,
            [key]: val
        })
    }
    useEffect(() => {
        if (props.editModal) {
            setWorker({
                ...props.editWorker,
                Role: WorkerRoles.find(wr => wr.roleId === props.editWorker.RoleId),
            })
            setImage({
                status: true,
                uri: window.$api.image(props.editWorker.ImagePath),
                file: []
            })
            props.SET_ALERT_BOX({
                variant: 'danger',
                status: false,
                message: ''
            })
            setTimeout(() => {
                setIsLoadedAuth(true)
            }, 250);
        }
    }, [props.editModal])
    function SET_FILE_CLICK() {
        document.getElementById('fileInputEdit').click();
    }
    async function SET_IMAGE(file) {
        const response = await SET_CREATE_IMAGE(file)
        setImage({
            status: true,
            file: response.file,
            uri: response.uri
        })
    }
    function SEND_UPDATE_WORKER_LIST_TO_DB() {
        props.SET_ALERT_BOX({
            variant: 'danger',
            status: false,
            message: ''
        })
        if (worker.Fullname === '' || worker.Phone.length < 13 || worker.Role.roleId === -1)
            return props.SET_ALERT_BOX({
                variant: 'danger',
                status: true,
                message: 'Tüm alanları eksiksiz doldurmalısın. Lütfen bilgileri kontrol edin.'
            })
        const fd = new FormData();
        fd.append('Fullname', worker.Fullname);
        fd.append('Phone', worker.Phone);
        fd.append('RoleId', worker.Role.roleId);
        if (image.status)
            fd.append('Image', image.file)
        if (props.index !== -1)
            props.SEND_UPDATE_WORKER_TO_DB(fd, { ...worker, ImagePath: image.uri })
        else props.SET_UPDATE_ACTIVE_USER(fd, worker, image);
    }
    return (
        <div>
            <label>Çalışanın Resimi</label>
            <div className='row align-items-center'>
                <div className='col-12 col-lg-4 text-center'>
                    <img className='avatar big' src={image.uri}></img>
                    <input accept='image/*' onChange={(e) => SET_IMAGE(e.target.files[0])} type="file" className='d-none' id='fileInputEdit'></input>
                    <div> <small>* Bu alan zorunlu değildir.</small></div>
                </div>
                <div className='col-12 col-lg-8'>
                    <p className='alert alert-secondary'>
                        Çalışanınızın resmini buradan ekleyebilirsiniz. Firmanızın daha kurumsal görünmesi için çalışanlarınıza profil resmi eklemeniz önemlidir.
                    </p>
                    <div className='mt-4 d-flex justify-content-end'>
                        {
                            image.status ? <button onClick={() => setImage({ status: false, uri: window.$api.image('app/static/default.jpg'), file: [] })} className='mx-2 btn btn-danger btn-xs'>Resimi Kaldır</button> : ''
                        }
                        <button onClick={() => SET_FILE_CLICK()} className='mx-2 btn btn-secondary btn-xs'>Resim Ekle</button>
                    </div>
                </div>
            </div>
            <div className='form-group my-4'>
                <label>Çalışanın Adı ve Soyadı</label>
                <input maxLength={50} value={worker.Fullname} type="text" onInput={(e) => SET_STATE_EDIT_WORKER('Fullname', e.target.value)} placeholder='Örn. Gürhan Arslan' className='form-control'></input>
                <div className='d-flex justify-content-end text-light'> <small>{worker.Fullname.length}/50</small></div>
                <div> <small>* Çalışanın Adı ve Soyadı girilmesi zorunludur.</small></div>
                <div> <small>* Çalışanın Adı ve Soyadı en fazla 50 karakter olabilir.</small></div>
                <p className='alert alert-secondary mt-2'> Çalışan Adı ve Soyadı bilgisini doğru girmeniz çalışanlarınızı takip etmeniz için önemli olduğunu unutmayın!</p>
            </div>
            <div className='form-group my-4'>
                <label>Çalışan Telefon Numarası</label>
                <div className="input-group mb-3 ">
                    <span className="input-group-text">+90</span>
                    <PhoneInput
                        country="TR"
                        className="form-control"
                        placeholder="Örn. 0506 123 56 67"
                        value={worker.Phone}
                        maxLength="14"
                        onChange={(e) => SET_STATE_EDIT_WORKER('Phone', e)} />
                </div>

                <div> <small>* Çalışanın Telefon Numarası girilmesi zorunludur.</small></div>
                <div> <small>* Çalışanın Telefon Numarası başındaki 0 ile birlikte giriniz.</small></div>
                <div> <small>* Çalışanın Telefon Numarası kişisel cep telefonu olmalıdır. Girilen telefon numarası ile SMS işlemleri olacaktır.</small></div>
                <div> <small>* Çalışan Telefon Numaranız <small className='text-primary'>Türkiye formatlarında</small> olmasına dikkat ediniz.</small></div>
                <p className='alert alert-secondary mt-2'> Girilen telefon numarası çalışanın kendi hesabına girebilmesi için kullanacağı telefon numarasıdır! Çalışan hesabına telefon numarası ve oluşturulan şifre ile giriş yapabilecektir.</p>

            </div>
            {
                props.index !== -1 || props.editWorker.RoleId === 1 ?
                    <div className='form-group my-4'>
                        <label>Hesabın Yetkisini Seçin</label>
                        {
                            isLoadedAuth ? <Select
                                defaultValue={worker.Role}
                                placeholder="Hesabın Yetkisini Seçin"
                                isSearchable={false}
                                onChange={(e) => SET_STATE_EDIT_WORKER('Role', e)}
                                options={WorkerRoles}
                                getOptionLabel={(option) => <div> <i className='flaticon-381-user-7'></i> {option.title} </div>}
                                getOptionValue={(val) => val.roleId}
                                theme={theme}
                            >
                            </Select> : <Loading description='Yetki ayarlanıyor..' variant='primary'></Loading>
                        }
                        <div> <small>* Çalışanın firma içindeki yetkisini belirtiniz.</small></div>
                    </div> : ''
            }
            {
                props.alertBox.status ? <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : ''
            }
            <div className='mt-4 d-flex align-items-center justify-content-end'>
                <button disabled={props.isLoadingAction} onClick={() => SEND_UPDATE_WORKER_LIST_TO_DB()} className='btn btn-secondary d-flex'>
                    {
                        props.isLoadingAction ? <Loading size="sm"></Loading> : ''
                    }
                    Kaydet</button>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    company: state.company.activeCompany,
    isLoadingAction: state.workers.isLoadingAction,
    alertBox: state.workers.alertBox
})

const mapDispatchToProps = { SEND_UPDATE_WORKER_TO_DB, SET_ALERT_BOX }

export default connect(mapStateToProps, mapDispatchToProps)(EditWorker)

