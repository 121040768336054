import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import NoFound from "../../shared/NoFound";
const Workers = ({ toggleTab, PerfectScrollbar, toggleChatBox, workerManager, workersEmployees, workersTyros,workers, company }) => {
   return (
      <div
         className={`tab-pane fade  ${toggleTab === "workers" ? "active show" : ""
            }`}
         id="alerts"
         role="tabpanel"
      >
         <div className="card mb-sm-3 mb-md-0 contacts_card">
            <div className="card-header chat-list-header justify-content-center text-center">
               <div>
                  <h6 className="mb-1">Çalışanlar</h6>
                  <p className="mb-0">{company.Name}</p>
               </div>
            </div>
            <PerfectScrollbar
               className={`card-body contacts_body p-0 dz-scroll  ${toggleChatBox ? "ps ps--active-y" : ""
                  }`}
               id="DZ_W_Contacts_Body1"
            >
               <ul className="contacts">
                  <li className="name-first-letter">YÖNETİCİLER</li>
                  {
                     workerManager.length === 0 ? <NoFound description="Çalışan Bulunamadı."></NoFound> :workerManager.map((worker, i) => (
                        <li key={i} className="active">
                           <div className="d-flex bd-highlight">
                           <img className="avatar" src={window.$api.image(worker.ImagePath)}></img>
                              <div className="user_info">
                                 <span>{worker.Fullname}</span>
                                 <p className="text-primary">{worker.Phone}</p>
                              </div>
                           </div>
                        </li>
                     ))
                  }
                  <li className="name-first-letter">ÇALIŞANLAR</li>
                  {
                     workersEmployees.length === 0 ? <NoFound description="Çalışan Bulunamadı."></NoFound> : workersEmployees.map((worker, i) => (
                        <li key={i} className="active">
                           <div className="d-flex bd-highlight">
                           <img className="avatar" src={window.$api.image(worker.ImagePath)}></img>
                              <div className="user_info">
                                 <span>{worker.Fullname}</span>
                                 <p className="text-primary">{worker.Phone}</p>
                              </div>
                           </div>
                        </li>
                     ))
                  }
                  <li className="name-first-letter">ÇIRAKLAR</li>
                  {
                     workersTyros.length === 0 ? <NoFound description="Çalışan Bulunamadı."></NoFound> :workersTyros.map((worker, i) => (
                        <li key={i} className="active">
                           <div className="d-flex bd-highlight">
                              <img className="avatar" src={window.$api.image(worker.ImagePath)}></img>
                              <div className="user_info">
                                 <span>{worker.Fullname}</span>
                                 <p className="text-primary">{worker.Phone}</p>
                              </div>
                           </div>
                        </li>
                     ))
                  }
               </ul>
            </PerfectScrollbar>
            <div className="card-footer"></div>
         </div>
      </div>
   );
};



const mapStateToProps = (state) => ({
   workers: state.workers.workers,
   workerManager: state.workers.workers.filter(w => w.RoleId === 1 || w.RoleId === 2),
   workersEmployees: state.workers.workers.filter(w => w.RoleId === 3),
   workersTyros: state.workers.workers.filter(w => w.RoleId === 4),
   company: state.company.activeCompany
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Workers);
