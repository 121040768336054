import React, { useState } from 'react'
import { connect } from 'react-redux'

export const CompanySettingsTitle = (props) => {
    const lists = [
        { comp: 'settings', icon: 'flaticon-381-settings-6', title: 'Firma Ayarları', description: 'Firmanızı ayarlarını buradan yapabilirsiniz.', variant: 'success' },
        { comp: 'package', icon: 'flaticon-381-gift', title: 'Paketim', description: 'Paketinizi buradan görebilirsiniz.', variant: 'primary' },
        { comp: 'company-messages', icon: 'flaticon-381-reading', title: 'Firma Mesajları', description: 'Müşterilerinize gönderdiğiniz mesajları buradan düzenleyebilirsiniz.', variant: 'info' },
        { comp: 'company-payments', icon: 'flaticon-381-add', title: 'Ödeme Yöntemlerim', description: 'Ödemenizi kredi kartı/banka kartı veya PayPal ile alabilirsiniz.', variant: 'secondary' },
    ]
    return (
        <div className='row'>
            {
                lists.map((list, i) => (
                    <div key={i} onClick={() => props.setActive(list.comp)} className='col-12 col-md-6 col-lg-4 col-xl-3'>
                        <div className={`widget-stat border card cursor-pointer ${list.comp === props.active ? 'border border-secondary' : ''}`}>
                            <div className="card-body p-4">
                                <h3><i className={`${list.icon} text-${list.variant}`}></i></h3>
                                <h4 className="card-title">{list.title}</h4>
                                <div className="progress mb-2">
                                    <div className={`progress-bar progress-animated bg-${list.variant} w-100`}></div>
                                </div>
                                <small>{list.description}</small>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettingsTitle)