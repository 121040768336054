import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { } from 'redux'
import currency from '../../../plugins/SET_CURRENCY'
import Loading from '../../shared/loading'
import Pagination from '../../shared/pagination'
export const PackagesOfCustomerCompanyList = (props) => {
    const [page, setPage] = useState(1);
    const [isSending, setIsSending] = useState(false);
    const [alertBox, setAlertBox] = useState({ status: false, message: '', variant: '', icon: '' })
    const [selectedCustomerPackage, setSelectedCustomerPackage] = useState({ Id: -1 })
    const [selectedPaymentType, setSelectedPaymentType] = useState(4);
    useEffect(() => {
        CHANGED_PAGE(page)
    }, [])
    function CHANGED_PAGE(pageCount) {
        setPage(pageCount)
        props.GET_CUSTOMER_PACKAGES(`&page=${pageCount}&limit=10`)
    }
    function TOTAL_PAGE() {
        return Math.ceil(props.count / 10);
    }
    function SEND_PAYMENT_TO_CUSTOMER() {
        setIsSending(true);
        setAlertBox({ status: false, message: '', variant: '', icon: '' })
        if (selectedPaymentType === 4) SEND_PACKAGE_TO_CUSTOMER_WITH_CASH();
        if (selectedPaymentType === 1) SEND_PACKAGE_TO_CUSTOMER_WITH_IYZICO();
        if (selectedPaymentType === 2) SEND_PACKAGE_TO_CUSTOMER_WITH_PAYPAL();
        if (selectedPaymentType === 3) SEND_PACKAGE_TO_CUSTOMER_WITH_TRANSFER();
    }
    async function SEND_PACKAGE_TO_CUSTOMER_WITH_CASH() {
        const response = await window.$api.post('PackagesOfCustomers/Create', {
            CustomerPackageId: selectedCustomerPackage.Id,
            IsCompleted: 1
        });
        if (response.message === 'OK' && response.result === 'OK') {
            props.refresh();
            setAlertBox({ status: true, message: `Tebrikler! Müşterinin hesabına "${selectedCustomerPackage.Detail}" paketi başarıyla tanımlandı.`, variant: 'success', icon: 'check-circle' })
        } else {
            setAlertBox({ status: true, message: 'Müşterinin hesabına paketi ekleyemedik. Lütfen daha sonra tekrar deneyin.', variant: 'danger', icon: 'times-circle' })
        }
        setIsSending(false);
    }
    async function SEND_PACKAGE_TO_CUSTOMER_WITH_PAYPAL() {
        const response = await window.$api.post('Payments/PaypalCustomerPackage', {
            "CustomerId": props.user.Id,
            "IsSms": 0,
            "IsEmail": 1,
            CustomerPackageId: selectedCustomerPackage.Id
        });
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({ status: true, message: `Tebrikler! Müşterinin mail adresine "${selectedCustomerPackage.Detail}" paketi satın alma linki başarıyla gönderildi.`, variant: 'success', icon: 'check-circle' })
        } else {
            setAlertBox({ status: true, message: 'Müşterinin hesabına paketi ekleyemedik. Lütfen daha sonra tekrar deneyin.', variant: 'danger', icon: 'times-circle' })
        }
        setIsSending(false);
    }
    async function SEND_PACKAGE_TO_CUSTOMER_WITH_IYZICO() {
        const response = await window.$api.post('Payments/IyzicoCustomerPackage', {
            "CustomerId": props.user.Id,
            "IsSms": 1,
            "IsEmail": 1,
            CustomerPackageId: selectedCustomerPackage.Id
        });
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({ status: true, message: `Tebrikler! Müşterinin mail ve telefon numarasına "${selectedCustomerPackage.Detail}" paketi satın alma linki başarıyla gönderildi.`, variant: 'success', icon: 'check-circle' })
        } else {
            setAlertBox({ status: true, message: 'Müşterinin hesabına paketi ekleyemedik. Lütfen daha sonra tekrar deneyin.', variant: 'danger', icon: 'times-circle' })
        }
        setIsSending(false);
    }
    async function SEND_PACKAGE_TO_CUSTOMER_WITH_TRANSFER() {
        const response = await window.$api.post('Payments/TransferCustomerPackage', {
            "CustomerId": props.user.Id,
            "IsSms": 1,
            "IsEmail": 1,
            CustomerPackageId: selectedCustomerPackage.Id
        });
        if (response.message === 'OK' && response.result === 'OK') {
            props.refresh();
            setAlertBox({ status: true, message: `Tebrikler! Müşterinin mail ve telefon numarasına "${selectedCustomerPackage.Detail}" paketi satın alma linki başarıyla gönderildi.`, variant: 'success', icon: 'check-circle' })
        } else {
            setAlertBox({ status: true, message: 'Müşterinin hesabına paketi ekleyemedik. Lütfen daha sonra tekrar deneyin.', variant: 'danger', icon: 'times-circle' })
        }
        setIsSending(false);
    }
    return (
        <div className='card'>
            <div className='card-title p-4'> Müşteri Paketleri ({props.count}) </div>
            <p className='px-4'>* Aşağıdan müşteriye eklemek istediğiniz paketi seçin. </p>
            {
                props.isLoading ? <div className='text-center my-2'><Loading variant='primary'></Loading></div> :
                    selectedCustomerPackage.Id !== -1 ?
                        <div className='row justify-content-center'>
                            <div className='col-12'>* rez. : rezzervasyon</div>
                            <div className='col-12 col-md-6 col-lg-5 col-xl-3'>
                                <div className='card'>
                                    <div className='card-body d-flex justify-content-between flex-column align-items-between'>
                                        <h6>{selectedCustomerPackage.Detail}</h6>
                                        <h2 className='text-primary'>{selectedCustomerPackage.Count}</h2>
                                        <div>rez. hakkı</div>
                                        <hr></hr>
                                        <h6 className='old-price text-danger mt-2'>{currency(selectedCustomerPackage.ActualPrice, selectedCustomerPackage.Currency)}</h6>
                                        <h3 className='text-success'>{currency(selectedCustomerPackage.Price, selectedCustomerPackage.Currency)}</h3>
                                        <hr></hr>
                                        <p className='text-secondary'>* Sadece {selectedCustomerPackage.Currency} satışlarında görünecektir.</p>
                                        <hr></hr>
                                        <p>{selectedCustomerPackage.ProcessesData.map(p => p.Name).join(' , ')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-7 col-xl-7'>
                                {
                                    alertBox.status ?
                                        <div className='text-center'>
                                            <h1> <i style={{ fontSize: '4rem' }} className={`h1 text-${alertBox.variant} fas fa-${alertBox.icon}`}></i></h1>
                                            <h4>{alertBox.message}</h4>
                                            {
                                                alertBox.variant === 'danger' ? <div className='mt-4'>
                                                    <button onClick={() => setAlertBox({ status: false })} className='btn btn-primary'> Tekrar Seçim Yap</button>
                                                </div> : ''
                                            }
                                        </div>
                                        :
                                        <div>
                                            <h5>Müşteri ödemeyi nasıl yapacak?</h5>
                                            <p> Ödemeyi yapacak işlemin üzerine tıklayın. </p>
                                            <div> <input checked={selectedPaymentType === 1} className='form-check-input' onChange={() => setSelectedPaymentType(1)} type="radio" value={1} id='1' name="payment" /> <label for="1">Kredi Kartı / Banka Kartı</label></div>
                                            <div className='alert alert-secondary'>Kredi Kartı / Banka Kartı seçimlerinde Iyzico ödeme linki müşterinin mail ve telefonuna gönderilecek.</div>
                                            <div> <input checked={selectedPaymentType === 2} className='form-check-input' onChange={() => setSelectedPaymentType(2)} type="radio" value={2} id='2' name="payment" /> <label for="2">PayPal</label> </div>
                                            <div className='alert alert-secondary'>PayPal seçimlerinde PayPal ödeme linki müşterinin mail ve telefonuna gönderilecek.</div>
                                            <div> <input checked={selectedPaymentType === 3} className='form-check-input' onChange={() => setSelectedPaymentType(3)} type="radio" value={3} id='3' name="payment" /><label for="3"> EFT / Havale</label> </div>
                                            <div className='alert alert-secondary'>EFT / Havale seçimlerinde Banka - IBAN bilgileri müşterinin mail ve telefonuna gönderilecek.</div>
                                            <div> <input checked={selectedPaymentType === 4} className='form-check-input' onChange={() => setSelectedPaymentType(4)} type="radio" value={4} id='4' name="payment" /> <label for="4">Nakit</label> </div>
                                            <div className='alert alert-secondary'>Nakit satışlarında müşterinin hesabına otomatik paket geçirilir.</div>
                                            <div className='mt-4 d-flex justify-content-end'>
                                                <button disabled={isSending} onClick={() => SEND_PAYMENT_TO_CUSTOMER()} className='btn btn-secondary'>
                                                    {isSending ? <Loading size={'sm'}></Loading> : ''}
                                                    Gönder </button>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        :

                        <div className='card-body row'>
                            <div className='col-12'>* rez. : rezzervasyon</div>
                            {
                                props.customerPackages.map((item, i) => (
                                    <div key={i} omcli className='col-12 col-md-4 col-lg-3 text-center'>
                                        <div className='card'>
                                            <div className='card-body d-flex justify-content-between flex-column align-items-between'>
                                                <h6>{item.Detail}</h6>
                                                <h2 className='text-primary'>{item.Count}</h2>
                                                <div>rez. hakkı</div>
                                                <hr></hr>
                                                <h6 className='old-price text-danger mt-2'>{currency(item.ActualPrice, item.Currency)}</h6>
                                                <h3 className='text-success'>{currency(item.Price, item.Currency)}</h3>
                                                <hr></hr>
                                                <p className='text-secondary'>* Sadece {item.Currency} satışlarında görünecektir.</p>
                                                <hr></hr>
                                                <p>{item.ProcessesData.map(p => p.Name).join(' , ')}</p>
                                                <hr></hr>
                                                <button onClick={() => setSelectedCustomerPackage(item)} className='btn btn-xs btn-primary'>+ Ekle</button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <Pagination changedPage={CHANGED_PAGE} totalPage={TOTAL_PAGE()} page={page}></Pagination>
                        </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PackagesOfCustomerCompanyList)