import React, { useState } from 'react'
import { connect } from 'react-redux'
import AddCustomer from './AddCustomer'
import ListOfProcessing from './components/ListOfProcessing'

export const Processing = (props) => {
  const [ShowAddCustomer, setShowAddCustomer] = useState(false)
  return (
    <div className='container-fluid'>
      {
        ShowAddCustomer || props.activeUser.RoleId === 4 ? '' :
          <div className='d-flex align-items-center justify-content-end'>
            <button className='btn btn-primary' onClick={() => setShowAddCustomer(true)}>Yeni Müşteri Ekleyin</button>
          </div>
      }
      <div className='row justfiy-content-center align-items-start'>
        {
          ShowAddCustomer ?
            <div className='col-12 col-lg-12 col-xl-5'>
              <div className='card'>
                <div className='card-title p-4 justify-content-between d-flex align-items-center'>
                  <h5>Yeni Müşteri Ekleyin</h5>
                  <button onClick={() => setShowAddCustomer(false)} className='btn btn-close'></button>
                </div>
                <div className='card-body'>
                  <AddCustomer></AddCustomer>
                </div>
              </div>
            </div> : ''
        }
        <div className={`col-12 col-lg-12 col-xl-${ShowAddCustomer ? 7 : 12}`}>
          <ListOfProcessing ShowAddCustomer={ShowAddCustomer}></ListOfProcessing>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  activeUser: state.user.activeUser.User
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Processing)