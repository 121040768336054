import React, { useState, useEffect } from 'react';
//Import
import DatePicker, { registerLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr'
import Statistics from '../Dashboard/components/Statistics';
import Room from '../Rooms/Room';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import NoFound from '../../shared/NoFound';
import { Modal } from 'react-bootstrap';
import Loading from '../../shared/loading';
import Select from 'react-select'
import { theme } from '../../../plugins/select.theme';
import UpdateProcessingOfRoom from './UpdateProcessingOfRoom';
import { SET_UPDATE_ROOM_W_INDEX, GET_ROOM_STATS } from '../../../store/module/rooms/action';
import { JustDate } from '../../../plugins/SET_DATE_FORMATTER';
const RoomDetail = (props) => {
    const WorkersWithoutTyro = props.workers.filter(w => w.RoleId !== 4)
    const WorkersWithTyro = props.workers.filter(w => w.RoleId === 4)
    const [editOwner, setEditOwner] = useState(false);
    const [noOffHours, setNoOffHours] = useState(false);
    const [editTyro, setEditTyro] = useState(false);
    const [isUpdating, setisUpdating] = useState(false);
    const [selectedOptionOwner, setSelectedOptionOwner] = useState(null);
    const [selectedOptionTyro, setSelectedOptionTyro] = useState(null);
    let [alertBox, setAlertBox] = useState({ status: false, message: '', variant: 'danger' });
    let [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    let [editRoom, setEditRoom] = useState({
        status: false
    });
    let [room, setRoom] = useState({
        status: false,
        roomStats: { status: false }
    });
    let [roomStats, setRoomStats] = useState({
        status: false
    });
    useEffect(() => {
        if (!props.roomStatsIsLoading) {
            const data = props.roomStats.find(r => Number(r.roomId) === room.Id);
            if (data !== undefined) {
                setRoomStats({ ...data.data, status: true })
            }
        }
    }, [props.roomStatsIsLoading])
    const [EditModal, setEditModal] = useState(false);
    const [times, setTimes] = useState([])
    const [allTimes, setAllTimes] = useState([])
    const IntervalList = [{
        val: 10,
        text: '10dk.'
    }, {
        val: 15,
        text: '15dk.'
    }, {
        val: 20,
        text: '20dk.'
    }, {
        val: 30,
        text: '30dk.'
    }, {
        val: 60,
        text: '60dk. (1 saat)'
    }, {
        val: 90,
        text: '90dk. (1saat 30dk.)'
    }, {
        val: 120,
        text: '120dk. (2 saat)'
    }, {
        val: 180,
        text: '180dk. (3 saat)'
    }]
    function convertM2H(n) {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return (rhours < 10 ? `0${rhours}` : rhours) + ":" + (rminutes < 10 ? `0${rminutes}:00` : rminutes + ':00');
    }
    function convertH2M(timeInHour) {
        var timeParts = timeInHour.split(":");
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }

    function SET_TIME_ALL_DAY(time = 30) {
        if (!editRoom.status) return setTimes([])
        const startTime = convertH2M('05:00');
        const endTime = convertH2M('23:50');
        let timeList = []
        let timer = startTime
        while (timer <= endTime) {
            timeList.push({ time: timer, text: convertM2H(timer) })
            timer += time;
        }
        return timeList
    }
    function SET_TIME(time = 30) {
        if (!editRoom.status) return setTimes([])
        const startTime = convertH2M(editRoom.OpenedAt);
        const endTime = convertH2M(editRoom.ClosedAt);
        let timeList = []
        let timer = startTime
        while (timer <= endTime) {
            timeList.push({ time: timer, text: convertM2H(timer) })
            timer += time;
        }
        return timeList
    }
    function SET_INTERVAL() {
        if (editRoom.Interval === undefined) return;
        setTimeout(() => {
            const allTimeList = SET_TIME_ALL_DAY(Number(editRoom.Interval))
            const timeList = SET_TIME(Number(editRoom.Interval));
            setTimes(timeList);
            setAllTimes(allTimeList);
        }, 200);
    }
    async function SET_STATE_EDIT_ROOM(key, val) {
        await setEditRoom({
            ...editRoom,
            [key]: val
        })
    }
    async function SEND_UPDATE_ROOM_WORKER(tyro = '') {
        setAlertBox({
            variant: 'danger',
            status: false,
            message: ''
        })
        // validations ===
        setIsLoadingUpdate(true)
        const response = await window.$api.put(`Rooms/${editRoom.Id}`, {
            "OwnerId": selectedOptionOwner.Id,
            "TyroId": tyro === '' ? selectedOptionTyro === null ? null : selectedOptionTyro.Id : null,
        })
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Güncelleme işlemi başarıyla gerçekleştirildi. Sayfanız yenileniyor.. Lütfen bekleyin'
            })
            setTimeout(() => {
                const index = props.rooms.findIndex(r => r.Id === editRoom.Id)
                props.SET_UPDATE_ROOM_W_INDEX({
                    "Owner": selectedOptionOwner,
                    "Tyro": tyro === '' ? selectedOptionTyro === null ? editRoom.Tyro : selectedOptionTyro : editRoom.Tyro,
                }, index)
                setisUpdating(true)
                setRoom({
                    ...room,
                    "Owner": selectedOptionOwner,
                    "Tyro": tyro === '' ? selectedOptionTyro === null ? editRoom.Tyro : selectedOptionTyro : editRoom.Tyro,
                    status: true
                })
                setTimeout(() => {
                    setEditOwner(false)
                    setisUpdating(false)
                    setEditTyro(false)
                    setAlertBox({
                        variant: 'danger',
                        status: false,
                        message: ''
                    })
                }, 1000);
            }, 2500);
        } else {
            return setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Güncelleme işleminizi gerçekleştiremedik. Lütfen tekrar deneyiniz..'
            })
        }
        setIsLoadingUpdate(false)

    }
    async function SEND_UPDATE_ROOM() {
        setAlertBox({
            variant: 'danger',
            status: false,
            message: ''
        })
        // validations ===
        if (editRoom.Description === '' || IntervalList.findIndex(il => il.val == Number(editRoom.Interval)) == -1
            || ((times.findIndex(il => il.text === editRoom.OffHoursStart) == -1
                || times.findIndex(il => il.text === editRoom.OffHoursEnd) == -1) && !noOffHours)
            || allTimes.findIndex(il => il.text === editRoom.OpenedAt) == -1
            || allTimes.findIndex(il => il.text === editRoom.ClosedAt) == -1) {
            return setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Tüm alanları eksiksiz doldurmalısın. Lütfen bilgileri kontrol edin.'
            })
        }
        setIsLoadingUpdate(true)
        let start = editRoom.OffHoursStart;
        let end = editRoom.OffHoursEnd;
        if (noOffHours) start = null
        if (noOffHours) end = null
        const response = await window.$api.put(`Rooms/${editRoom.Id}`, {
            ...editRoom,
            OffHoursStart: start,
            OffHoursEnd: end
        })
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Güncelleme işlemi başarıyla gerçekleştirildi. Sayfanız yenileniyor.. Lütfen bekleyin'
            })
            setEditOwner(false)
            setEditTyro(false)
            setisUpdating(true)
            setTimeout(() => {
                const index = props.rooms.findIndex(r => r.Id === editRoom.Id)
                props.SET_UPDATE_ROOM_W_INDEX({
                    ...editRoom,
                    OffHoursStart: start,
                    OffHoursEnd: end
                }, index)
                setRoom({
                    ...room,
                    ...editRoom,
                    OffHoursStart: start,
                    OffHoursEnd: end,
                    status: true
                })
                setisUpdating(false)
                setEditModal(false)
                setAlertBox({
                    variant: 'danger',
                    status: false,
                    message: ''
                })
            }, 2500);
        } else {
            return setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Güncelleme işleminizi gerçekleştiremedik. Lütfen tekrar deneyiniz..'
            })
        }
        setIsLoadingUpdate(false)

    }
    const params = useParams();
    const [date, setDateRange] = useState(null);
    const [CurrentDate, setCurrentDate] = useState(null);
    useEffect(() => {
        registerLocale('tr', tr)
        const serverDate = props.activeUser.CurrentDate.split('T')[0];
        setCurrentDate(JustDate(serverDate))
        const data = props.roomStats.find(r => Number(r.roomId) === Number(params.roomId));
        if (data !== undefined) {
            setRoomStats({ ...data.data, status: true })
        } else
            props.GET_ROOM_STATS(`?roomId=${params.roomId}`, params.roomId)
    }, [])
    useEffect(() => {
        const p = props.rooms.findIndex(rl => rl.Id === Number(params.roomId))
        if (p !== -1) {
            if (props.rooms[p].OffHoursStart === "00:00:00") setNoOffHours(true);
            setRoom({
                ...props.rooms[p],
                status: true
            })
            setEditRoom({
                ...props.rooms[p],
                status: true
            })
            setSelectedOptionOwner(props.rooms[p].Owner)
            if (props.rooms[p].Tyro.Id !== 0) {
                setSelectedOptionTyro(props.rooms[p].Tyro)
            } else
                setSelectedOptionTyro(null)

            // SET_INTERVAL();
        }

        else props.history.push('/error')
    }, [EditModal])
    useEffect(() => {
        setTimeout(() => {
            SET_INTERVAL();
        }, 250);
    }, [editRoom.Interval, editRoom.OpenedAt, editRoom.ClosedAt])
    return (
        <>
            {
                props.activeUser.RoleId !== 4 && props.activeUser.RoleId !== 3 ?
                    <div className='d-flex justify-content-end'><button onClick={() => setEditModal(true)} className='btn btn-secondary'>Odayı Güncelle</button></div>
                    : ''
            }
            {room.status ? isUpdating ? <div className='text-center'>
                <Loading variant='primary' description='Firma Detayı Güncelleniyor..'></Loading>
            </div> : <><h3>{room.Description} Detayı</h3>
                {
                    alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                }
                <div className='row my-4'>
                    <div className='col-12 col-md-6 '>
                        <div className='card p-3 justify-content-center d-flex'>
                            <div className='row align-items-center'>
                                <div className='col-3'><img className='avatar-room' src={window.$api.image(room.Owner.ImagePath)} width="100%"></img>
                                    <div className='w-100 badge badge-primary mt-2'>Sahip</div>
                                </div>
                                <div className='col-9'>
                                    {
                                        !editOwner ?
                                            <div>{room.Owner.Fullname}</div> :
                                            <Select
                                                defaultValue={selectedOptionOwner}
                                                placeholder="Oda Sahibini Seçin"
                                                name="Fullname"
                                                onChange={setSelectedOptionOwner}
                                                options={WorkersWithoutTyro}
                                                getOptionLabel={(option) => <div> <i className='flaticon-381-user-7'></i> {option.Fullname} </div>}
                                                getOptionValue={(val) => val.Id}
                                                isSearchable={false}
                                                theme={theme}
                                            >
                                            </Select>
                                    }
                                    <div> <hr></hr> <span><i className='flaticon-381-smartphone-4'></i> {room.Owner.Phone}</span></div>
                                    {
                                        props.activeUser.RoleId !== 4 && props.activeUser.RoleId !== 3 ?
                                            <div className='mt-3 d-flex justify-content-end'>
                                                {
                                                    !editOwner ? <button onClick={() => setEditOwner(true)} className='btn btn-xs btn-info'>Oda Sahibini Değiştir</button>
                                                        : <button onClick={() => SEND_UPDATE_ROOM_WORKER()} className='d-flex btn btn-xs btn-secondary'>
                                                            {
                                                                isLoadingUpdate ? <Loading size="sm"></Loading> : ''
                                                            } Kaydet
                                                        </button>
                                                }
                                            </div> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 '>
                        <div className='card p-3 d-flex justify-content-center'>
                            <div className='row align-items-center '>
                                <div className='col-3'><img className='avatar-room' src={room.Tyro.Id === 0 ? window.$api.image('app/static/default.jpg') : window.$api.image(room.Tyro.ImagePath)} width="100%"></img>
                                    <div className='w-100 badge badge-secondary mt-2'>Personel</div>
                                </div>
                                <div className='col-9'>
                                    {
                                        !editTyro ? <div>{
                                            room.Tyro.Id === 0 ? 'Bu odanın personeli bulunmamaktadır.' : room.Tyro.Fullname
                                        }</div> :
                                            <div>
                                                <Select
                                                    defaultValue={selectedOptionTyro}
                                                    placeholder="Odaya Personel Seçin"
                                                    name="Fullname"
                                                    isSearchable={false}
                                                    onChange={setSelectedOptionTyro}
                                                    options={WorkersWithTyro}
                                                    getOptionLabel={(option) => <div> <i className='flaticon-381-user-7'></i> {option.Fullname} </div>}
                                                    getOptionValue={(val) => val.Id}
                                                    theme={theme}
                                                >
                                                </Select>
                                                {
                                                    selectedOptionTyro === null ? '' : <div className='d-flex justify-content-end mt-2'>
                                                        <button onClick={() => SEND_UPDATE_ROOM_WORKER(null)} className='d-flex btn btn-xs btn-danger'>
                                                            {
                                                                isLoadingUpdate ? <Loading size="sm"></Loading> : ''
                                                            } Çırağı Kaldır
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                    }
                                    {
                                        room.Tyro.Id === 0 ? '' : <div> <hr></hr> <span><i className='flaticon-381-smartphone-4'></i> {room.Tyro.Phone}</span></div>
                                    }
                                    {
                                        props.activeUser.RoleId !== 4 && props.activeUser.RoleId !== 3 ?
                                            <div className='mt-3 d-flex justify-content-end'>
                                                {
                                                    !editTyro ? <button onClick={() => setEditTyro(true)} className='btn btn-xs btn-info'>Personel Değiştir</button>
                                                        : <button onClick={() => SEND_UPDATE_ROOM_WORKER()} className='d-flex btn btn-xs btn-secondary'>
                                                            {
                                                                isLoadingUpdate ? <Loading size="sm"></Loading> : ''
                                                            } Kaydet
                                                        </button>
                                                }
                                            </div> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    roomStats.status ? <Statistics companyStats={roomStats}></Statistics> : ''
                }
                <div className="row">
                    <div className='col-12'>
                        <UpdateProcessingOfRoom RoleId={props.activeUser.RoleId} room={room} companyId={props.company.Id} processingListofRoom={room.Processes}></UpdateProcessingOfRoom>

                    </div>
                    <div className='row'>
                        <div className='col-12 col-lg-8'>
                            <h2 className='text-primary'>{CurrentDate} Tarihli Rezzervasyonlarınız</h2>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <DatePicker
                                dateFormat="dd.MM.yyyy"
                                selected={date}
                                className="form-control"
                                maxDate={new Date().setDate(new Date().getDate() + 365)}
                                calendarStartDay={1}
                                locale="tr"
                                placeholderText='Rezzervasyon Tarihini Seçin'
                                onChange={(update) => {
                                    setCurrentDate(JustDate(update))
                                    setDateRange(update);
                                }}
                                withPortal
                            />
                        </div>
                    </div>
                    <Room full={true} date={date === null ? props.activeUser.CurrentDate : date} gridCount={12} room={room}></Room>

                </div>
                <Modal centered className="fade" onHide={() => setEditModal(false)} show={EditModal}>
                    <Modal.Header>
                        <Modal.Title> <i className='flaticon-381-settings-5'></i> {room.Description} Ayarları</Modal.Title>
                        <button
                            className="btn btn-close"
                            onClick={() => setEditModal(false)}
                        >

                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            room.status ? <>
                                <div className='form-group my-4'>
                                    <label>Oda Adı</label>
                                    <input maxLength={50} value={editRoom.Description} type="text" onInput={(e) => SET_STATE_EDIT_ROOM('Description', e.target.value)} placeholder='Oda Adı' className='form-control'></input>
                                    <div className='d-flex justify-content-end text-light'> <small>{editRoom.Description.length}/50</small></div>
                                    <div> <small>* Oda adını değiştirebilirsiniz.</small></div>
                                    <div> <small>* Oda adınız en fazla 50 karakter olabilir.</small></div>
                                </div>
                                <div className='form-group my-4'>
                                    <label>Rezzervasyon Aralığı (dk.).</label>
                                    <select value={editRoom.Interval} onChange={(e) => SET_STATE_EDIT_ROOM('Interval', Number(e.target.value))} className='form-control'>
                                        {
                                            IntervalList.map((list, i) => (
                                                <option key={i} value={Number(list.val)}>{list.text}</option>
                                            ))
                                        }
                                    </select>
                                    <div> <small>* Rezzervasyon aralığını giriniz (dk.).</small></div>
                                    <div> <small>* Rezzervasyon aralığı girdikten sonra çalışma saatlerini kontrol ediniz.</small></div>
                                </div>
                                <div className='form-group my-4'>
                                    <label>Oda Çalışma Başlangıç Saati</label>
                                    <select value={editRoom.OpenedAt} onChange={(e) => SET_STATE_EDIT_ROOM('OpenedAt', e.target.value)} className='form-control'>
                                        {
                                            allTimes.map((time, i) => (
                                                <option key={i} disabled={convertH2M(time.text) > convertH2M(editRoom.ClosedAt)} value={time.text}>{time.text}</option>
                                            ))
                                        }
                                    </select>
                                    <div> <small>* Odanın rezzervasyona <span className='text-primary'>başlangıç</span> saatini seçebilirsiniz.</small></div>
                                    {
                                        allTimes.findIndex(al => al.text === editRoom.OpenedAt) === -1 ?
                                            <div className='alert alert-danger mt-2'><small >* Seçmiş olduğunuz "Rezzervasyon Aralığı" içersinde Oda çalışma başlangıç saati bulunmamaktadır. Lütfen oda çalışma başlangıç saatini değiştirin.</small></div> : ''
                                    }
                                </div>
                                <div className='form-group my-4'>
                                    <label>Oda Çalışma Bitiş Saati</label>
                                    <select value={editRoom.ClosedAt} onChange={(e) => SET_STATE_EDIT_ROOM('ClosedAt', e.target.value)} className='form-control'>
                                        {
                                            allTimes.map((time, i) => (
                                                <option key={i} disabled={convertH2M(time.text) < convertH2M(editRoom.OpenedAt)} value={time.text}>{time.text}</option>
                                            ))
                                        }
                                    </select>
                                    <div> <small>* Odanın rezzervasyona <span className='text-primary'>bitiş</span> saatini seçebilirsiniz.</small></div>
                                    {
                                        allTimes.findIndex(al => al.text === editRoom.ClosedAt) === -1 ?
                                            <div className='alert alert-danger mt-2'><small >* Seçmiş olduğunuz "Rezzervasyon Aralığı" içersinde Oda çalışma bitiş saati bulunmamaktadır. Lütfen oda çalışma bitiş saatini değiştirin.</small></div> : ''
                                    }
                                </div>
                                <div>
                                    <h5 className='text-info'>Oda'da öğle arasını kaldırabilirsiniz</h5>
                                    <div className='alert alert-secondary'>
                                        {noOffHours ? 'Öğle arası eklemek istiyorsanız aşağıda olan işaretlenmiş kutucuğun işaretini kaldırın.' : ' Öğle arasını kaldırmak istiyorsanız aşağıdaki butonu işaretleyebilirsiniz.'}
                                    </div>
                                    <div className='mt-4'>
                                        <div className='d-flex align-items-center' onClick={() => setNoOffHours(!noOffHours)}> <input className='form-check-input mx-2' checked={noOffHours} type="checkbox" id='noOffHours'></input> Öğle arasını bu odadan kaldır</div>
                                    </div>
                                </div>
                                {
                                    noOffHours ? <div className='alert alert-secondary mt-4' >
                                        Öğle arasını kaldırdınız. Belirtilen tüm saatler arasında rezervasyon alabilirsiniz.
                                    </div> : <div>
                                        <div className='form-group my-4'>
                                            <label>Öğle Arası Başlangıç Saati</label>
                                            <select value={editRoom.OffHoursStart} onChange={(e) => SET_STATE_EDIT_ROOM('OffHoursStart', e.target.value)} className='form-control'>
                                                {
                                                    times.map((time, i) => (
                                                        <option key={i} disabled={convertH2M(time.text) > convertH2M(editRoom.OffHoursEnd)} value={time.text}>{time.text}</option>
                                                    ))
                                                }
                                            </select>
                                            <div> <small>* Öğle arası <span className='text-primary'>başlangıç</span> saatini seçebilirsiniz.</small></div>
                                            {
                                                times.findIndex(al => al.text === editRoom.OpenedAt) === -1 ?
                                                    <div className='alert alert-danger mt-2'><small >* Seçmiş olduğunuz "Rezzervasyon Aralığı" içersinde Öğle arası başlangıç saati bulunmamaktadır. Lütfen öğle arası başlangıç saatini değiştirin.</small></div> : ''
                                            }
                                        </div>
                                        <div className='form-group my-4'>
                                            <label>Öğle Arası Bitiş Saati</label>
                                            <select value={editRoom.OffHoursEnd} onChange={(e) => SET_STATE_EDIT_ROOM('OffHoursEnd', e.target.value)} className='form-control'>
                                                {
                                                    times.map((time, i) => (
                                                        <option key={i} disabled={convertH2M(time.text) < convertH2M(editRoom.OffHoursStart)} value={time.text}>{time.text}</option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                convertH2M(editRoom.OffHoursEnd) < convertH2M(editRoom.OffHoursStart) ?
                                                    <div className='alert alert-danger mt-2'><small >* Öğle Arası başlangıç saatin, Öğle Arası bitiş saatinden ileri gözüküyor. Lütfen kontrol edin.</small></div> : ''
                                            }
                                            {
                                                times.findIndex(al => al.text === editRoom.OpenedAt) === -1 ?
                                                    <div className='alert alert-danger mt-2'><small >* Seçmiş olduğunuz "Rezzervasyon Aralığı" içersinde  Öğle arası bitiş saati bulunmamaktadır. Lütfen öğle arası bitiş saatini değiştirin.</small></div> : ''
                                            }
                                            <div> <small>* Öğle arası <span className='text-primary'>bitiş</span> saatini seçebilirsiniz.</small></div>
                                        </div>
                                        <div className='alert alert-secondary'>
                                            Belirlenen "Öğle Arası" saatleri arasında oda rezzervasyon alamayacaktır.
                                        </div>
                                    </div>
                                }

                            </> : ''
                        }
                        {
                            alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-light btn-sm' onClick={() => setEditModal(false)}>Kapat</button>
                        <button disabled={isLoadingUpdate} onClick={() => SEND_UPDATE_ROOM()} className='btn btn-secondary btn-sm d-flex'>
                            {
                                isLoadingUpdate ? <Loading size="sm"></Loading> : ''
                            } Kaydet
                        </button>
                    </Modal.Footer>
                </Modal>

            </> : <div><NoFound></NoFound></div>}
        </>
    )
}
const mapStateToProps = (state) => ({
    rooms: state.rooms.roomsList,
    roomStats: state.rooms.roomStats,
    roomStatsIsLoading: state.rooms.roomStatsIsLoading,
    company: state.company.activeCompany,
    activeUser: state.user.activeUser.User,
    workers: state.workers.workers
})

const mapDispatchToProps = { SET_UPDATE_ROOM_W_INDEX, GET_ROOM_STATS }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoomDetail))