import React, { useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../../shared/loading'
import { SET_UPDATE_CUSTOMER_PACKAGES, SET_CUSTOMER_PACKAGES_LOADING, SET_ALERT_BOX } from '../../../../store/module/customerPackages/action'
import ProcessingSelectBox from '../../ProcessingSelectBox'
export const InsertProcessing = (props) => {
    console.log(props.item);
    const [item, setItem] = useState(props.item)
    const index = props.index;
    const UPDATE_IT = () => {
        console.log(item);
        if (item.Detail.length > 50 || item.Price < 0 || item.Count < 1 || item.Price === null || item.ActualPrice < 0 || item.ActualPrice === null || item.ValidFor < 0)
            return props.SET_ALERT_BOX({
                status: 'update',
                variant: 'danger',
                message: 'Tüm alanları eksiksiz doldurmanız gerekmektedir.'

            })
        props.SET_UPDATE_CUSTOMER_PACKAGES({
            "Id": item.Id,
            "Detail": item.Detail,
            "Count": item.Count,
            "Processes": item.ProcessesData.map(p => p.Id).join(','),
            "ActualPrice": item.ActualPrice,
            "Price": item.Price,
            "ValidFor": item.ValidFor,
            "Currency": item.Currency
        }, index, item.ProcessesData);
    }
    return (
        <div>
            <div>
                <div className='alert alert-secondary'>
                    * Kayıt edeceğiniz paketler rezzervasyon oluşturulurken kullanıcıya sunulacaktır.
                    Kullanıcı satın alması durumunda bu paketten işlem yapabilecektir.
                </div>
                <div className='form-group my-4'>
                    <input value={item.Detail} type="text" onInput={(e) => setItem({ ...item, Detail: e.target.value })} placeholder='Paket Adı *' className='form-control'></input>
                    <div className='d-flex justify-content-end text-light'> <small>{item.Detail.length}/50</small></div>
                    <div> <small>* Örn: Saç Kesimi, Boyama Paketi vs.</small></div>
                    <div> <small>* Paket Adı en fazla 50 karakter olmalıdır.</small></div>
                    {
                        item.Detail.length < 1 ? (<div className='alert alert-danger mt-2'> <small>* Paket adı girmeniz zorunludur.</small></div>) : ''
                    }
                </div>
                <div className='form-group my-4'>
                    <label>Paket Süresi *</label>
                    <select value={item.ValidFor} onChange={(e) => setItem({ ...item, ValidFor: Number(e.target.value) })} className='form-control'>
                        <option value={1}> 1 gün</option>
                        <option value={3}> 3 gün</option>
                        <option value={5}> 5 gün</option>
                        <option value={10}> 10 gün</option>
                        <option value={15}> 15 gün</option>
                        <option value={20}> 20 gün</option>
                        <option value={30}> 1 ay</option>
                        <option value={30 * 2}> 2 ay</option>
                        <option value={30 * 6}> 6 ay</option>
                        <option value={30 * 12}> 1 yıl</option>
                    </select>
                    <div> <small>* Müşteriniz paketi aldığı günden itibaren geçerli olacak gün sayısı.</small></div>
                </div>
                <ProcessingSelectBox
                    placeholder="Paketinizin Hizmetlerini Seçin"
                    label="Müşteriye uygulanacak hizmetleri seçin"
                    ProcessesData={item.ProcessesData}
                    onChange={(e) => setItem({ ...item, ProcessesData: e })}
                ></ProcessingSelectBox>
                <div className='form-group my-4'>
                    <label>Para Birimi *</label>
                    <select className='form-control' value={item.Currency} onChange={(e) => setItem({ ...item, Currency: (e.target.value) })}>
                        <option value={'TRY'}>Türk Lirası (₺)</option>
                        <option value={'USD'}>Dolar ($)</option>
                        <option value={'EUR'}>Euro (€)</option>
                    </select>
                </div>
                <div className='form-group my-4'>
                    <label>Eski Paket Ücreti *</label>
                    <div className="input-group mb-3 "><span className="input-group-text">₺</span>
                        <input value={item.ActualPrice} type="number" onInput={(e) => setItem({ ...item, ActualPrice: Number(e.target.value) })} className="form-control" placeholder='Paket Ücreti (₺) *' />
                    </div>
                    <div> <small>* Eski Paket ücretini tanımlayabilirsiniz.</small></div>
                    {
                        item.ActualPrice < 0 ? (<div className='alert alert-danger mt-2'> <small>* Eski Paket ücreti 0 TL'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        item.ActualPrice === null ? (<div className='alert alert-danger mt-2'> <small>* Eski Paket ücreti girilmesi zorunludur.</small></div>) : ''
                    }

                </div>
                <div className='form-group my-4'>
                    <label>Paket Ücreti *</label>
                    <div className="input-group mb-3 "><span className="input-group-text">₺</span>
                        <input value={item.Price} type="number" onInput={(e) => setItem({ ...item, Price: Number(e.target.value) })} className="form-control" placeholder='Paket Ücreti (₺) *' />
                    </div>
                    <div> <small>* Paketinize ücret tanımlayabilirsiniz.</small></div>
                    {
                        item.Price < 0 ? (<div className='alert alert-danger mt-2'> <small>* Paket ücreti 0 TL'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        item.Price === null ? (<div className='alert alert-danger mt-2'> <small>* Paket ücreti girilmesi zorunludur.</small></div>) : ''
                    }

                </div>

                <div className='form-group my-4'>
                    <label>Paket Kaç Adet Rezzervasyon İçeriyor? *</label>
                    <div className="input-group mb-3 ">
                        <input value={item.Count} type="number" onInput={(e) => setItem({ ...item, Count: Number(e.target.value) })} className="form-control" placeholder='Adet Sayısı' />
                    </div>
                    <div> <small>* Kullanıcılar bu paketi satın aldığında kaç kez rezzervasyon oluşturabilecek.</small></div>
                    {
                        item.Count < 1 ? (<div className='alert alert-danger mt-2'> <small>* Adet Sayısı 1'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        item.Count === null ? (<div className='alert alert-danger mt-2'> <small>* Adet Sayısı girilmesi zorunludur.</small></div>) : ''
                    }

                </div>
                {
                    props.alertBox.status === 'update' ?
                        <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                }
                <div className='mt-4 d-flex justify-content-end'>
                    <button onClick={() => UPDATE_IT()} disabled={props.isLoading} className='btn btn-secondary d-flex'>
                        {
                            props.isLoading ?
                                <Loading size="sm"></Loading>
                                : ''
                        } Güncelle
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.customerPackages.isLoadingUpdate,
    alertBox: state.customerPackages.alertBox
})

const mapDispatchToProps = { SET_UPDATE_CUSTOMER_PACKAGES, SET_CUSTOMER_PACKAGES_LOADING, SET_ALERT_BOX }

export default connect(mapStateToProps, mapDispatchToProps)(InsertProcessing)