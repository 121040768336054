import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

export const DailyResarvationStatistic = (props) => {
    const [total, setTotal] = useState(0);
    useEffect(() => {
        if (props.companyStats.status)
            setTotal(props.companyStats.DailyCanceledRezCount + props.companyStats.DailyCompletedRezCount + props.companyStats.DailyNotCompletedRezCount)
    }, [props.companyStats.status])
    return (
        <div className="col-xl-12 col-sm-6">
            <div className="card">
                <div className="card-header border-0 pb-0">
                    <h4 className="mb-0">Günlük Rezzervasyonlarım</h4>
                </div>
                <div className="card-body">
                    <div className="progress mb-4" style={{ height: "13px" }}>
                        <div className="progress-bar gradient-5 progress-animated" style={{ width: `${props.companyStats.DailyNotCompletedRezCount * 100 / total}%`, height: "13px" }} >
                            <span className="sr-only">{props.companyStats.DailyNotCompletedRezCount * 100 / total}% Complete</span>
                        </div>
                    </div>
                    <div className="progress mb-4" style={{ height: "13px" }}>
                        <div className="progress-bar gradient-6 progress-animated" style={{ width: `${props.companyStats.DailyCompletedRezCount * 100 / total}%`, height: "13px" }}>
                            <span className="sr-only">{props.companyStats.DailyCompletedRezCount * 100 / total}% Complete</span>
                        </div>
                    </div>
                    <div className="progress default-progress" style={{ height: "13px" }}>
                        <div className="progress-bar gradient-7 progress-animated" style={{ width: `${props.companyStats.DailyCanceledRezCount * 100 / total}%`, height: "13px" }}>
                            <span className="sr-only">{props.companyStats.DailyCanceledRezCount * 100 / total}% Complete</span>
                        </div>
                    </div>
                    <div className="d-flex mt-4 progress-bar-legend align-items-center justify-content-between">
                        <div className="d-flex">
                            <span className="marker gradient-5"></span>
                            <div>
                                <p className="status">Bekleyen</p>
                                <span className="result">{props.companyStats.DailyNotCompletedRezCount}</span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <span className="marker gradient-6"></span>
                            <div>
                                <p className="status">Tamamlanan</p>
                                <span className="result">{props.companyStats.DailyCompletedRezCount}</span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <span className="marker gradient-7"></span>
                            <div>
                                <p className="status">İptal Edilen</p>
                                <span className="result">{props.companyStats.DailyCanceledRezCount}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DailyResarvationStatistic)