export const theme = theme => {
    return {
        ...theme,
        borderRadius: "1rem",
        colors: {
            ...theme.colors,
            color: "red",
            primary25: "rgba(253, 95, 0, 0.2)",
            primary: "#fd5f00"
        },
        spacing: {
            baseUnit: 4,
            controlHeight: "3.5rem",
            menuGutter: 8
        }
    }
};