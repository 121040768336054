import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import EditCustomer from '../Customers/EditCustomer';

export const CustomerCard = (props) => {
    const [EditModal, setEditModal] = useState(false);
    return (
        <div className='card'>
            <div className='card-title p-4'>
                Müşteri Bilgileri 
            </div>
            <div className='card-body row'>
                <div className='col-12 col-md-6 col-lg-4'>
                    <small> Üye Numarası</small>
                    <p className='text-primary'>{props.customer.Id}</p>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                    <small>Adınız Soyadınız</small>
                    <p className='text-primary'>{props.customer.Fullname}</p>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                    <div className='col-12 col-md-12 col-lg-12'>
                        <small>Telefon Numaranız</small>
                        <p className='text-primary'>{props.customer.Phone}</p>
                    </div>
                </div>
                <div className='my-3 d-flex justify-content-end'>
                    <button
                        onClick={() => setEditModal(true)} className='btn btn-secondary' >
                        Bilgileri Değiştir </button>
                </div>
                <div className='alert alert-secondary'>
                    * Müşteri bilgilerinizi buradan güncelleyebilirsiniz.
                </div>
            </div>
            <Modal centered className="fade" size='lg' onHide={() => setEditModal(false)} show={EditModal}>
                <Modal.Header>
                    <Modal.Title>{props.customer.Fullname} çalışanını güncelle</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setEditModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    <EditCustomer updateCustomer={props.updateCustomer} index={-1} editModal={EditModal} EditCustomer={props.customer}></EditCustomer>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCard)