export function GET_CUSTOMER_LIST(search = '', page = 1, limit = 10) {
    return async (dispatch) => {
        dispatch(SET_LOADING_CUSTOMER(true))
        const response = await window.$api.get(`Customers/GetAll?searchKey=${search}&page=${page}&limit=${limit}`)
        if (response.message === 'OK' && response.result === 'OK') {
            if (response.data === null) dispatch(SET_CUSTOMER_LIST([], response.count))
            else dispatch(SET_CUSTOMER_LIST(response.data, response.count))
        } else dispatch(SET_CUSTOMER_LIST([], 0))
        dispatch(SET_LOADING_CUSTOMER(false))
    }
}

function SET_CUSTOMER_LIST(data = [], count = 1) {
    return {
        type: 'SET_CUSTOMER_LIST',
        payload: {
            data: data === null ? [] : data,
            count
        }
    }
}
export function SET_LOADING_CUSTOMER(data) {
    return {
        type: 'SET_LOADING_CUSTOMER',
        payload: data
    }
}
export function SEND_NEW_CUSTOMER_TO_DB(data) {
    return async (dispatch) => {
        dispatch(SET_LOADING_WHEN_INSERTING_CUSTOMER(true))
        const response = await window.$api.post(`Customers/Create`, data);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_THAT_CUSTOMER_OF_INSERTED_TO_LIST({ ...data, Id: response.data }, {
                status: true,
                variant: 'success',
                message: 'Müşteriyi başarıyla oluşturduk! Artık müşteriye rezzervasyon oluşturabilirsiniz'
            }))
            dispatch(SET_RESET_LOADING_CUSTOMER())
        } else if (response.message === 'REGISTERED') {
            dispatch(SET_THAT_CUSTOMER_OF_INSERTED_TO_LIST({}, {
                status: true,
                variant: 'danger',
                message: `"${data.Phone}" numarası daha önce sisteme kayıt edilmiş görünüyor. Lütfen farklı bir numara ile müşteri kaydı oluşturun.`
            }))
        }
        else {
            dispatch(SET_THAT_CUSTOMER_OF_INSERTED_TO_LIST({}, {
                status: true,
                variant: 'danger',
                message: 'Müşteri kaydı gerçekleştirelemedi. Lütfen tekrar deneyin!'
            }))
        }
        dispatch(SET_LOADING_WHEN_INSERTING_CUSTOMER(false))

    }
}
export function SEND_UPDATE_CUSTOMER_TO_DB(data) {
    return async (dispatch) => {
        dispatch(SET_LOADING_WHEN_INSERTING_CUSTOMER(true))
        const response = await window.$api.put(`Customers/${data.Id}`, data);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_THAT_CUSTOMER_OF_UPDATED_TO_LIST({ ...data, Id: response.data }, {
                status: true,
                variant: 'success',
                message: 'Müşteri hesabını başarıyla güncelledik!'
            }))
            dispatch(SET_RESET_LOADING_CUSTOMER())
        } else if (response.message === 'REGISTERED') {
            dispatch(SET_THAT_CUSTOMER_OF_UPDATED_TO_LIST({}, {
                status: true,
                variant: 'danger',
                message: `"${data.Phone}" numarası daha önce sisteme kayıt edilmiş görünüyor. Lütfen farklı bir numara ile kayıt oluşturun.`
            }))
        }
        else {
            dispatch(SET_THAT_CUSTOMER_OF_UPDATED_TO_LIST({}, {
                status: true,
                variant: 'danger',
                message: 'Müşteri kaydı gerçekleştirelemedi. Lütfen tekrar deneyin!'
            }))
        }
        dispatch(SET_LOADING_WHEN_INSERTING_CUSTOMER(false))

    }
}


function SET_THAT_CUSTOMER_OF_INSERTED_TO_LIST(data, alertBox) {
    return {
        type: 'SET_THAT_CUSTOMER_OF_INSERTED_TO_LIST',
        payload: { data, alertBox }
    }
}

function SET_THAT_CUSTOMER_OF_UPDATED_TO_LIST(data, alertBox) {
    return {
        type: 'SET_THAT_CUSTOMER_OF_UPDATED_TO_LIST',
        payload: { data, alertBox }
    }
}

function SET_LOADING_WHEN_INSERTING_CUSTOMER(data) {
    return {
        type: 'SET_LOADING_WHEN_INSERTING_CUSTOMER',
        payload: data
    }
}

export function SET_ALERT_BOX(payload) {
    return {
        type: 'SET_ALERT_BOX',
        payload
    }
}

export function SET_REMOVE_CUSTOMER_LIST(payload) {
    return {
        type: 'SET_REMOVE_CUSTOMER_LIST',
        payload
    }
}

export function SET_REMOVE_CUSTOMER(index, id) {
    return async (dispatch) => {
        dispatch(SET_LOADING_WHEN_INSERTING_CUSTOMER(true))

        const response = await window.$api.delete(`Customers/${id}`);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_REMOVE_CUSTOMER_LIST({
                status: 'remove',
                variant: 'success',
                message: `Tebrikler! Müşteri başarıyla kaldırdık..`,
                data: { index, id },
            }))
            dispatch(SET_RESET_LOADING_CUSTOMER())
        } else
            dispatch(SET_REMOVE_CUSTOMER_LIST({
                status: 'remove',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Müşteri kaldıramadık, lütfen bir süre sonra tekrar deneyin..`,
                data: { index: -1, }
            }))


    }
}

function SET_RESET_LOADING_CUSTOMER() {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(SET_ALERT_BOX({
                variant: 'danger',
                status: false,
                message: ''
            }))
            dispatch(SET_LOADING_CUSTOMER(true))
            setTimeout(() => {
                dispatch(SET_LOADING_CUSTOMER(false))

            }, 1500);
        }, 2500);
    }
}