/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
/// Link
import { Link, NavLink } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../assets/context/ThemeContext";
import { GET_ROOMS_LIST } from '../../store/module/rooms/action'
import { GET_WORKER_LIST } from '../../store/module/workers/action'

import { GET_PROCESSING_LIST } from "../../store/module/processing/action";
import Loading from "../../components/shared/loading";
/// Image
//import profile from "../../../assets/images/profile/pic1.jpg";
//import plus from "../../../assets/images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el, {
      toggle: true
    });
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {

  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  useEffect(async () => {
    await props.GET_ROOMS_LIST()
    props.GET_WORKER_LIST()
    props.GET_PROCESSING_LIST()

    // oda listesi alınır
    // worker list alınır.
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector('.heart');
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener('click', heartBlast);

  }, []);
  let scrollPosition = useScrollPosition();
  ///  window.location.pathname
  /// Active menu
  function SET_ROUTE(path = '') {
    return '/' + props.activeCompany.Id + path
  }
  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      {
        props.isLoading ? <div className="text-center mt-4"> <Loading variant="primary" description="Menü Yükleniyor..."></Loading> </div> :
          <PerfectScrollbar className="deznav-scroll">
            <MM className="metismenu" id="menu">
              <li>
                <NavLink exact activeClassName='bg-primary text-white' to={SET_ROUTE()} >
                  <i className="flaticon-025-dashboard"></i>
                  <span className="nav-text">Ana Sayfa </span>
                </NavLink>
              </li>
              {
                props.rooms.length !== 0 ? <li>
                  <NavLink to="#" className="has-arrow ai-icon">
                    <i className="flaticon-050-info"></i>
                    <span className="nav-text ">Odalar</span>
                  </NavLink>
                  <ul >
                    {
                      props.rooms.map((room, i) => (
                        <li key={i}><NavLink exact exacActiveClassName='bg-primary text-white' to={SET_ROUTE(`/room/${room.Id}`)}>{room.Description}</NavLink></li>
                      ))
                    }
                    <li><NavLink exact activeClassName='bg-primary text-white' className="text-secondary" to={SET_ROUTE(`/add-room`)}>Yeni Oda Ekle</NavLink></li>
                  </ul>
                </li> : ''
              }
              <li>
                <NavLink  className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-user-8"></i>
                  <span className="nav-text">Müş. İşlemleri </span>
                </NavLink>
                <ul >
                  <li >
                    <NavLink exact activeClassName='bg-primary text-white' to={SET_ROUTE('/customers')} >
                      <span>Müşteriler</span>
                    </NavLink>
                  </li>
                  <li >
                    <NavLink exact activeClassName='bg-primary text-white' to={SET_ROUTE('/customersTag')} >
                      <span>Müş. Etiketleri</span>
                    </NavLink>
                  </li>
                  <li >
                    <NavLink exact activeClassName='bg-primary text-white' to={SET_ROUTE('/customerPackages')} >
                      <span>Müş. Paketleri</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li >
                <NavLink exact activeClassName='bg-primary text-white' to={SET_ROUTE('/process')} >
                  <i className="flaticon-041-graph"></i>
                  <span className="nav-text">Hizmetler</span>
                </NavLink>
              </li>
              <li >
                <NavLink exact activeClassName='bg-primary text-white' to={SET_ROUTE('/workers')} >
                  <i className="flaticon-381-user-9"></i>
                  <span className="nav-text">Çalışanlar</span>
                </NavLink>
              </li>
              {
                props.user.RoleId == 1 || props.user.RoleId === 2 ?
                  < li >
                    <NavLink  className="has-arrow ai-icon" to="#" >
                      <i className="flaticon-381-settings-6"></i>
                      <span className="nav-text">Ayarlar </span>
                    </NavLink>
                    <ul >
                      <li>
                        <NavLink exact activeClassName='bg-primary text-white'

                          to={SET_ROUTE('/add-worker')}
                        >
                          Yeni Çalışan Ekle
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact activeClassName='bg-primary text-white'

                          to={SET_ROUTE('/add-room')}
                        >
                          Yeni Oda Ekle
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact activeClassName='bg-primary text-white'

                          to={SET_ROUTE('/company-settings')}
                        >
                          Firma Ayarları
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  : ''
              }
              <li >
                <NavLink  to={''} >
                  <i className="flaticon-381-help-1"></i>
                  <span className="nav-text">Destek</span>
                </NavLink>
              </li>
            </MM>
            <div className="copyright">
              <p><strong>Rezzervation App</strong> © 2022 All Rights Reserved</p>
              <p className="fs-12">Made with <span className="heart"></span> by Eralp Software</p>
            </div>
          </PerfectScrollbar>
      }
    </div >
  );
};
const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
  user: state.user.activeUser.User,
  rooms: state.rooms.roomsList,
  isLoading: state.rooms.roomIsLoading,
})

const mapDispatchToProps = { GET_ROOMS_LIST, GET_WORKER_LIST, GET_PROCESSING_LIST }

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);

