import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { JustDate } from '../../../plugins/SET_DATE_FORMATTER'
import Loading from '../../shared/loading'
import { theme } from '../../../plugins/select.theme'
import { GET_CUSTOMER_LIST } from '../../../store/module/customers/action'
import AddCustomer from '../Customers/AddCustomer'
import CustomerTags from '../Customers/CustomerTags'
import PackagesOfCustomerList from './PackagesOfCustomerList'
export const Room = (props) => {
    let [EmptyRezModal, setEmptyRezModal] = useState(false);
    let [SelectedPackage, setSelectedPackage] = useState({ Id: -1 });
    let [CustomerTagModal, setCustomerTagModal] = useState(false);
    let [RezervationLoading, setRezervationLoading] = useState(false);
    let [LastAvailableRezervationTime, setLastAvailableRezervationTime] = useState(0);
    const [SelectedProcessing, setSelectedProcessing] = useState([]);
    let [customerItem, setCustomerItem] = useState(null)
    let [alertBox, setAlertBox] = useState({ status: false, message: '', variant: 'danger' });
    let [times, setTimes] = useState([]);
    const [currency, setCurrency] = useState('TRY');
    const [room, setRoom] = useState(props.room)
    const [AddCustomerModal, setAddCustomerModal] = useState(false);
    const [NewRezervation, setNewRezervation] = useState({
        statusRezervation: false,
        "RoomId": props.room.Id,
        "CustomerId": -1,
        "Note": "",
        "Price": null,
        "Duration": Number(props.room.Interval),
        "IsCanceled": 0,
        "CancelText": null,
        "Processes": "",
        "RezervationDate": "",
        "RezervationTime": 0
    })
    useEffect(() => {
        if (props.RezervationModal) {
            setTimes(props.times);
            setRoom(props.room);
            setCustomerItem(null);
            SHOW_REZERVATION_DETAIL(props.item, props.index)
            setAlertBox({
                variant: 'danger',
                status: false,
                message: ''
            })
        }
    }, [props.RezervationModal])
    async function SEND_REMOVE_CLOSED_REZERVATION() {
        setRezervationLoading(true)
        const response = await window.$api.put(`Rezervations/${props.item.rez.Id}`, {
            "IsCanceled": 1,
            "CancelText": ``,
            "Processes": props.item.rez.ProcessesData
        });
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Rezzervasyonunuz başarıyla kaldırıldı. Oda rezzervasyonları yenileniyor. Lütfen bekleyin...'
            })
            props.GET_REZERVATION_BY_ROOM(new Date(new Date(props.date).setHours(4)).toISOString().split('T')[0], props.room.Id)
            setTimeout(() => {
                props.setRezervationModal(false)
            }, 2500);
        } else {
            setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Rezzervasyonunuz kaldırılamadı. Lütfen sayfayı yenileyip tekrar deneyin.'
            })
        }
        setRezervationLoading(false)
    }
    async function SEND_EMPTY_REZERVATION() {
        setAlertBox({
            variant: 'danger',
            status: false,
            message: ''
        })
        if (!CHECK_AVAILABLE_REZERVATION()) return;
        setRezervationLoading(true)
        const response = await window.$api.post('Rezervations/Create', {
            statusRezervation: false,
            "RoomId": props.room.Id,
            "CustomerId": null,
            "Note": "",
            "Price": 0,
            "Duration": Number(NewRezervation.Duration),
            "IsCanceled": 0,
            "CancelText": null,
            "Processes": "",
            "RezervationDate": new Date(new Date(props.date).setHours(4)).toISOString().split('T')[0],
            "RezervationTime": NewRezervation.time
        })
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Rezzervasyonunuz başarıyla kayıt edildi. Oda rezzervasyonları yenileniyor. Lütfen bekleyin...'
            })
            props.GET_REZERVATION_BY_ROOM(new Date(new Date(props.date).setHours(4)).toISOString().split('T')[0], props.room.Id)

            setTimeout(() => {
                setEmptyRezModal(false);
                props.setRezervationModal(false)

                setAlertBox({
                    variant: 'danger',
                    status: false,
                    message: ''
                })
            }, 2500);
        } else {
            setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Rezzervasyonunuz eklenemedi. Lütfen sayfayı yenileyip tekrar deneyin.'
            })
        }
        setRezervationLoading(false)

    }
    async function SEND_REZERVATION() {
        setAlertBox({
            variant: 'danger',
            status: false,
            message: ''
        })
        if (SelectedProcessing === null || SelectedProcessing.length === 0 || customerItem === null || NewRezervation.Price < 0 || NewRezervation.Price === null
            || NewRezervation.Duration < 0 || !CHECK_AVAILABLE_REZERVATION()) {
            return setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Tüm alanları eksiksiz doldurmalısın. Lütfen bilgileri kontrol edin.'
            })
        }
        setRezervationLoading(true)
        const response = await window.$api.post('Rezervations/Create', {
            ...NewRezervation,
            CustomerId: customerItem.Id,
            Currency: currency,
            Processes: SelectedProcessing.map(s => s.Id).join(','),
            Price: SelectedPackage.Id === -1 ? Number(NewRezervation.Price) : 0,
            RezervationDate: new Date(new Date(props.date).setHours(4)).toISOString().split('T')[0],
            CustomerPackageId: SelectedPackage.Id === -1 ? null : SelectedPackage.CustomerPackageId,
            "CreateText": `Sevgili ${customerItem.Fullname}, ${props.company.Name} tarafından ${JustDate(NewRezervation.RezervationDate)} Saat: ${convertM2H(NewRezervation.RezervationTime)} tarihine rezervasyonunuz oluşturulmuştur. Rezervasyon saatinizden 15 dakika önce randevu alanında bulunmanızı rica ederiz. Detaylı bilgi için bizimle ile iletişime geçebilirsiniz.`,
        })
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Rezzervasyonunuz başarıyla kayıt edildi. Oda rezzervasyonları yenileniyor. Lütfen bekleyin...'
            })
            props.GET_REZERVATION_BY_ROOM(new Date(new Date(props.date).setHours(4)).toISOString().split('T')[0], props.room.Id)

            setTimeout(() => {
                props.setRezervationModal(false)
                setAlertBox({
                    variant: 'danger',
                    status: false,
                    message: ''
                })
            }, 2500);
        } else {
            setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Rezzervasyonunuz eklenemedi. Lütfen sayfayı yenileyip tekrar deneyin.'
            })
        }
        setRezervationLoading(false)
    }
    function SET_CUSTOMER(e) {
        setCustomerItem(e);
    }
    function CALCULATE_END_REZERVATION_DATE() {
        if (!NewRezervation.statusRezervation) return '...'
        else return convertM2H(NewRezervation.RezervationTime + NewRezervation.Duration)
    }
    async function GET_CUSTOMER_LIST_W_SEARCH(search, callback) {

        const response = await window.$api.get(`Customers/GetAll?searchKey=${search}`)
        if (response.message === 'OK' && response.result === 'OK') {
            callback(response.data)
        } else callback()
    }
    function CHECK_AVAILABLE_REZERVATION() {
        return ((NewRezervation.RezervationTime + NewRezervation.Duration) <= LastAvailableRezervationTime)
    }
    function convertH2M(timeInHour) {
        var timeParts = timeInHour.split(":");
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }
    function GUESS_PROCESSING_DURATION(e) {
        return e === null || e.length === 0 ? props.room.Interval : e.map(function (a) { return a.Duration; })
            .reduce(function (a, b) { return a + b; })
    }
    function GUESS_PROCESSING_PRICE(e, crncy = currency) {
        return e === null || e.length === 0 ? props.room.Interval : e.map(function (a) { return a[`Price${crncy}`]; })
            .reduce(function (a, b) { return a + b; }).toFixed(2)
    }
    function SET_SELECTED_PROCESSING(e, crncy = currency) {
        setSelectedProcessing(e);
        setNewRezervation({
            ...NewRezervation,
            Price: GUESS_PROCESSING_PRICE(e, crncy),
            Duration: GUESS_PROCESSING_DURATION(e)
        })
    }
    function SET_STATE_NEW_REZERVATION(key, val) {
        setNewRezervation({
            ...NewRezervation,
            [key]: val
        })
    }
    function SET_CURRENCY(val) {
        setCurrency(val)
        setTimeout(() => {
            SET_SELECTED_PROCESSING(SelectedProcessing, val);
        }, 300);

    }
    function convertM2H(n) {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return (rhours < 10 ? `0${rhours}` : rhours) + ":" + (rminutes < 10 ? `0${rminutes}` : rminutes);
    }
    function SET_REZERVATION(timeData, index) {
        setNewRezervation({ ...NewRezervation, statusRezervation: true, ...timeData, RezervationTime: timeData.time, RezervationDate: props.date });
        const arr = [...props.times]
        arr.splice(0, index)
        const result = arr.filter(a => a.status);
        if (result.length !== 0) {
            setLastAvailableRezervationTime(result[0].rez.RezervationTime); // bir sonraki rezzervasyona kadar rezzervasyona izin ver.
        } else {
            setLastAvailableRezervationTime(convertH2M(room.ClosedAt)); // firma kapanış saatine kadar olan saate kadar rezzervasyon kabul et.
        }
        // setLastAvailableMinute()
        props.setRezervationModal(true);
    }
    function SHOW_REZERVATION_DETAIL(item, i) {
        if (!item.disabled)
            SET_REZERVATION(item, i)
        else return
    }
    return (
        <Modal size={props.item.status ? 'md' : "xl"} centered className="fade" onHide={() => props.setRezervationModal(false)} show={props.RezervationModal}>
            <Modal.Header>
                <Modal.Title> <i className='flaticon-381-bookmark'></i> {props.item.status ? 'Rezzervasyon Saati Kilitli' : "Yeni Rezzervasyon Oluştur"}</Modal.Title>
                <button
                    className="btn btn-close"
                    onClick={() => props.setRezervationModal(false)}
                >

                </button>
            </Modal.Header>
            {
                props.item.status ? <Modal.Body>
                    {
                        alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                    }
                    <p>Seçmiş olduğunuz saat rezzervasyonya kapalı olarak görünmektedir. Dilerseniz rezzervasyon saatini tekrardan açabilirsiniz.
                        rezzervasyona açtığınız taktirde seçili saate yeniden rezzervasyon oluşturabileceksiniz. <br></br><br></br><strong>Bunu yapmak ister misiniz?</strong></p>
                    <div className='mt-4 d-flex justify-content-end'>
                        <button className='btn btn-light mx-2' onClick={() => props.setRezervationModal(false)}>Hayır, Rezzervasyon kapalı kalsın</button>
                        <button disabled={RezervationLoading} className='btn btn-secondary mx-2' onClick={() => SEND_REMOVE_CLOSED_REZERVATION()}>
                            {
                                RezervationLoading ? <Loading size="sm"></Loading> : ''
                            }

                            Evet, Rezzervasyon kilidini kaldır</button>
                    </div>
                </Modal.Body> :
                    <Modal.Body>
                        <Modal centered className="fade" onHide={() => setEmptyRezModal(false)} show={EmptyRezModal}>
                            <Modal.Header>
                                <Modal.Title> <i className='flaticon-381-bookmark'></i> Rezzervasyon Kapatma </Modal.Title>
                                <button
                                    className="btn btn-close"
                                    onClick={() => setEmptyRezModal(false)}
                                >

                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                                }
                                <div className='my-4'>
                                    <div className='d-flex justify-content-between flex-wrap'>
                                        <button disabled={NewRezervation.Duration < 15} onClick={() => SET_STATE_NEW_REZERVATION('Duration', NewRezervation.Duration - 15)} className='btn btn-xs btn-secondary'>-15dk azalt</button>
                                        <button disabled={NewRezervation.Duration < 30} onClick={() => SET_STATE_NEW_REZERVATION('Duration', NewRezervation.Duration - 30)} className='btn btn-xs btn-warning'>-30dk azalt</button>
                                        <button disabled={NewRezervation.Duration < 60} onClick={() => SET_STATE_NEW_REZERVATION('Duration', NewRezervation.Duration - 60)} className='btn btn-xs btn-primary'>-1 saat azalt</button>
                                        <button disabled={NewRezervation.Duration < 120} onClick={() => SET_STATE_NEW_REZERVATION('Duration', NewRezervation.Duration - 120)} className='btn btn-xs btn-info'>-2 saat azalt</button>
                                    </div>
                                    <div className='form-group my-4'>
                                        <label>Rezzervasyon Süreci (dk)</label>
                                        <input value={NewRezervation.Duration} type="number" onInput={(e) => SET_STATE_NEW_REZERVATION('Duration', Number(e.target.value))} placeholder='Rezzervasyon süresini girin..' className='form-control'></input>
                                    </div>
                                    <div className='d-flex justify-content-between flex-wrap'>
                                        <button onClick={() => SET_STATE_NEW_REZERVATION('Duration', NewRezervation.Duration + 15)} className='btn btn-xs btn-secondary'>+15dk arttır</button>
                                        <button onClick={() => SET_STATE_NEW_REZERVATION('Duration', NewRezervation.Duration + 30)} className='btn btn-xs btn-warning'>+30dk arttır</button>
                                        <button onClick={() => SET_STATE_NEW_REZERVATION('Duration', NewRezervation.Duration + 60)} className='btn btn-xs btn-primary'>+1 saat arttır</button>
                                        <button onClick={() => SET_STATE_NEW_REZERVATION('Duration', NewRezervation.Duration + 120)} className='btn btn-xs btn-info'>+2 saat arttır</button>
                                    </div>
                                    <div className='alert alert-secondary mt-2'>
                                        Yukarıdan süreyi hızlıca artırabilir veya azaltabilirsiniz.
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <small className='text-light'>Başlangıç Saati</small>
                                        <h2>
                                            <span className='text-primary'>{convertM2H(NewRezervation.RezervationTime)}</span>
                                        </h2>
                                    </div>
                                    <div className='col-6'>
                                        <small className='text-light'>Ort. Bitiş Saati</small>
                                        <h2>
                                            <span className='text-primary'>{CALCULATE_END_REZERVATION_DATE()}</span>
                                        </h2>
                                    </div>
                                </div>
                                {
                                    CHECK_AVAILABLE_REZERVATION() ?
                                        '' :
                                        <div className='alert alert-danger'>
                                            Bir sonraki rezzervasyon veya Oda kapanış saatiniz: <strong className='mx-3' style={{ fontSize: '20px' }}>{convertM2H(LastAvailableRezervationTime)}</strong>
                                            <div className='mt-2'>Kapatmak istediğiniz rezzervasyonun
                                                bitiş saati <strong className='mx-3' style={{ fontSize: '20px' }}> {CALCULATE_END_REZERVATION_DATE()}</strong></div>

                                            <div className='mt-2'>Lütfen rezzervasyon sürecini kısaltın. Diğer durumlarda rezzervasyonu kapatamayacaksınız !!!</div>
                                        </div>
                                }
                                <div className='mt-4'>
                                    Yukarıdaki saatler arasını rezzervasyona kapatmak üzeresiniz. Bu işlemi gerçekleştirmek istiyor musunuz?
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    onClick={() => setEmptyRezModal(false)}
                                    className='btn btn-light'>Kapat</button>
                                <button onClick={() => SEND_EMPTY_REZERVATION()} disabled={RezervationLoading} className='btn btn-secondary d-flex'>
                                    {
                                        RezervationLoading ? <Loading size="sm"></Loading> : ''
                                    }
                                    Evet, Rezzervasyona kapat</button>
                            </Modal.Footer>
                        </Modal>
                        {
                            NewRezervation.statusRezervation ? <div className='container-fluid'>
                                <div className={`row justify-content-center align-items-${customerItem === null ? 'start' : 'start'}`}>
                                    <div className='col-12 col-lg-9 col-xl-8'>
                                        <div className='form-group my-4'>
                                            <label>Müşterinizi Seçin</label>
                                            <AsyncSelect
                                                theme={theme}
                                                defaultValue={customerItem}
                                                placeholder="Tel no veya isime göre arayabilirsiniz.."
                                                cacheOptions
                                                getOptionLabel={(opt) => <span>{opt.Fullname} - <span className='text-success'>{opt.Phone}</span> - <span className='text-secondary'>{opt.Email}</span></span>}
                                                noOptionsMessage={() => 'Müşteri arayın..'}
                                                getOptionValue={(opt) => opt.Id}
                                                loadOptions={GET_CUSTOMER_LIST_W_SEARCH}
                                                defaultOptions
                                                onChange={(e) => SET_CUSTOMER(e)}
                                            />
                                            <div> <small>* Telefon numarası veya Müşteri isim ve soyisimi ile arayabilirsiniz.</small></div>
                                        </div>
                                        {
                                            customerItem !== null ?
                                                <div>
                                                    <div className='row justify-content-end'>
                                                        <div className='col-8 col-md-6 col-lg-3'>
                                                            <select className='form-control' value={currency} onChange={(e) => SET_CURRENCY(e.target.value)}>
                                                                <option value={'TRY'}>Türk Lirası ({currency} )</option>
                                                                <option value={'USD'}>Dolar ($)</option>
                                                                <option value={'EUR'}>Euro (€)</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <label>Müşteriye uygulanacak hizmetleri seçin</label>
                                                    <Select
                                                        defaultValue={SelectedProcessing}
                                                        placeholder="Odanınızın Hizmetlerini Seçin"
                                                        isMulti={true}
                                                        name="Fullname"
                                                        isSearchable={false}
                                                        onChange={(e) => SET_SELECTED_PROCESSING(e)}
                                                        options={props.room.Processes}
                                                        getOptionLabel={(option) => <div>    <i className="flaticon-041-graph"></i> {option.Name} ({option.Duration}dk.) -s <span className='text-success'>{currency} {option[`Price${currency}`].toFixed(2)}</span> </div>}
                                                        getOptionValue={(val) => val.Id}
                                                        theme={theme}
                                                    >
                                                    </Select>
                                                    {
                                                        SelectedProcessing !== null && SelectedProcessing.length !== 0 ?
                                                            <div>
                                                                <div className='alert alert-secondary mt-3'>
                                                                    Seçmiş olduğunuz hizmetler ile işlemleriniz toplam süresi <strong className='text-success'>{GUESS_PROCESSING_DURATION(SelectedProcessing)}dk</strong> olacaktır.
                                                                </div>
                                                                <div className='alert alert-secondary mt-3'>
                                                                    Seçmiş olduğunuz hizmetler ile işlemleriniz toplam ücreti <strong className='text-success'>{currency} {GUESS_PROCESSING_PRICE(SelectedProcessing)}</strong> olacaktır.
                                                                </div>
                                                                <div className='mt-2'><small>* Aşağıdan bu rezzervasyona özel <span className='text-primary'>süreyi</span> ve <span className='text-primary'>ücretini</span> değiştirebilirsiniz.</small></div>
                                                                <div> <small>Hizmet sürelerini ve ücretlerini <Link className='text-primary' to={`/${props.company.Id}/process`} >'Hizmetlerim'</Link> sayfasından değiştirebilirsiniz.</small></div>

                                                            </div> : <div className='mt-2 text-danger'><small>* En az 1 adet hizmet seçmeniz gerekmektedir.</small></div>

                                                    }
                                                    {
                                                        SelectedProcessing === null ? '' :
                                                            SelectedProcessing.length === 0 ? '' :
                                                                <PackagesOfCustomerList
                                                                    SelectedPackage={SelectedPackage}
                                                                    SetSelectedPackage={setSelectedPackage}
                                                                    currency={currency}
                                                                    CustomerItem={customerItem}
                                                                    SelectedProcessing={SelectedProcessing}></PackagesOfCustomerList>
                                                    }
                                                    <div className='row'>
                                                        <div className='col-12 col-lg-6'>
                                                            {
                                                                SelectedPackage.Id === -1 ? <div className='form-group my-4'>
                                                                    <label>Rezzervasyon Ücreti ({currency})</label>
                                                                    <input value={NewRezervation.Price} type="number" onInput={(e) => SET_STATE_NEW_REZERVATION('Price', e.target.value > -1 ? Number(e.target.value).toFixed(2) : null)} placeholder='Rezzervasyon fiyatı girin..' className='form-control'></input>
                                                                </div> : <div className='alert alert-secondary mt-4 text-center'>
                                                                    Paket seçtiğiniz için müşteri ödeme yapmayacaktır.
                                                                </div>
                                                            }

                                                        </div>
                                                        <div className='col-12 col-lg-6'>
                                                            <div className='form-group my-4'>
                                                                <label>Rezzervasyon Süreci (dk)</label>
                                                                <input value={NewRezervation.Duration} type="number" onInput={(e) => SET_STATE_NEW_REZERVATION('Duration', Number(e.target.value))} placeholder='Rezzervasyon süresini girin..' className='form-control'></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        GUESS_PROCESSING_PRICE(SelectedProcessing) !== NewRezervation.Price || GUESS_PROCESSING_DURATION(SelectedProcessing) !== NewRezervation.Duration ?
                                                            <div> <a href='#'><small className='text-primary' onClick={() => SET_SELECTED_PROCESSING(SelectedProcessing)}> - Toplam Hizmet ücretini ({currency} {GUESS_PROCESSING_PRICE(SelectedProcessing)}) ve Toplam Hizmet Süresini ({GUESS_PROCESSING_DURATION(SelectedProcessing)}dk.) hizmetlere göre ayarlamak için tıklayın. </small></a></div> : ''
                                                    }
                                                    {
                                                        CHECK_AVAILABLE_REZERVATION() ?
                                                            '' :
                                                            <div className='alert alert-danger'>
                                                                Bir sonraki rezzervasyon veya Oda kapanış saatiniz: <strong className='mx-3' style={{ fontSize: '20px' }}>{convertM2H(LastAvailableRezervationTime)}</strong>
                                                                <div className='mt-2'>Oluşturmak istediğiniz rezzervasyonun
                                                                    bitiş saati <strong className='mx-3' style={{ fontSize: '20px' }}> {CALCULATE_END_REZERVATION_DATE()}</strong></div>

                                                                <div className='mt-2'>Lütfen Rezzervasyon Saatini  değiştirin veya rezzervasyon sürecini kısaltın. Diğer durumlarda rezzervasyon oluşturamayacaksınız !!!</div>
                                                            </div>
                                                    }
                                                    <div className='form-group my-4'>
                                                        <label>Rezzervasyon Notu</label>
                                                        <textarea maxLength={250} value={NewRezervation.Note} type="text" onInput={(e) => SET_STATE_NEW_REZERVATION('Note', e.target.value)} placeholder='Rezzervasyon notu varsa girin..' className='form-control'></textarea>
                                                        <div className='d-flex justify-content-end text-light'> <small>{NewRezervation.Note.length}/250</small></div>
                                                        <div> <small>* Rezzervasyon notunuz en fazla 250 karakter olabilir.</small></div>
                                                        <div className='mt-4 alert alert-secondary'> <small>* Rezzervasyon notunuzu, "Müşteri Rezervasyon Oluşturabilir" özelliğimizi kullanıyorsanız görebilecektir.</small></div>

                                                    </div>
                                                </div>
                                                : <div>
                                                    <div className='alert mt-4 alert-secondary'>
                                                        <div className='text-center '>Rezzervasyon kaydı yapmak için öncelikle müşteriyi seçmelisiniz.</div>
                                                        <div className='text-center mt-4'>
                                                            Eğer yeni müşteri eklemek istiyorsanız
                                                        </div>
                                                        <div className='text-center mt-2'>
                                                            <button onClick={() => setAddCustomerModal(true)} className='btn btn-secondary btn-sm'>Hemen Yeni Müşteri Ekle</button>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div className='d-flex flex-column justify-content-center alert alert-primary my-4'>
                                                        Dilerseniz aşağıdaki butona basarak seçeceğiniz saatler arasında odayı rezzervasyona kapatabilirsiniz.
                                                        <div className='mt-4 text-center'>
                                                            <button
                                                                onClick={() => setEmptyRezModal(true)}
                                                                className='btn btn-primary btn-sm'> Bu Saat Aralığını Rezzervasyona Kapat</button>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div className='col-12 col-lg-3 col-xl-4'>
                                        <h4>Özet</h4>
                                        <div>
                                            <div className='card my-2'>
                                                <div className='card-body'>
                                                    <Modal centered className="fade" onHide={() => setCustomerTagModal(false)} show={CustomerTagModal}>
                                                        <Modal.Header>
                                                            <Modal.Title>Müşteri Etiketleri</Modal.Title>
                                                            <button
                                                                className="btn btn-close"
                                                                onClick={() => setCustomerTagModal(false)}
                                                            >

                                                            </button>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <CustomerTags customer={customerItem}></CustomerTags>
                                                        </Modal.Body>
                                                    </Modal>
                                                    <h6>Müşteri Bilgisi</h6>
                                                    {
                                                        customerItem === null ? <div className='text-center my-4'>
                                                            <span>Henüz müşteri seçmediniz..</span>
                                                        </div> :
                                                            <div>
                                                                <p> Adı Soyadı: {customerItem.Fullname}</p>
                                                                <p> Tel. No.:  <a target="_blank" href={`tel:${customerItem.Phone}`}>{customerItem.Phone} </a> </p>
                                                                <p> Email.:  <a target="_blank" href={`mailto:${customerItem.Email}`}>{customerItem.Email} </a></p>
                                                                <p> Instagram.: <a target="_blank" href={`https://instagram.com/${customerItem.Instagram}`}>{customerItem.Instagram} </a></p>
                                                                {
                                                                    !customerItem.LastRezervation ? ' Son Rezzervasyon: Müşterinin rezzervasyonu bulunamadı.' :
                                                                        <div>Son Rezzervasyon: {customerItem.LastRezervation.RezervationDate.includes('0001-01-01') ? 'Rezzervasyon bulunamadı.' : <div>{JustDate(customerItem.LastRezervation.RezervationDate)} {convertM2H(customerItem.LastRezervation.RezervationTime)}</div>}</div>
                                                                }
                                                                <div className='mt-4'>
                                                                    <button onClick={() => setCustomerTagModal(true)} className='btn-sm btn btn-secondary btn-block'>Müşteri Etiketleri</button>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className='card my-2'>
                                                <div className='card-body'>
                                                    <h6>Hizmetler</h6>
                                                    {
                                                        SelectedProcessing === null || SelectedProcessing.length === 0 ? '' : SelectedProcessing.map((p, i) => (
                                                            <span key={i} className='badge badge-primary m-1 light'>{p.Name}</span>
                                                        ))
                                                    }                                                <div>   <small>* Hizmetler sizin için ortalama rezzervasyon süresi ve ücreti çıkartacaktır.</small></div>
                                                    <div>   <small>* Bu süreyi ve ücreti değiştirebilirsiniz.</small></div>
                                                </div>
                                            </div>
                                            <div className='card my-1'>
                                                <div className='card-body'>
                                                    <h6>Rezzervasyon</h6>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <small className='text-light'>Rezzervasyon Günü</small>
                                                            <h3>
                                                                <span className='text-primary'>{JustDate(NewRezervation.RezervationDate)}</span>
                                                            </h3>
                                                        </div>
                                                        <div className='col-6'>
                                                            <small className='text-light'>Başlangıç Saati</small>
                                                            <h2>
                                                                <span className='text-primary'>{convertM2H(NewRezervation.RezervationTime)}</span>
                                                            </h2>
                                                        </div>
                                                        <div className='col-6'>
                                                            <small className='text-light'>Ort. Bitiş Saati</small>
                                                            <h2>
                                                                <span className='text-primary'>{CALCULATE_END_REZERVATION_DATE()}</span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <small>* Rezzervasyon başlangıç saati seçtiğiniz saattir. </small>
                                                    </div>
                                                    <div className='alert alert-secondary mt-2 d-flex align-items-center'>
                                                        * Bir sonraki Rezzervasyon veya Oda Kapanış Saati: <span className='mx-2' style={{ fontSize: '2rem' }}> {convertM2H(LastAvailableRezervationTime)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                NewRezervation.Price === null ? '' :
                                                    <div>
                                                        <div className='card'>
                                                            <div className='card-body'>
                                                                <h6>Toplam Ücret</h6>
                                                                {
                                                                    SelectedPackage.Id !== -1 ? <div className='alert alert-secondary'>Müşteri ödemesini {SelectedPackage.Detail} ile yapacaktır.</div> :
                                                                        GUESS_PROCESSING_PRICE(SelectedProcessing) === NewRezervation.Price ?
                                                                            <h2>
                                                                                <span className='text-success'>{currency} {NewRezervation.Price}</span>
                                                                            </h2> :
                                                                            <div>
                                                                                <div>
                                                                                    <span className='text-danger old-price'>{currency} {GUESS_PROCESSING_PRICE(SelectedProcessing)}</span>
                                                                                </div>
                                                                                <h2>
                                                                                    <span className='text-success'>{currency} {NewRezervation.Price}</span>
                                                                                </h2>
                                                                            </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <div><small>* Rezzervasyon bitiş süresi "Rezzervasyon süreci" kısmından otomatik hesaplanacaktır.</small></div>
                                                            <div className='mt-2'><small>* Rezzervasyon süresini veya ücretini değiştirebilirsiniz.</small></div>
                                                            <div className='mt-2'><small>* En az 1 adet hizmet seçmeniz gerekmektedir.</small></div>
                                                            <div className='mt-2'><small>* {convertM2H(LastAvailableRezervationTime)} saatinden ileri saate rezzervasyon oluşturamazsınız.</small></div>
                                                            <div className='mt-2'><small>* Rezzervasyon süresi odanın rezzervasyon aralığına uymazsa bir sonraki en yakın rezzervasyon aralık saatine yuvarlanacaktır.</small></div>

                                                            {
                                                                props.remainOfActiveCompany.PackageRemain.RezervationCount <= 0 ?
                                                                    <button disabled className='btn btn-danger my-2'>Rezzervasyon Hakkın Yok</button>
                                                                    :
                                                                    <button disabled={RezervationLoading} className='btn btn-primary btn-xxl my-2 btn-block d-flex justify-content-center' onClick={() => SEND_REZERVATION()}>
                                                                        {
                                                                            RezervationLoading ? <Loading size="sm"></Loading> : ''
                                                                        } Rezzervasyon Oluştur
                                                                    </button>
                                                            }
                                                            <button disabled={RezervationLoading} className='btn btn-light btn-xxl my-2 btn-block d-flex justify-content-center'>
                                                                Kapat
                                                            </button>
                                                        </div> </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> : <div className='text-center my-4'>Rezzervasyon bulunamadı.</div>
                        }
                        {
                            alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                        }
                        <Modal centered className="fade" size='xl' onHide={() => setAddCustomerModal(false)} show={AddCustomerModal}>
                            <Modal.Header>
                                <Modal.Title> <i className='flaticon-381-bookmark'></i> Yeni Müşteri Ekle </Modal.Title>
                                <button
                                    className="btn btn-close"
                                    onClick={() => setAddCustomerModal(false)}
                                >

                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                <AddCustomer
                                    modal={true}
                                    success={setAddCustomerModal}
                                ></AddCustomer>
                            </Modal.Body>
                        </Modal>
                    </Modal.Body>
            }

        </Modal>
    )
}


const mapStateToProps = (state) => ({
    processingList: state.processing.processingList,
    company: state.company.activeCompany,
    remainOfActiveCompany: state.company.remainOfActiveCompany,


})

const mapDispatchToProps = { GET_CUSTOMER_LIST }

export default connect(mapStateToProps, mapDispatchToProps)(Room)