import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import Loading from '../../shared/loading';
import Select from 'react-select';
import { theme } from '../../../plugins/select.theme';
import { SET_ADD_ROOM_W_REFRESH } from '../../../store/module/rooms/action';
import { SET_ALERT_MODAL } from '../../../store/module/settings/action';
import ThereisNoProcessing from '../../alertModals/ThereisNoProcessing';

export const AddRoom = (props) => {
    useEffect(() => {
        if (props.processingList.length === 0)
            props.SET_ALERT_MODAL({
                status: true,
                component: <ThereisNoProcessing></ThereisNoProcessing>,
                title: 'Hizmetiniz Bulunmuyor.',
                persistent: true
            })
    }, [])
    const WorkersWithoutTyro = props.workers.filter(w => w.RoleId !== 4)
    const WorkersWithTyro = props.workers.filter(w => w.RoleId === 4)
    const [selectedOptionOwner, setSelectedOptionOwner] = useState(null);
    const [selectedOptionTyro, setSelectedOptionTyro] = useState(null);
    const [SelectedProcessing, setSelectedProcessing] = useState([]);
    const [noOffHours, setNoOffHours] = useState(false);
    let [alertBox, setAlertBox] = useState({ status: false, message: '', variant: 'danger' });
    let [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    let [room, setRoom] = useState({
        status: false,
        "OwnerId": null,
        "TyroId": null,
        "OpenedAt": "09:00:00",
        "ClosedAt": "18:00:00",
        "Description": "",
        "Interval": 30,
        "OffHoursStart": "12:00:00",
        "OffHoursEnd": "13:00:00",
        "Processes": []
    });
    const [times, setTimes] = useState([])
    const [allTimes, setAllTimes] = useState([])
    const IntervalList = [{
        val: 10,
        text: '10dk.'
    }, {
        val: 15,
        text: '15dk.'
    }, {
        val: 20,
        text: '20dk.'
    }, {
        val: 30,
        text: '30dk.'
    }, {
        val: 60,
        text: '60dk. (1 saat)'
    }, {
        val: 90,
        text: '90dk. (1saat 30dk.)'
    }, {
        val: 120,
        text: '120dk. (2 saat)'
    }, {
        val: 180,
        text: '180dk. (3 saat)'
    }]
    function convertM2H(n) {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return (rhours < 10 ? `0${rhours}` : rhours) + ":" + (rminutes < 10 ? `0${rminutes}:00` : rminutes + ':00');
    }
    function convertH2M(timeInHour) {
        var timeParts = timeInHour.split(":");
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }

    function SET_TIME_ALL_DAY(time = 30) {
        const startTime = convertH2M('05:00');
        const endTime = convertH2M('23:50');
        let timeList = []
        let timer = startTime
        while (timer <= endTime) {
            timeList.push({ time: timer, text: convertM2H(timer) })
            timer += time;
        }
        return timeList
    }
    function SET_TIME(time = 30) {
        const startTime = convertH2M(room.OpenedAt);
        const endTime = convertH2M(room.ClosedAt);
        let timeList = []
        let timer = startTime
        while (timer <= endTime) {
            timeList.push({ time: timer, text: convertM2H(timer) })
            timer += time;
        }
        return timeList
    }
    function SET_INTERVAL() {
        if (room.Interval === undefined) return;
        setTimeout(() => {
            const allTimeList = SET_TIME_ALL_DAY(room.Interval)
            const timeList = SET_TIME(room.Interval);
            setTimes(timeList);
            setAllTimes(allTimeList);
        }, 200);
    }
    async function SET_STATE_ROOM(key, val) {
        await setRoom({
            ...room,
            [key]: val
        })
    }
    async function SEND_ADD_ROOM() {
        setAlertBox({
            variant: 'danger',
            status: false,
            message: ''
        })
        // validations ===
        if (room.Description === '' || Number(room.Interval) < 15 || IntervalList.findIndex(il => il.val == Number(room.Interval)) == -1
            || ((times.findIndex(il => il.text === room.OffHoursStart) == -1
                || times.findIndex(il => il.text === room.OffHoursEnd) == -1) && !noOffHours)
            || allTimes.findIndex(il => il.text === room.OpenedAt) == -1
            || allTimes.findIndex(il => il.text === room.ClosedAt) == -1
            || selectedOptionOwner === null || SelectedProcessing.length === 0) {
            return setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Tüm alanları eksiksiz doldurmalısın. Lütfen bilgileri kontrol edin.'
            })
        }
        setIsLoadingUpdate(true)
        let start = room.OffHoursStart;
        let end = room.OffHoursEnd;
        if (noOffHours) start = null
        if (noOffHours) end = null
        const response = await window.$api.post(`Rooms/Create`, {
            ...room,
            OwnerId: selectedOptionOwner.Id,
            Processes: SelectedProcessing.map(s => s.Id),
            TyroId: selectedOptionTyro === null ? null : selectedOptionTyro.Id,
            OffHoursStart: start,
            OffHoursEnd: end
        })
        if (response.message === 'OK' && response.result === 'OK') {
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Odanız başarıyla eklendi. Sayfanız yenileniyor.. Lütfen bekleyin'
            })
            setTimeout(() => {
                // props.SET_ADD_ROOM_W_REFRESH({
                //     ...room,
                //     OwnerId: selectedOptionOwner.Id,
                //     Processes: SelectedProcessing.map(s => s.Id),
                //     TyroId: selectedOptionTyro === null ? null : selectedOptionTyro.Id,
                //     OffHoursStart: start,
                //     OffHoursEnd: end,
                //     Id: response.data
                // })
                window.location.reload();
            }, 2500);
        } else if (response.message === 'NOPACKAGERIGHT') {
            setAlertBox({
                status: true,
                variant: 'warning',
                message: `Firmanızın yeni çalışan ekleme hakkı dolmuş görünüyor. Paketini yükseltebilir veya Ek paket satın alabilirsin.`
            })
        }

        else {
            setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Ekleme işleminizi gerçekleştiremedik. Lütfen tekrar deneyiniz..'
            })
        }
        setIsLoadingUpdate(false)

    }
    const params = useParams();
    useEffect(() => {
        SET_INTERVAL();
    }, [])
    useEffect(() => {
        setTimeout(() => {
            SET_INTERVAL();
        }, 250);
    }, [room.Interval, room.OpenedAt, room.ClosedAt])
    if (!room.status)
        return (
            <div>
                {
                    props.activeUser.RoleId !== 4 && props.activeUser.RoleId !== 3 ?
                        <div>
                            {
                                props.remainOfActiveCompany.PackageRemain.RoomCount <= 0 ?
                                    <div className='alert alert-secondary'>Oda hakkınız bitmiş görünüyor. Yeni oda ekleyebilmeniz için Yardım Merkezinden bizim ile iletişime geçin.</div> : ''
                            }
                            <div className='form-group my-4'>
                                <label>Oda Adı</label>
                                <input maxLength={50} value={room.Description} type="text" onInput={(e) => SET_STATE_ROOM('Description', e.target.value)} placeholder='Oda Adı' className='form-control'></input>
                                <div className='d-flex justify-content-end text-light'> <small>{room.Description.length}/50</small></div>
                                <div> <small>* Oda adının girilmesi zorunludur.</small></div>
                                <div> <small>* Oda adınız en fazla 50 karakter olabilir.</small></div>
                            </div>
                            <div className='form-group my-4'>
                                <label>Rezzervasyon Aralığı (dk.).</label>
                                <select value={room.Interval} onChange={(e) => SET_STATE_ROOM('Interval', Number(e.target.value))} className='form-control'>
                                    {
                                        IntervalList.map((list, i) => (
                                            <option key={i} value={Number(list.val)}>{list.text}</option>
                                        ))
                                    }
                                </select>
                                <div> <small>* Rezzervasyon aralığını giriniz (dk.).</small></div>
                                <div> <small>* Rezzervasyon aralığı girdikten sonra çalışma saatlerini kontrol ediniz.</small></div>
                            </div>
                            <div className='form-group my-4'>
                                <label>Oda Çalışma Başlangıç Saati</label>
                                <select value={room.OpenedAt} onChange={(e) => SET_STATE_ROOM('OpenedAt', e.target.value)} className='form-control'>
                                    {
                                        allTimes.map((time, i) => (
                                            <option key={i} disabled={convertH2M(time.text) > convertH2M(room.ClosedAt)} value={time.text}>{time.text}</option>
                                        ))
                                    }
                                </select>
                                <div> <small>* Odanın rezzervasyona <span className='text-primary'>başlangıç</span> saatini seçebilirsiniz.</small></div>
                                {
                                    allTimes.findIndex(al => al.text === room.OpenedAt) === -1 ?
                                        <div className='alert alert-danger mt-2'><small >* Seçmiş olduğunuz "Rezzervasyon Aralığı" içersinde Oda çalışma başlangıç saati bulunmamaktadır. Lütfen oda çalışma başlangıç saatini değiştirin.</small></div> : ''
                                }
                            </div>
                            <div className='form-group my-4'>
                                <label>Oda Çalışma Bitiş Saati</label>
                                <select value={room.ClosedAt} onChange={(e) => SET_STATE_ROOM('ClosedAt', e.target.value)} className='form-control'>
                                    {
                                        allTimes.map((time, i) => (
                                            <option key={i} disabled={convertH2M(time.text) < convertH2M(room.OpenedAt)} value={time.text}>{time.text}</option>
                                        ))
                                    }
                                </select>
                                <div> <small>* Odanın rezzervasyona <span className='text-primary'>bitiş</span> saatini seçebilirsiniz.</small></div>
                                {
                                    allTimes.findIndex(al => al.text === room.ClosedAt) === -1 ?
                                        <div className='alert alert-danger mt-2'><small >* Seçmiş olduğunuz "Rezzervasyon Aralığı" içersinde Oda çalışma bitiş saati bulunmamaktadır. Lütfen oda çalışma bitiş saatini değiştirin.</small></div> : ''
                                }
                            </div>
                            <div>
                                <h5 className='text-info'>Oda'da öğle arasını kaldırabilirsiniz</h5>
                                <div className='alert alert-secondary'>
                                    {noOffHours ? 'Öğle arası eklemek istiyorsanız aşağıda olan işaretlenmiş kutucuğun işaretini kaldırın.' : ' Öğle arasını kaldırmak istiyorsanız aşağıdaki butonu işaretleyebilirsiniz.'}
                                </div>
                                <div className='mt-4'>
                                    <div className='d-flex align-items-center' onClick={() => setNoOffHours(!noOffHours)}> <input className='form-check-input mx-2' checked={noOffHours} type="checkbox" id='noOffHours'></input> Öğle arasını bu odadan kaldır</div>
                                </div>
                            </div>
                            {
                                noOffHours ? <div className='alert alert-secondary mt-4' >
                                    Öğle arasını kaldırdınız. Belirtilen tüm saatler arasında rezervasyon alabilirsiniz.
                                </div> : <div>
                                    <div className='form-group my-4'>
                                        <label>Öğle Arası Başlangıç Saati</label>
                                        <select value={room.OffHoursStart} onChange={(e) => SET_STATE_ROOM('OffHoursStart', e.target.value)} className='form-control'>
                                            {
                                                times.map((time, i) => (
                                                    <option key={i} disabled={convertH2M(time.text) > convertH2M(room.OffHoursEnd)} value={time.text}>{time.text}</option>
                                                ))
                                            }
                                        </select>
                                        <div> <small>* Öğle arası <span className='text-primary'>başlangıç</span> saatini seçebilirsiniz.</small></div>
                                        {
                                            times.findIndex(al => al.text === room.OpenedAt) === -1 ?
                                                <div className='alert alert-danger mt-2'><small >* Seçmiş olduğunuz "Rezzervasyon Aralığı" içersinde Öğle arası başlangıç saati bulunmamaktadır. Lütfen öğle arası başlangıç saatini değiştirin.</small></div> : ''
                                        }
                                    </div>
                                    <div className='form-group my-4'>
                                        <label>Öğle Arası Bitiş Saati</label>
                                        <select value={room.OffHoursEnd} onChange={(e) => SET_STATE_ROOM('OffHoursEnd', e.target.value)} className='form-control'>
                                            {
                                                times.map((time, i) => (
                                                    <option key={i} disabled={convertH2M(time.text) < convertH2M(room.OffHoursStart)} value={time.text}>{time.text}</option>
                                                ))
                                            }
                                        </select>
                                        {
                                            convertH2M(room.OffHoursEnd) < convertH2M(room.OffHoursStart) ?
                                                <div className='alert alert-danger mt-2'><small >* Öğle Arası başlangıç saatin, Öğle Arası bitiş saatinden ileri gözüküyor. Lütfen kontrol edin.</small></div> : ''
                                        }
                                        {
                                            times.findIndex(al => al.text === room.OpenedAt) === -1 ?
                                                <div className='alert alert-danger mt-2'><small >* Seçmiş olduğunuz "Rezzervasyon Aralığı" içersinde  Öğle arası bitiş saati bulunmamaktadır. Lütfen öğle arası bitiş saatini değiştirin.</small></div> : ''
                                        }
                                        <div> <small>* Öğle arası <span className='text-primary'>bitiş</span> saatini seçebilirsiniz.</small></div>
                                    </div>
                                    <div className='alert alert-secondary'>
                                        Belirlenen "Öğle Arası" saatleri arasında oda rezzervasyon alamayacaktır.
                                    </div>
                                </div>
                            }
                            <div className='form-group my-4'>
                                <label>Oda Sahibini Seçin *</label>
                                <Select
                                    defaultValue={selectedOptionOwner}
                                    placeholder="Oda Sahibini Seçin"
                                    name="Fullname"
                                    onChange={setSelectedOptionOwner}
                                    options={WorkersWithoutTyro}
                                    getOptionLabel={(option) => <div> <i className='flaticon-381-user-7'></i> {option.Fullname} </div>}
                                    getOptionValue={(val) => val.Id}
                                    isSearchable={false}
                                    theme={theme}
                                >
                                </Select>
                                <div> <small>* Oda sahibi seçmeniz zorunludur.</small></div>
                            </div>
                            <div className='form-group my-4'>
                                <label>Personel Seçin</label>

                                <Select
                                    defaultValue={selectedOptionTyro}
                                    placeholder="Odaya Personel Seçin"
                                    name="Fullname"
                                    isSearchable={false}
                                    onChange={setSelectedOptionTyro}
                                    options={WorkersWithTyro}
                                    getOptionLabel={(option) => <div> <i className='flaticon-381-user-7'></i> {option.Fullname} </div>}
                                    getOptionValue={(val) => val.Id}
                                    theme={theme}
                                >
                                </Select>
                                <div> <small>* Bu alanı boş geçebilirsiniz.</small></div>
                            </div>
                            <div className='form-group my-4'>
                                <label>Odanın Verdiği Hizmetler</label>

                                <Select
                                    defaultValue={SelectedProcessing}
                                    placeholder="Odanınızın Hizmetlerini Seçin"
                                    isMulti={true}
                                    name="Fullname"
                                    isSearchable={false}
                                    onChange={setSelectedProcessing}
                                    options={props.processingList}
                                    getOptionLabel={(option) => <div>    <i className="flaticon-041-graph"></i> {option.Name} </div>}
                                    getOptionValue={(val) => val.Id}
                                    theme={theme}
                                >
                                </Select>
                                <div> <small>* En az 1 adet hizmet seçmeniz zorunludur.</small></div>
                            </div>
                            {
                                alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                            }
                            <div className='d-flex justify-content-end'>
                                {
                                    props.remainOfActiveCompany.PackageRemain.RoomCount <= 0 ?
                                        <button className='btn btn-primary' disabled>Oda Hakkın Yok</button>
                                        :
                                        <button disabled={isLoadingUpdate} onClick={() => SEND_ADD_ROOM()} className='btn btn-primary d-flex'>
                                            {
                                                isLoadingUpdate ? <Loading size="sm"></Loading> : ''
                                            } Kaydet
                                        </button>
                                }


                            </div>
                        </div> : <div className='alert alert-secondary'>
                            Hesabınızın oda açma yetkisi bulunmamaktadır. Oda açabilmeniz için yöneticiniz ile görüşmelisiniz.
                        </div>
                }
            </div>

        )
    else return <div></div>
}

const mapStateToProps = (state) => ({
    workers: state.workers.workers,
    company: state.company.activeCompany,
    processingList: state.processing.processingList,
    remainOfActiveCompany: state.company.remainOfActiveCompany,
    activeUser: state.user.activeUser.User



})

const mapDispatchToProps = { SET_ADD_ROOM_W_REFRESH, SET_ALERT_MODAL }

export default connect(mapStateToProps, mapDispatchToProps)(AddRoom)