export const timeZone = (data) => {
  const time = new Date(data);
  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
  };
  const date = new Intl.DateTimeFormat('tr-TR', options).format(time)
  return date
}
export const dateZone = (data, iso = false) => {
  let [newDate, newTime] = data.split('T')
  const year = newDate.substring(0, 4);
  const month = newDate.substring(4, 6);
  const day = newDate.substring(6, 8);
  const hours = newTime.substring(0, 2);
  const min = newTime.substring(2, 4);
  const sec = newTime.substring(4, 6);
  const date = year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec
  const time = new Date(new Date(date).setHours(Number(newTime.substring(0, 2)) + 3));
  if (iso) return time.toISOString()
  else return IntlDateTime(time)
}

export const IntlDateTime = (time) => {
  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
  };
  const date = new Intl.DateTimeFormat('tr-TR', options).format(time)
  return date
}

export const IntlTime = (time) => {
  const options = {
    hour: 'numeric', minute: 'numeric', second: 'numeric',
  };
  const date = new Intl.DateTimeFormat('tr-TR', options).format(time)
  return date
}
export const IntlDate = (time) => {
  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
  };
  const date = new Intl.DateTimeFormat('tr-TR', options).format(time)
  return date
}
export const JustDate = (data, locale = 'tr-TR') => {
  const time = new Date(data);
  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
  };
  const date = new Intl.DateTimeFormat(locale, options).format(time)
  return date
}