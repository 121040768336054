import React, { Fragment, useEffect, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import ChatBox from "../ChatBox";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { SET_ALERT_MODAL } from "../../store/module/settings/action";
import ThereisNoRoom from "../../components/alertModals/ThereisNoRoom";
const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3, SET_ALERT_MODAL, alertModal, roomIsLoading, roomsList, remainOfActiveCompany, activeUser }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  useEffect(() => {
    if (!roomIsLoading && roomsList.length === 0 && remainOfActiveCompany.PackageRemain.RoomCount > 0 && activeUser.RoleId !== 4 && activeUser.RoleId !== 3)
      SET_ALERT_MODAL({
        status: true,
        component: <ThereisNoRoom></ThereisNoRoom>,
        title: 'Odanız Bulunmuyor.',
        persistent: false
      })
  }, [roomIsLoading])
  return (
    <Fragment>
      <NavHader />
      <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
      <SideBar />
      <Modal centered className="fade" onHide={() => SET_ALERT_MODAL({ status: false })} show={alertModal.status}>
        <Modal.Header>
          <Modal.Title> <i className='flaticon-381-warning'></i> {alertModal.title}</Modal.Title>
          <button
            className="btn btn-close"
            onClick={() => SET_ALERT_MODAL({ status: false })}
          >

          </button>
        </Modal.Header>
        <Modal.Body>
          {alertModal.component}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  alertModal: state.settings.alertModal,
  roomIsLoading: state.rooms.roomIsLoading,
  roomsList: state.rooms.roomsList,
  remainOfActiveCompany: state.company.remainOfActiveCompany,
  activeUser: state.user.activeUser.User

})

const mapDispatchToProps = { SET_ALERT_MODAL }

export default connect(mapStateToProps, mapDispatchToProps)(JobieNav);
