import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap';
import { SET_REMOVE_CUSTOMER, SET_ALERT_BOX } from '../../../../store/module/customers/action';
import Loading from '../../../shared/loading';
import EditCustomer from '../EditCustomer';
import { JustDate } from '../../../../plugins/SET_DATE_FORMATTER'
import CustomerTags from '../CustomerTags';
import { Link } from 'react-router-dom';

export const ListOfProcessingItem = (props) => {
    const [EditModal, setEditModal] = useState(false);
    const [CustomerTagModal, setCustomerTagModal] = useState(false);
    const [Date, setDate] = useState(null);
    const [RemoveModal, setRemoveModal] = useState(false);
    let [index, setIndex] = useState(-1);
    useEffect(() => {
        setIndex(props.list.findIndex(l => l.Id === props.item.Id)) // search yapıldığı için indexi bu şekilde bulunmalı
        setDate(props.item.Birthday.includes('T') ? props.item.Birthday.split('T')[0] : props.item.Birthday)
    }, [])
    function SET_REMOVE_CUSTOMER() {
        props.SET_REMOVE_CUSTOMER(index, props.item.Id);
    }
    function SET_REMOVE_MODAL() {
        props.SET_ALERT_BOX({
            variant: 'danger',
            status: false,
            message: ''
        })
        setRemoveModal(true)

    }
    return (
        <div className='row my-2 py-2 border-bottom'>
            <div className='col-8 my-2 col-md-8 col-lg-5 col-xl-5 d-flex align-items-start flex-column'>
                <div>
                    <i className='flaticon-381-success text-success'></i>
                    <Link to={`/${props.company.Id}/customerDetail/${props.item.Id}`} className="text-link"> <span className='text-link'>  {props.item.Fullname}</span></Link>
                </div>
                <div><small className='text-light'>{props.item.Note === '' ? 'Müşteri notu bulunmamaktadır.' : props.item.Note}</small></div>
            </div>
            <div className='col-4 my-2 col-md-2 col-lg-2 d-flex align-items-start flex-column justify-content-start'>
                <small className="text-light">Tel No. </small>
                <div><small className='text-primary'>{props.item.Phone}</small></div>
                <small className="text-light">Email </small>
                <div><small className='text-primary'>{props.item.Email}</small></div>
            </div>
            <div className='col-6 my-2 col-md-2 col-lg-2 d-flex align-items-start flex-column justify-content-start'>
                <small className="text-light">Dğm. Tarihi </small>
                <div className='text-secondary mt-1'>
                    <small>{Date === '0001-01-01' ? 'Bilinmiyor' : JustDate(Date)}</small>
                </div>
            </div>

            <div className='col-6 my-2 col-md-12 col-lg-3 d-flex align-items-center justify-content-end'>
                {
                    props.activeUser.RoleId === 4 ? '' :
                        <div>
                            <div>
                                <button className='btn btn-warning btn-xs mx-1' onClick={() => setEditModal(true)}>Düzenle</button>
                                <button className='btn btn-danger btn-xs mx-1' onClick={() => SET_REMOVE_MODAL()}><i className='flaticon-381-trash-1'></i></button>
                            </div>
                            <div className='mt-2'>
                            <Link to={`/${props.company.Id}/customerDetail/${props.item.Id}`} className="text-link">   <button className='btn btn-secondary btn-xs mx-1'>Detaya Git</button>
                            </Link>
                            </div>
                        </div>
                }

            </div>
            <div className='col-12'>
                <div> <button className='btn btn-danger btn-xs m-1' onClick={() => setCustomerTagModal(true)}>Müşteri Etiketleri</button></div>

            </div>
            <Modal centered className="fade" onHide={() => setCustomerTagModal(false)} show={CustomerTagModal}>
                <Modal.Header>
                    <Modal.Title>{props.item.Fullname} çalışanının etiketleri</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setCustomerTagModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    <CustomerTags index={index} customer={props.item}></CustomerTags>
                </Modal.Body>
            </Modal>
            <Modal centered className="fade" onHide={() => setEditModal(false)} show={EditModal}>
                <Modal.Header>
                    <Modal.Title>{props.item.Fullname} çalışanını güncelle</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setEditModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    <EditCustomer index={index} editModal={EditModal} EditCustomer={props.item}></EditCustomer>
                </Modal.Body>
            </Modal>
            <Modal centered className="fade" onHide={() => setRemoveModal(false)} show={RemoveModal}>
                <Modal.Header>
                    <Modal.Title>{props.item.Fullname} müşterini kaldır</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setRemoveModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    {
                        props.alertBox.status === 'remove' ?
                            <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> :
                            <p>
                                {props.item.Fullname} müşterisini firmadan kaldırıyorsunuz. Bu işleme devam ederseniz müşteriniz tamamen kaldırılacaktır.
                                <br></br><br></br> <strong className='text-secondary'> Bunu yapmak istediğiniz Emin misiniz?</strong>
                            </p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={() => setRemoveModal(false)}
                        className="btn btn-light btn-xs"
                    >
                        Kapat
                    </button>
                    <button onClick={() => SET_REMOVE_CUSTOMER()} className='btn btn-secondary btn-xs d-flex'>
                        {
                            props.isLoading ?
                                <Loading size="sm"></Loading>
                                : ''
                        } Evet, Çalışanı Kaldır

                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    alertBox: state.customers.alertBox,
    isLoading: state.customers.isLoadingAction,
    activeUser: state.user.activeUser.User,
    company: state.company.activeCompany

})

const mapDispatchToProps = { SET_REMOVE_CUSTOMER, SET_ALERT_BOX }

export default connect(mapStateToProps, mapDispatchToProps)(ListOfProcessingItem)