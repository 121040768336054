import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../../shared/loading'
import { SET_INSERT_CUSTOMER_PACKAGES, SET_ALERT_BOX } from '../../../../store/module/customerPackages/action'
import { GET_PROCESSING_LIST } from '../../../../store/module/processing/action'
import ProcessingSelectBox from '../../ProcessingSelectBox'
export const InsertProcessing = (props) => {
    useEffect(() => {
        props.GET_PROCESSING_LIST();
    }, [])
    const [submitted, setSubmitted] = useState(false);
    const [form, setForm] = useState({
        Detail: '',
        ActualPrice: null,
        Price: null,
        ValidFor: 15,
        ProcessesData: [],
        Count: 1,
        Currency: 'TRY',
        ProcessesDataId: []
    })
    function SEND_NEW_CUSTOMER_PACKAGES() {
        setSubmitted(true)
        if (form.Detail.length > 50 || form.Price < 0 || form.Price === null || form.ActualPrice < 0 || form.Count < 1 || form.ActualPrice === null || form.ValidFor < 0)
            return props.SET_ALERT_BOX({
                status: 'insert',
                variant: 'danger',
                message: 'Tüm alanları eksiksiz doldurmanız gerekmektedir.'

            })
        props.SET_INSERT_CUSTOMER_PACKAGES({
            "Detail": form.Detail,
            "Count": form.Count,
            "Processes": form.ProcessesData.map(p => p.Id).join(','),
            "ActualPrice": form.ActualPrice,
            "Price": form.Price,
            "ValidFor": form.ValidFor,
            "Currency": form.Currency
        }, form.ProcessesData)
    }
    useEffect(() => {
        if (props.alertBox.status === 'insert' && props.alertBox.variant === 'success') {
            setSubmitted(false);
            setForm({
                Detail: '',
                Price: null,
                ActualPrice: null,
                Count: 1,
                ProcessesData: [],
                Currency: 'TRY',
                ValidFor: 15
            })
        }
    }, [props.alertBox])
    return (
        <div className='card'>
            <div className='card-title p-4'>
                <h5>Yeni paket ekleyin</h5>
            </div>
            <div className='card-body'>
                <div className='alert alert-secondary'>
                    * Kayıt edeceğiniz paketler rezzervasyon oluşturulurken size işlemin süresini ve toplam ücretini otomatik dolduracaktır.
                </div>
                <div className='form-group my-4'>
                    <input value={form.Detail} type="text" onInput={(e) => setForm({ ...form, Detail: e.target.value })} placeholder='Paket Adı *' className='form-control'></input>
                    <div className='d-flex justify-content-end text-light'> <small>{form.Detail.length}/50</small></div>
                    <div> <small>* Örn: Saç Kesimi, Boyama Paketi vs.</small></div>
                    <div> <small>* Paket Adı en fazla 50 karakter olmalıdır.</small></div>
                    {
                        form.Detail.length < 1 && submitted ? (<div className='alert alert-danger mt-2'> <small>* Paket adı girmeniz zorunludur.</small></div>) : ''
                    }
                </div>
                <div className='form-group my-4'>
                    <label>Paket Süresi *</label>
                    <select value={form.ValidFor} onChange={(e) => setForm({ ...form, ValidFor: Number(e.target.value) })} className='form-control'>
                        <option value={1}> 1 gün</option>
                        <option value={3}> 3 gün</option>
                        <option value={5}> 5 gün</option>
                        <option value={10}> 10 gün</option>
                        <option value={15}> 15 gün</option>
                        <option value={20}> 20 gün</option>
                        <option value={30}> 1 ay</option>
                        <option value={30 * 2}> 2 ay</option>
                        <option value={30 * 6}> 6 ay</option>
                        <option value={30 * 12}> 1 yıl</option>
                    </select>
                    <div> <small>* Müşteriniz paketi aldığı günden itibaren geçerli olacak gün sayısı.</small></div>
                </div>
                <ProcessingSelectBox
                    placeholder="Paketinizin Hizmetlerini Seçin"
                    label="Müşteriye uygulanacak hizmetleri seçin"
                    ProcessesData={form.ProcessesData}
                    onChange={(e) => setForm({ ...form, ProcessesData: e })
                    }
                ></ProcessingSelectBox>
                <div className='form-group my-4'>
                    <label>Para Birimi *</label>
                    <select className='form-control' value={form.Currency} onChange={(e) => setForm({ ...form, Currency: (e.target.value) })}>
                        <option value={'TRY'}>Türk Lirası (₺)</option>
                        <option value={'USD'}>Dolar ($)</option>
                        <option value={'EUR'}>Euro (€)</option>
                    </select>
                </div>
                <div className='form-group my-4'>
                    <label>Eski Paket Ücreti *</label>
                    <div className="input-group mb-3 "><span className="input-group-text">₺</span>
                        <input value={form.ActualPrice} type="number" onInput={(e) => setForm({ ...form, ActualPrice: Number(e.target.value) })} className="form-control" placeholder='Paket Ücreti (₺) *' />
                    </div>
                    <div> <small>* Eski Paket ücretini tanımlayabilirsiniz.</small></div>
                    {
                        form.ActualPrice < 0 ? (<div className='alert alert-danger mt-2'> <small>* Eski Paket ücreti 0 TL'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        form.ActualPrice === null ? (<div className='alert alert-danger mt-2'> <small>* Eski Paket ücreti girilmesi zorunludur.</small></div>) : ''
                    }

                </div>
                <div className='form-group my-4'>
                    <label>Paket Ücreti *</label>
                    <div className="input-group mb-3 "><span className="input-group-text">₺</span>
                        <input value={form.Price} type="number" onInput={(e) => setForm({ ...form, Price: Number(e.target.value) })} className="form-control" placeholder='Paket Ücreti (₺) *' />
                    </div>
                    <div> <small>* Paketinize ücret tanımlayabilirsiniz.</small></div>
                    {
                        form.Price < 0 ? (<div className='alert alert-danger mt-2'> <small>* Paket ücreti 0 TL'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        form.Price === null ? (<div className='alert alert-danger mt-2'> <small>* Paket ücreti girilmesi zorunludur.</small></div>) : ''
                    }

                </div>
                <div className='form-group my-4'>
                    <label>Paket Kaç Adet Rezzervasyon İçeriyor? *</label>
                    <div className="input-group mb-3 ">
                        <input value={form.Count} type="number" onInput={(e) => setForm({ ...form, Count: Number(e.target.value) })} className="form-control" placeholder='Adet Sayısı' />
                    </div>
                    <div> <small>* Kullanıcılar bu paketi satın aldığında kaç kez rezzervasyon oluşturabilecek.</small></div>
                    {
                        form.Count < 1 ? (<div className='alert alert-danger mt-2'> <small>* Adet Sayısı 1'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        form.Count === null ? (<div className='alert alert-danger mt-2'> <small>* Adet Sayısı girilmesi zorunludur.</small></div>) : ''
                    }

                </div>
                {
                    props.alertBox.status === 'insert' ?
                        <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                }
                <div className='mt-4 d-flex justify-content-end'>
                    <button onClick={() => SEND_NEW_CUSTOMER_PACKAGES()} disabled={props.isLoading} className='btn btn-primary'>
                        {
                            props.isLoading ?
                                <Loading size="sm"></Loading>
                                : ''
                        } Kaydet
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.customerPackages.isLoadingInsert,
    alertBox: state.customerPackages.alertBox,
    processList: state.processing.processingList,
    company: state.company.activeCompany,
    ProcessesareLoading: state.processing.isLoading,
})


const mapDispatchToProps = { SET_INSERT_CUSTOMER_PACKAGES, SET_ALERT_BOX, GET_PROCESSING_LIST }

export default connect(mapStateToProps, mapDispatchToProps)(InsertProcessing)