import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { JustDate } from '../../../plugins/SET_DATE_FORMATTER'
import Loading from '../../shared/loading'
import NoFound from '../../shared/NoFound'
import { PackagesOfCustomerCompanyList } from './PackagesOfCustomerCompanyList'
export const PackagesOfCustomerDetail = (props) => {
  const [customerOfPackageList, setCustomerOfPackageList] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [mounted, setMounted] = useState(false);
  const [alertBox, setAlertBox] = useState({ message: '', variant: '', status: false })
  const [updateIsLoading, setUpdateIsLoading] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(-1);
  const [packagesOfCustomerCompanyModal, setPackagesOfCustomerCompanyModal] = useState(false)
  useEffect(() => {
    GET_CUSTOMER_PACKAGES()
  }, [mounted])
  useEffect(() => {
    setMounted(true);
  }, [])

  async function GET_CUSTOMER_PACKAGES() {
    setIsLoading(true);
    const response = await window.$api.get(`PackagesOfCustomers/GetAll?customerId=${props.customer.Id}&processFilter=`)
    if (response.message === 'OK') {
      setCustomerOfPackageList(response.data);
    } else
      setCustomerOfPackageList([]);
    setIsLoading(false);
  }
  async function SEND_TO_UPDATE_PACKAGE(item, key, key2) {
    setUpdateIsLoading(true);
    const response = await window.$api.put(`PackagesOfCustomers/${item.Id}`, {
      [key]: 1,
      [key2]: 0
    });
    if (response.message === 'OK' && response.result === 'OK') {
      setAlertBox({ message: 'Başarıyla güncellendi.', variant: 'success', status: true })
    } else {
      setAlertBox({ message: 'İşleminiz gerçekleşmedi.', variant: 'danger', status: true })
    }
    setUpdateIsLoading(false);
    setTimeout(() => {
      setAlertBox({ status: false })
      setUpdateIndex(-1)
      GET_CUSTOMER_PACKAGES();
    }, 2000);
  }
  return (
    <div className='card'>
      {
        isLoading ? <div className='text-center'> <Loading variant='primary'></Loading></div> :
          customerOfPackageList.length === 0 ? <NoFound></NoFound> :
            <div>
              <h4 className='p-4 card-title'>Müşterinin Sahip Olduğu Paketler
              </h4>
              <div className='row card-body'>
                {
                  customerOfPackageList.map((item, index) => (
                    <div className='col-12 col-md-6 col-lg-3'>
                      <div className={`card`} key={index}>
                        <div className='card-body text-center'>
                          {
                            updateIndex === index ? '' : <div className='text-center my-2'><button onClick={() => setUpdateIndex(index)} className='btn btn-secondary btn-xs'>Düzenle</button></div>
                          }
                          <h6>{item.Detail}</h6>
                          <h2 className='text-primary'>{item.RemainCount}</h2>
                          <p>adet kaldı.</p>
                          <div>Son Kullanım Tarihi</div>
                          <p className='text-secondary'>{JustDate(item.FinishedAt)}</p>
                          <p>{item.ProcessesData.map(p => p.Name).join(' , ')}</p>
                          <p className={item.IsCompleted === 0 && item.IsCanceled === 0 ? 'badge badge-warning' : item.IsCompleted === 1 ? 'badge badge-success' : 'badge badge-danger'}>
                            {item.IsCompleted === 0 && item.IsCanceled === 0 ? 'Ödeme Bekleniyor.' : item.IsCompleted === 1 ? 'Ödeme Tamamlandı' : 'İptal Edildi'}
                          </p>
                          {
                            updateIndex === index ? alertBox.status ? <div className={`alert alert-${alertBox.variant}`}>{alertBox.message}</div> : <div className='mt-4 alert alert-primary'>
                              <h6 className='mb-4 text-primary'>Düzenle <i className='fas fa-arrow-down'></i></h6>
                              <button disabled={updateIsLoading} onClick={() => SEND_TO_UPDATE_PACKAGE(item, 'IsCompleted', 'IsCanceled')} className='btn btn-success mx-1 btn-xxs'>Aktif Et</button>
                              <button disabled={updateIsLoading} onClick={() => SEND_TO_UPDATE_PACKAGE(item, 'IsCanceled', 'IsCompleted')} className='btn btn-danger mx-1 btn-xxs'>İptal Et</button>
                            </div> : ''
                          }
                        </div>
                      </div>
                    </div>
                  ))
                }

              </div>

            </div>
      }
      <hr></hr>
      <div className='card-body text-center'>
        <h4>Müşteriye yeni paket eklemek ister misiniz?</h4>
        <div className='mt-4'><button onClick={() => setPackagesOfCustomerCompanyModal(true)} className='btn btn-secondary'>+ Yeni Paket Ekle</button></div>
      </div>
      <Modal centered className="fade" size='xl' onHide={() => setPackagesOfCustomerCompanyModal(false)} show={packagesOfCustomerCompanyModal}>
        <Modal.Header>
          <Modal.Title>{props.customer.Fullname} paket ekle</Modal.Title>
          <button
            className="btn btn-close"
            onClick={() => setPackagesOfCustomerCompanyModal(false)}
          >

          </button>
        </Modal.Header>
        <Modal.Body>
          <PackagesOfCustomerCompanyList refresh={GET_CUSTOMER_PACKAGES} user={props.customer} count={props.count} GET_CUSTOMER_PACKAGES={props.GET_CUSTOMER_PACKAGES} customerPackages={props.customerPackages} isLoading={props.isLoading} index={-1} editModal={packagesOfCustomerCompanyModal} ></PackagesOfCustomerCompanyList>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PackagesOfCustomerDetail)