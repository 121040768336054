import React from 'react'
import { connect } from 'react-redux'
import CustomersChild from '../components/main-components/Customers/CustomersChild'

export const Customers = (props) => {
  return (
    <div>
      <h3>Müşterilerim</h3>
      <CustomersChild></CustomersChild>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Customers)