import { SET_UPDATE_REMAIN_OF_COMPANY, SET_REFRESH_APP } from '../company/action'
export function GET_ROOMS_LIST() {
    return async (dispatch) => {
        dispatch(SET_PROCESSING_LOADING({ key: 'roomIsLoading', value: true }))
        const response = await window.$api.get(`Rooms/GetAll`);
        if (response.message === 'OK' && response.result === 'OK') {
            if (response.data === null) dispatch(SET_ROOMS_LIST([]))
            else dispatch(SET_ROOMS_LIST(response.data));
        } else
            dispatch(SET_ROOMS_LIST([]));

    }
}
export function SET_ADD_ROOM_W_REFRESH(data) {
    return (dispatch, getState) => {
        const remainOfActiveCompany = getState().company.remainOfActiveCompany
        dispatch(SET_ADD_ROOM(data))
        dispatch(SET_REFRESH_APP())
        setTimeout(() => {
            SET_UPDATE_REMAIN_OF_COMPANY({
                ...remainOfActiveCompany,
                PackageRemain: {
                    ...remainOfActiveCompany.PackageRemain,
                    roomCount: remainOfActiveCompany.roomCount - 1
                }
            })
        }, 1000);
    }
}
export function GET_ROOM_STATS(query = '', roomId) {
    return async (dispatch) => {
        dispatch(SET_PROCESSING_LOADING({ key: 'roomStatsIsLoading', value: true }))
        const response = await window.$api.get(`Rezervations/GetStatistics${query}`) //?roomId=5
        if (response.message === 'OK' && response.result === 'OK') {
            if (response.data === null) dispatch(SET_ROOM_STATS())
            else dispatch(SET_ROOM_STATS(roomId, response.data))
        } else
            dispatch(SET_ROOM_STATS(roomId))

    }
}
function SET_ROOM_STATS(roomId, data = null) {
    return {
        type: 'SET_ROOM_STATS',
        payload: { data, roomId }
    }

}
export function SET_ADD_ROOM(data) {
    return {
        type: 'SET_ADD_ROOM',
        payload: data
    }
}

function SET_ROOMS_LIST(data) {
    return {
        type: 'SET_ROOMS_LIST',
        payload: data
    }
}
function SET_PROCESSING_LOADING(data) {
    return {
        type: 'SET_PROCESSING_LOADING',
        payload: data
    }
}

export function SET_UPDATE_ROOM_W_INDEX(data, index) {
    return {
        type: 'SET_UPDATE_ROOM_W_INDEX',
        payload: { data, index }
    }
}