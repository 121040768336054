import { Suspense, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CHECK_AUTOLOGIN_ON_LOCALSTORAGE } from '../../store/module/users/action'
import ChooseCompany from '../../pages/ChooseCompany'
function Default(props) {
    useEffect(() => {
        console.log(props.history, "use app");
        props.CHECK_AUTOLOGIN_ON_LOCALSTORAGE(props.history);
    }, [props.history]); // every route change will be work.
    if (props.activeCompany.status) {
        return (
            <div>Buradayım</div>
        )
    } else {
        return (
            <ChooseCompany></ChooseCompany>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        activeCompany: state.company.activeCompany
    }
}

export default withRouter(connect(mapStateToProps, { CHECK_AUTOLOGIN_ON_LOCALSTORAGE })(Default))