import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../../shared/loading'
import { SET_INSERT_CUSTOMERS_TAG, SET_ALERT_BOX } from '../../../../store/module/customersTag/action'
export const InsertProcessing = (props) => {
    const [form, setForm] = useState({
        Name: '',

    })
    function SEND_NEW_PROCESSING() {
        if (form.Name === '' || form.Name.length > 50)
            return props.SET_ALERT_BOX({
                status: 'insert',
                variant: 'danger',
                message: 'Tüm alanları eksiksiz doldurmanız gerekmektedir.'

            })
        props.SET_INSERT_CUSTOMERS_TAG({
            ...form,
            companyId: props.company.Id
        })
    }
    useEffect(() => {
        if (props.alertBox.status === 'insert' && props.alertBox.variant === 'success') {
            setForm({
                Name: ''
            })
            setTimeout(() => {
                if (props.modal) props.success(false);
            }, 1500);
        }
    }, [props.alertBox])
    return (
        <div className='card'>
            <div className='card-title p-4'>
                <h5>Yeni Etiket ekleyin</h5>
            </div>
            <div className='card-body'>
                <div className='alert alert-secondary'>
                    * Kayıt edeceğiniz Etiketleri müşterilerinize atayabilirsiniz. Etiketler, müşterinin bir sonraki rezervasyonuna geldiğinde bilgi almanızı sağlar.
                </div>
                <div className='form-group my-4'>
                    <input value={form.Name} type="text" onInput={(e) => setForm({ ...form, Name: e.target.value })} placeholder='Etiket Adı *' className='form-control'></input>
                    <div className='d-flex justify-content-end text-light'> <small>{form.Name.length}/50</small></div>
                    <div> <small>* Örn: Çay Sever, Kahve Sever, Siyaset konuşmayı sevmez vs.</small></div>
                    <div> <small>* Etiket Adı en fazla 50 karakter olmalıdır.</small></div>
                    {
                        form.Name.length < 1 && props.alertBox.status !== 'insert' ? (<div className='alert alert-danger mt-2'> <small>* Etiket adı girmeniz zorunludur.</small></div>) : ''
                    }
                </div>
                {
                    props.alertBox.status === 'insert' ?
                        <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                }
                <div className='mt-4 d-flex justify-content-end'>
                    <button onClick={() => SEND_NEW_PROCESSING()} disabled={props.isLoading} className='btn btn-primary'>
                        {
                            props.isLoading ?
                                <Loading size="sm"></Loading>
                                : ''
                        } Kaydet
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    company: state.company.activeCompany,
    isLoading: state.customersTag.isLoadingInsert,
    alertBox: state.customersTag.alertBox
})


const mapDispatchToProps = { SET_INSERT_CUSTOMERS_TAG, SET_ALERT_BOX }

export default connect(mapStateToProps, mapDispatchToProps)(InsertProcessing)