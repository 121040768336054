import React from 'react'
import { connect } from 'react-redux'

export const CompanyApplyBrand = (props) => {
  return (
    <div className='alert alert-secondary'>
      {
        props.remainOfActiveCompany.PackageRemain.BranchCount <= 0 ?
          <div>
            <p>Merhaba {props.user.Fullname},</p>
            <br></br>
            <p>{props.company.Name} isimli firmanıza paketiniz dahilinde Yeni Şube açma hakkı bulunmamaktadır. Yardım Merkezinden bizim ile Yeni Şube açmak için
              iletişime geçebilirsin.</p>
          </div> : 'Şu an için Şube Başvurusu yapamıyorsun.'

      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  remainOfActiveCompany: state.company.remainOfActiveCompany,
  company: state.company.activeCompany,
  user: state.user.activeUser.User

})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyApplyBrand)