import React, { lazy, useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./components/index.css";
import "./components/chart.css";
import "./components/step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/main-components/Dashboard/Home";

/// Pages
import Error404 from "./pages/Error404";
import Processing from "./pages/Processing";
import RoomDetail from "./pages/RoomDetailPage";
import Setting from "./layouts/Setting";
import AddRoomPage from "./pages/AddRoomPage";
import { ThemeContext } from "./assets/context/ThemeContext.js";
import AddWorkerPage from "./pages/AddWorkerPage";
import Workers from "./pages/Workers";
import Customers from "./pages/Customers";
import CompanySettings from "./pages/CompanySettings";
import CustomersTag from "./pages/CustomersTag";
import CustomerPackages from "./pages/CustomerPackages";
import CustomerDetail from './pages/CustomerDetail'
import ProfilePage from "./pages/ProfilePage";
import Index from "./pages/Index";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Index, name: "Destek" },
    { url: ":companyId", component: Home, name: "Ana Sayfa" },
    { url: ":companyId/process", component: Processing, name: "Hizmetler" },
    { url: ":companyId/customersTag", component: CustomersTag, name: "Müşteri Etiketlerim" },
    { url: ":companyId/customerPackages", component: CustomerPackages, name: "Müşteri Paketleri" },
    { url: ":companyId/workers", component: Workers, name: "Çalışanlar" },
    { url: ":companyId/customers", component: Customers, name: "Müşteriler" },
    { url: ":companyId/room/:roomId", component: RoomDetail, name: "Odalar" },
    { url: ":companyId/customerDetail/:customerId", component: CustomerDetail, name: "Odalar" },
    { url: ":companyId/add-room", component: AddRoomPage, name: "Yeni Oda Ekle" },
    { url: ":companyId/add-worker", component: AddWorkerPage, name: "Yeni Çalışan Ekle" },
    { url: ":companyId/company-settings", component: CompanySettings, name: "Firma Ayarları" },
    { url: ":companyId/profile-user", component: ProfilePage, name: "Kullanıcı Ayarları" },

    /// pages

    { url: "page-error-404", component: Error404 },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  name={data.name}
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
