import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap';
import EditProcessing from './EditProcessing';
import Currency from '../../../../plugins/SET_CURRENCY'
import { SET_REMOVE_PROCESSING } from '../../../../store/module/processing/action';
import Loading from '../../../shared/loading';
export const ListOfProcessingItem = (props) => {
    const [EditModal, setEditModal] = useState(false);
    const [RemoveModal, setRemoveModal] = useState(false);
    let [index, setIndex] = useState(-1);
    useEffect(() => {
        setIndex(props.list.findIndex(l => l.Id === props.item.Id)) // search yapıldığı için indexi bu şekilde bulunmalı
    }, [])
    function SET_REMOVE_PROCESSING() {
        props.SET_REMOVE_PROCESSING(index, props.item.Id);
    }
    return (
        <div className='row my-2 py-2 border-bottom'>
            <div className='col-12 my-2 d-flex align-items-start flex-column'>
                <div> <i className='flaticon-381-success text-success'></i> {props.item.Name}</div>
                <div><small className='text-light'>{props.item.Detail}</small></div></div>
            <div className='col-3 my-2 col-md-2 col-lg-2 d-flex align-items-center'><strong className='text-secondary'>{props.item.Duration}dk</strong></div>
            <div className='col-9 my-2 col-md-6 col-lg-6 d-flex align-items-center'>
                <span className='text-primary mx-2'>{Currency(props.item.PriceTRY, 'TRY')}</span> 
                <span className='text-primary mx-2'>{Currency(props.item.PriceUSD, 'USD')}</span> 
                <span className='text-primary mx-2'>{Currency(props.item.PriceEUR, 'EUR')}</span> 
            <span className='badge badge-info mx-2'>{props.item.Point}p.</span></div>
            <div className='col-6 my-2 col-md-4 col-lg-4 d-flex align-items-center'>
                {
                    props.activeUser.RoleId === 4 ? '' :
                        <div>
                            <button className='btn btn-warning btn-xs mx-1' onClick={() => setEditModal(true)}>Düzenle</button>
                            <button className='btn btn-danger btn-xs mx-1' onClick={() => setRemoveModal(true)}><i className='flaticon-381-trash-1'></i></button>
                        </div>
                }
            </div>
            <Modal centered className="fade" onHide={() => setEditModal(false)} show={EditModal}>
                <Modal.Header>
                    <Modal.Title>{props.item.Name} hizmetini güncelle</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setEditModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    <EditProcessing index={index} item={props.item}></EditProcessing>
                </Modal.Body>
            </Modal>
            <Modal centered className="fade" onHide={() => setRemoveModal(false)} show={RemoveModal}>
                <Modal.Header>
                    <Modal.Title>{props.item.Name} hizmetini kaldır</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setRemoveModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    {
                        props.alertBox.status === 'remove' ?
                            <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> :
                            <p>
                                {props.item.Name} hizmetinizi kaldırmak üzeresiniz. Bu işleme devam ederseniz hizmetiniz tamamen kaldırılacaktır.
                                <br></br><br></br> <strong className='text-secondary'> Bunu yapmak istediğiniz Emin misiniz?</strong>
                            </p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={() => setRemoveModal(false)}
                        className="btn btn-light btn-xs"
                    >
                        Kapat
                    </button>
                    <button onClick={() => SET_REMOVE_PROCESSING()} className='btn btn-secondary btn-xs d-flex'>
                        {
                            props.isLoading ?
                                <Loading size="sm"></Loading>
                                : ''
                        } Evet, Hizmeti Kaldır

                    </button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

const mapStateToProps = (state) => ({
    alertBox: state.processing.alertBox,
    isLoading: state.processing.isLoadingRemove,
    activeUser: state.user.activeUser.User

})

const mapDispatchToProps = { SET_REMOVE_PROCESSING }

export default connect(mapStateToProps, mapDispatchToProps)(ListOfProcessingItem)