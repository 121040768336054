export function GET_CUSTOMER_PACKAGES(processFilter) {
    return async (dispatch) => {
        window.$api.get(`CustomerPackages/GetAll?processFilter=${processFilter}`).then(response => {
            if (response.message === 'OK' && response.result === 'OK') {
                if (response.data === null)
                    dispatch(SET_CUSTOMER_PACKAGES([]))
                else
                    dispatch(SET_CUSTOMER_PACKAGES(response.data))

                dispatch(SET_CUSTOMER_COUNT(response.count))
            } else {
                dispatch(SET_CUSTOMER_PACKAGES([]))
                dispatch(SET_CUSTOMER_COUNT(0))
            }
        })
    }
}
export function SET_UPDATE_CUSTOMER_PACKAGES(item, index, ProcessesData) {
    return async (dispatch) => {
        dispatch(SET_CUSTOMER_PACKAGES_LOADING({ key: 'isLoadingUpdate', value: true }))
        const response = await window.$api.put(`CustomerPackages/${item.Id}`, item);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_UPDATE_CUSTOMER_PACKAGES_LIST({
                status: 'update',
                variant: 'success',
                message: `Tebrikler! ${item.Detail} ürününüz başarıyla güncelledik.`,
                data: { ...item, ProcessesData },
                index
            }))
            setTimeout(() => {
                dispatch(SET_UPDATE_MAIN_LIST())
                dispatch(SET_CUSTOMER_PACKAGES_LOADING({ key: 'isLoadingUpdate', value: false }))
            }, 2500);
        } else
            dispatch(SET_UPDATE_CUSTOMER_PACKAGES_LIST({
                status: 'update',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Hizmetinizi güncelleyemedik, lütfen bir süre sonra tekrar deneyin..`,
                data: item,
                index
            }))


    }
}
function SET_UPDATE_MAIN_LIST() {
    return (dispatch) => {
        dispatch(SET_CUSTOMER_PACKAGES_LOADING({ key: 'isLoading', value: true }))
        setTimeout(() => {
            dispatch(SET_CUSTOMER_PACKAGES_LOADING({ key: 'isLoading', value: false }))
        }, 1000);
    }
}
export function SET_UPDATE_CUSTOMER_PACKAGES_LIST(payload) {
    return {
        type: 'SET_UPDATE_CUSTOMER_PACKAGES_LIST',
        payload
    }
}
function SET_CUSTOMER_PACKAGES(payload) {
    return {
        type: 'SET_CUSTOMER_PACKAGES',
        payload
    }
}
function SET_CUSTOMER_COUNT(payload) {
    return {
        type: 'SET_CUSTOMER_COUNT',
        payload
    }
}
export function SET_CUSTOMER_PACKAGES_LOADING(payload) {
    return {
        type: 'SET_CUSTOMER_LOADING',
        payload
    }
}
export function SET_REMOVE_CUSTOMER_PACKAGES(index, id) {
    return async (dispatch) => {
        dispatch(SET_CUSTOMER_PACKAGES_LOADING({ key: 'isLoadingRemove', value: true }))

        const response = await window.$api.delete(`CustomerPackages/${id}`);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_REMOVE_CUSTOMER_PACKAGES_LIST({
                status: 'remove',
                variant: 'success',
                message: `Tebrikler! Paketinizi başarıyla kaldırdık..`,
                data: { index, id },
            }))
            setTimeout(() => {
                dispatch(SET_UPDATE_MAIN_LIST())
                dispatch(SET_CUSTOMER_PACKAGES_LOADING({ key: 'isLoadingRemove', value: false }))
            }, 2500);
        } else
            dispatch(SET_REMOVE_CUSTOMER_PACKAGES_LIST({
                status: 'remove',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Paketinizi kaldıramadık, lütfen bir süre sonra tekrar deneyin..`,
                data: { index: -1, }
            }))


    }
}
export function SET_REMOVE_CUSTOMER_PACKAGES_LIST(payload) {
    return {
        type: 'SET_REMOVE_CUSTOMER_PACKAGES_LIST',
        payload
    }
}
export function SET_ALERT_BOX(payload) {
    return {
        type: 'SET_ALERT_BOX',
        payload
    }
}
export function SET_INSERT_CUSTOMER_PACKAGES_LIST(payload) {
    return {
        type: 'SET_INSERT_CUSTOMER_PACKAGES_LIST',
        payload
    }
}
export function SET_INSERT_CUSTOMER_PACKAGES(item, ProcessesData) {
    console.log(item, ProcessesData, "eveeet burda");
    return async (dispatch) => {
        dispatch(SET_CUSTOMER_PACKAGES_LOADING({ key: 'isLoadingInsert', value: true }))

        const response = await window.$api.post(`CustomerPackages/Create`, item);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_INSERT_CUSTOMER_PACKAGES_LIST({
                status: 'insert',
                variant: 'success',
                message: `Tebrikler! ${item.Detail} ürününüz başarıyla eklendi.`,
                data: { ...item, ProcessesData, Id: response.data }
            }))
            setTimeout(() => {
                dispatch(SET_UPDATE_MAIN_LIST())
                dispatch(SET_CUSTOMER_PACKAGES_LOADING({ key: 'isLoadingInsert', value: false }))
            }, 2500);
        } else
            dispatch(SET_INSERT_CUSTOMER_PACKAGES_LIST({
                status: 'insert',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Paketinizi ekleyemedik, lütfen bir süre sonra tekrar deneyin..`,
                data: item,
            }))


    }
}