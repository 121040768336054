import React, { useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../../shared/loading'
import { SET_UPDATE_PROCESSING, SET_PROCESSING_LOADING, SET_ALERT_BOX } from '../../../../store/module/processing/action'
export const InsertProcessing = (props) => {
    const [item, setItem] = useState(props.item)
    const index = props.index;
    const UPDATE_IT = () => {
        if (item.Name === '' || item.Name.length > 50 || item.Detail.length > 140 || item.PriceTRY < 0 || item.PriceTRY === null || item.PriceEUR < 0 || item.PriceEUR === null || item.PriceUSD < 0 || item.PriceUSD === null || item.Duration < 0 || item.Duration === null)
            return props.SET_ALERT_BOX({
                status: 'update',
                variant: 'danger',
                message: 'Tüm alanları eksiksiz doldurmanız gerekmektedir.'

            })
        props.SET_UPDATE_PROCESSING(item, index);
    }
    return (
        <div>
            <div>
                <div className='alert alert-secondary'>
                    * Kayıt edeceğiniz hizmetler rezzervasyon oluşturulurken size işlemin süresini ve toplam ücretini otomatik dolduracaktır.
                </div>
                <div className='form-group my-4'>
                    <input value={item.Name} type="text" onInput={(e) => setItem({ ...item, Name: e.target.value })} placeholder='Hizmet Adı *' className='form-control'></input>
                    <div className='d-flex justify-content-end text-light'> <small>{item.Name.length}/50</small></div>
                    <div> <small>* Örn: Saç Kesimi, Boyama vs.</small></div>
                    <div> <small>* Hizmet Adı en fazla 50 karakter olmalıdır.</small></div>
                    {
                        item.Name.length < 1 ? (<div className='alert alert-danger mt-2'> <small>* Hizmet adı girmeniz zorunludur.</small></div>) : ''
                    }
                </div>
                <div className='form-group my-4'>
                    <textarea maxLength={140} value={item.Detail} rows="3" type="text" onInput={(e) => setItem({ ...item, Detail: e.target.value })} placeholder='Hizmet Açıklaması' className='form-control'></textarea>
                    <div className='d-flex justify-content-end text-light'> <small>{item.Detail.length}/140</small></div>
                    <div> <small>* Hizmete detay ekleyebilirsiniz.</small></div>
                    <div> <small>* Hizmete detay en fazla 140 karakter olmalıdır.</small></div>

                </div>
                <div className='form-group my-4'>
                    <div className="input-group mb-3 ">
                        <input value={item.Duration} type="number" onInput={(e) => setItem({ ...item, Duration: Number(e.target.value) })} placeholder='Hizmet Süresi (örn. 30dk) *' className='form-control'></input>
                        <span className="input-group-text">dk.</span>
                    </div>
                    <div> <small>* Hizmet süresini dakika cinsinden yazınız.</small></div>
                    {
                        item.Duration < 0 ? (<div className='alert alert-danger mt-2'> <small>* Hizmet süresi 0 dakikadan küçük olamaz.</small></div>) : ''
                    }
                    {
                        item.Duration === null ? (<div className='alert alert-danger mt-2'> <small>* Hizmet süresi girilmesi zorunludur.</small></div>) : ''
                    }
                </div>
                <div className='alert alert-secondary'>
                    * Türk Lirası (₺), EURO (€) ve Dolar ($) biriminden ayrı ayrı fiyat belirtmelisiniz.
                </div>
                <div className='form-group my-4'>
                    <label>Hizmetin Türk Lirası (₺) fiyatını girin.</label>
                    <div className="input-group mb-3 "><span className="input-group-text">₺</span>
                        <input value={item.PriceTRY} type="number" onInput={(e) => setItem({ ...item, PriceTRY: Number(e.target.value) })} className="form-control" placeholder='Hizmet Ücreti (₺) *' />
                    </div>
                    <div> <small>* Hizmetinize ücret tanımlayabilirsiniz.</small></div>
                    {
                        item.PriceTRY < 0 ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti 0 TL'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        item.PriceTRY === null ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti girilmesi zorunludur.</small></div>) : ''
                    }

                </div>
                <div className='form-group my-4'>
                    <label>Hizmetin Euro (€) fiyatını girin.</label>

                    <div className="input-group mb-3 "><span className="input-group-text">€</span>
                        <input value={item.PriceEUR} type="number" onInput={(e) => setItem({ ...item, PriceEUR: Number(e.target.value) })} className="form-control" placeholder='Hizmet Ücreti (₺) *' />
                    </div>
                    <div> <small>* Hizmetinize ücret tanımlayabilirsiniz.</small></div>
                    {
                        item.PriceEUR < 0 ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti 0 €'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        item.PriceEUR === null ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti girilmesi zorunludur.</small></div>) : ''
                    }

                </div>
                <div className='form-group my-4'>
                    <label>Hizmetin Dolar ($) fiyatını girin.</label>

                    <div className="input-group mb-3 "><span className="input-group-text">$</span>
                        <input value={item.PriceUSD} type="number" onInput={(e) => setItem({ ...item, PriceUSD: Number(e.target.value) })} className="form-control" placeholder='Hizmet Ücreti ($) *' />
                    </div>
                    <div> <small>* Hizmetinize ücret tanımlayabilirsiniz.</small></div>
                    {
                        item.PriceUSD < 0 ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti 0 $'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        item.PriceUSD === null ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti girilmesi zorunludur.</small></div>) : ''
                    }

                </div>
                <div className='form-group my-4'>
                    <input value={item.Point} type="number" onInput={(e) => setItem({ ...item, Point: Number(e.target.value) })} placeholder='Puan' className='form-control'></input>
                    <div> <small>* Hizmetinize müşterinin puan biriktirmesi için puan ekleyebilirsiniz.</small></div>
                    {
                        item.Point < 0 ? (<div> <small>* Puan 0 dakikadan küçük olamaz.</small></div>) : ''
                    }

                </div>                {
                    props.alertBox.status === 'update' ?
                        <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                }
                <div className='mt-4 d-flex justify-content-end'>
                    <button onClick={() => UPDATE_IT()} disabled={props.isLoading} className='btn btn-secondary d-flex'>
                        {
                            props.isLoading ?
                                <Loading size="sm"></Loading>
                                : ''
                        } Güncelle
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.processing.isLoadingUpdate,
    alertBox: state.processing.alertBox
})

const mapDispatchToProps = { SET_UPDATE_PROCESSING, SET_PROCESSING_LOADING, SET_ALERT_BOX }

export default connect(mapStateToProps, mapDispatchToProps)(InsertProcessing)