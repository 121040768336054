import SET_LOCALSTORAGE from '../../../plugins/SetLocalstorage'
import { SET_REMOVE_COMPANY } from '../company/action';
export function SEND_TO_LOGIN(phone, password, history, autologin = false) {
    return (dispatch) => {
        dispatch(SET_LOADING_ON_LOGIN_PAGE(true));
        window.$api.login({
            phone,
            password
        }).then(response => {
            if (response.message === 'OK' && response.result === 'OK') {
                dispatch(SET_ACTIVE_USER({
                    data: response, form: {
                        phone, password
                    },
                    history,
                    autologin
                }));
            } else {
                dispatch(SET_INVALID_LOGIN('Hesabına giriş yap'));
                history.push('/login')
            }

        })
    }
}
export function SET_UPDATE_ACTIVE_USER(data) {
    return {
        type: 'SET_UPDATE_ACTIVE_USER',
        payload: data
    }
}
export function CHECK_AUTOLOGIN_ON_LOCALSTORAGE(history) {
    return (dispatch) => {
        const user = localStorage.getItem('user');
        const token = localStorage.getItem('token'); // 
        const expiredAt = localStorage.getItem('expiredAt'); // 3 gün süreyi geçere otomatik logout yapar.
        if (expiredAt !== null) {
            const today = new Date().getTime();
            const expAt = Number(expiredAt)
            if (expAt < today) dispatch(SET_LOGOUT(history))
        }
        if (user === null || token === null) {
            dispatch(SET_INVALID_LOGIN('Oturum süreniz dolmuştur. Lütfen tekrar giriş yapın.')) // alert mesajını set et.
            dispatch(SET_REMOVE_COMPANY()) // aktif company varsa siler.
            dispatch(SET_LOGOUT(history)) // kullanıcıyı logout eder tüm localstorage i temizler.

        }

    }
}
export function CHECK_INITIAL_AUTOLOGIN(history) {
    return (dispatch) => {
        try {
            const data = localStorage.getItem('user');
            if (data) {
                const user = JSON.parse(data).form
                dispatch(SEND_TO_LOGIN(user.phone, user.password, history, false))
            }
            else {
                dispatch(SET_INVALID_LOGIN('Hemen Giriş Yapın'))
                dispatch(SET_LOGOUT(history))
            }
        } catch (error) {
            console.log(error);
            dispatch(SET_INVALID_LOGIN('Oturum süreniz dolmuştur. Lütfen tekrar giriş yapın.'))
            dispatch(SET_LOGOUT(history))
        }
    }
}
const SET_LOADING_ON_LOGIN_PAGE = (status) => {
    return {
        type: "SET_LOADING_ON_LOGIN_PAGE",
        payload: status
    }
}
const SET_ACTIVE_USER = ({ data, form, history }) => {
    SET_LOCALSTORAGE("user", { ...data, form })
    SET_LOCALSTORAGE("token", data.data.Token)
    SET_LOCALSTORAGE("expiredAt", new Date().getTime() + 259200000)
    return {
        type: "SET_ACTIVE_USER",
        payload: { data: data.data, history }
    }
}
const SET_INVALID_LOGIN = (payload = "Telefon numaranız veya şifreniz yanlış. Lütfen tekrar deneyin.") => {
    return {
        type: "SET_INVALID_LOGIN",
        payload
    }
}

export const SET_LOGOUT = (history, message = "Oturumunuzu başarıyla kapattınız.", variant = "success") => {
    return (dispatch) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user')
        localStorage.removeItem('companyToken')
        localStorage.removeItem('expiredAt')
        localStorage.removeItem('company')
        console.log(document.location.hash, "sssevet set logout external kontrolü");
        if (history.location.pathname === '/external-login') {
            console.log("buradayımmmm set logout", document.location);
            // const path = new URL(document.location.host + history.location.pathname + history.location.search)
            const path = new URL(document.location.href);
            console.log(path, document.location.host + history.location.pathname + history.location.search, "logout path");
            dispatch(SET_USER_IS_EXTERNAL(true, path));
        }
        history.push('/login');
        return {
            type: "SET_LOGOUT",
            payload: { message, variant }
        }
    }
}


export const SET_USER_IS_EXTERNAL = (payload = true, path = {}) => {
    console.log(payload, "set user is external");
    return {
        type: "SET_USER_IS_EXTERNAL",
        payload: path
    }
}