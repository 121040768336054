import React from 'react'
import { connect } from 'react-redux'
import CompanySettingsChild from '../components/main-components/CompanySettings/Main'
export const CompanySettings = (props) => {

  return (
    <div className='container'>
      <CompanySettingsChild></CompanySettingsChild>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettings)