import { SET_LOGOUT } from "../users/action"
export function SET_ACTIVE_COMPANY(company) {
    return {
        type: 'SET_ACTIVE_COMPANY',
        payload: company
    }
}
export function GET_REMAIN_INFO_OF_ACTIVE_COMPANY(company, history) {
    return async (dispatch, getState) => {
        const userIsExternal = getState().user.userIsExternal
        const user = getState().user.activeUser
        console.log("user externaalll", userIsExternal);
        window.$api.get(`Companies/${company.Id}`).then(response => {
            if (response.message === 'OK' && response.result === 'OK') {
                const userExternalDomain = getState().user.userExternalDomain;
                console.log(userExternalDomain);
                if (userIsExternal) {
                    const domain = userExternalDomain.href.split('?domain=')[1]
                    console.log(domain, response.data, "domainnn");
                    if (domain !== response.data.Domain) return dispatch(SET_LOGOUT(history, `'${response.data.Domain}' site adresine erişiminiz bulunmamaktadır.`, 'danger'))
                    window.opener.postMessage({
                        user,
                        company: response.data,
                        name: 'rezzervasyon',
                        domain,
                        expiredAt: new Date().getTime() + (7 * 24 * 60 * 60 * 1000)
                    }, "*");
                    window.close();
                }
                else {
                    window.companyToken = response.data.CompanyToken
                    localStorage.setItem('companyToken', response.data.CompanyToken)
                    dispatch(SET_REMAIN_INFO_OF_ACTIVE_COMPANY(response.data))
                }
            } else {
                dispatch(SET_LOGOUT(history)) // kullanıcıyı logout eder tüm localstorage i temizler.
            }
        })
    }
}
function SET_REMAIN_INFO_OF_ACTIVE_COMPANY(data) {
    return {
        type: 'SET_REMAIN_INFO_OF_ACTIVE_COMPANY',
        payload: data
    }
}
export function SET_REMOVE_COMPANY() {
    return {
        type: 'SET_REMOVE_COMPANY'
    }
}
export function SET_REFRESH_APP() {
    return {
        type: 'SET_REFRESH_APP'
    }
}
export function SET_UPDATE_EXPIRED_PACKAGE_COMPANY(expiredAt) {
    return {
        type: 'SET_UPDATE_EXPIRED_PACKAGE_COMPANY',
        payload: expiredAt
    }
}
export function GET_COMPANY_STATS(query = '') {
    return async (dispatch) => {
        const response = await window.$api.get(`Rezervations/GetStatistics${query}`)
        if (response.message === 'OK' && response.result === 'OK') {
            if (response.data === null) dispatch(SET_COMPANY_STATS([]))
            else dispatch(SET_COMPANY_STATS(response.data))
        } else
            dispatch(SET_COMPANY_STATS())

    }
}

function SET_COMPANY_STATS(data = null) {
    return {
        type: 'SET_COMPANY_STATS',
        payload: data
    }

}
export function SET_UPDATE_ACTIVE_COMPANY(data = null) {
    return {
        type: 'SET_UPDATE_ACTIVE_COMPANY',
        payload: data
    }

}
export function SET_UPDATE_REMAIN_OF_COMPANY(data = null) {
    return {
        type: 'SET_UPDATE_REMAIN_OF_COMPANY',
        payload: data
    }

}
