import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import { theme } from '../../../plugins/select.theme';
import Loading from '../../shared/loading';
import { SET_UPDATE_ROOM_W_INDEX } from '../../../store/module/rooms/action';
export const UpdateProcessingOfRoom = (props) => {
    const [SelectedProcessing, setSelectedProcessing] = useState(null);
    let [alertBox, setAlertBox] = useState({ status: false, message: '', variant: 'danger' });
    const [isLoaded, setisLoaded] = useState(false);
    function sendSelectedProcessing(data) {
        setAlertBox({
            variant: 'danger',
            status: false,
            message: ''
        })
        setSelectedProcessing(data);
        const remove = props.room.Processes !== null ? props.room.Processes.filter((p) => data.findIndex(d => p.Id === d.Id) === -1) : []
        const NewProcesses = data.filter((d) => {
            if (props.room.Processes === null) return false
            return props.room.Processes.findIndex(p => p.Id === d.Id) === -1
        })
        window.$api.put(`Rooms/${props.room.Id}`, {
            RemovedProcesses: remove.map(r => r.Id),
            NewProcesses: NewProcesses.map(n => n.Id)
        }).then(response => {
            if (response.message === 'OK' && response.result === 'OK') {
                setAlertBox({
                    variant: 'success',
                    status: true,
                    message: 'Güncelleme işlemi başarıyla gerçekleştirildi.'
                })
                const index = props.roomsList.findIndex(r => r.Id === props.room.Id)
                props.SET_UPDATE_ROOM_W_INDEX({ Processes: data }, index)
            } else {
                setAlertBox({
                    variant: 'danger',
                    status: true,
                    message: 'Beklenmedik bir hata ile karşılaştık! Hizmetler güncellenmedi. Lütfen tekrar deneyiniz.'
                })
            }
        })
    }
    useEffect(() => {
        setSelectedProcessing(props.processingListofRoom)
        setTimeout(() => {
            setisLoaded(true)
        }, 250);
    }, [])
    return (
        <div className='card'>
            <div className='card-body'>
                {
                    alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                }
                <div className='form-group my-4'>
                    <label>Odanın Verdiği Hizmetler</label>
                    {
                        !isLoaded ? <div className='text-center'><Loading variant='primary' description='Hizmetleriniz Yükleniyor..'></Loading></div> :
                            <Select
                                defaultValue={SelectedProcessing}
                                placeholder="Odanınızın Hizmetlerini Seçin"
                                isMulti={true}
                                isDisabled={props.RoleId === 4 || props.RoleId === 3}
                                name="Fullname"
                                isSearchable={false}
                                onChange={sendSelectedProcessing}
                                options={props.processingList}
                                getOptionLabel={(option) => <div>    <i className="flaticon-041-graph"></i> {option.Name} </div>}
                                getOptionValue={(val) => val.Id}
                                theme={theme}
                            >
                            </Select>
                    }
                    <div> <small>* En az 1 adet hizmet seçmeniz zorunludur.</small></div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    processingList: state.processing.processingList,
    roomsList: state.rooms.roomsList
})

const mapDispatchToProps = { SET_UPDATE_ROOM_W_INDEX }

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProcessingOfRoom)