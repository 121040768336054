import React from 'react'
import { connect } from 'react-redux'
import AddRoom from '../components/main-components/Rooms/AddRoom'

export const AddRoomPage = (props) => {
  return (
    <div className='row justify-content-center'>
      <div className='col-12 col-md-8 col-lg-6 col-xl-6'>
        <div className='card'>
          <div className='card-title pt-4 px-4'>Yeni Oda Ekleyin</div>
          <div className='card-body'>
            <p className='alert alert-secondary'>* Firmanıza rezzervasyon alabilmeniz için oda açmanız gerekmektedir. Her odanın rezzervasyonu ayrı olacaktır. Satın almış olduğunuz paketinizde
              bulunan oda sayısı kadar oda açabilme hakkına sahipsiniz. 
            </p>
            <AddRoom></AddRoom>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddRoomPage)