import React, { useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../shared/loading';

export const PaymentActions = (props) => {
    let [activePayment, setActivePayment] = useState(-1);
    let [isEmail, setIsEmail] = useState(false);
    let [isPhone, setIsPhone] = useState(false);
    let [isLoading, setIsLoading] = useState(false);

    let [alertBox, setAlertBox] = useState({ status: false, message: '', variant: '' });

    function SEND_PAYMENT_TO() {
        setIsLoading(true);
        if (activePayment === 1) SEND_TO_IYZICO()
        else if (activePayment === 2) SEND_TO_PAYPAL()
        else if (activePayment === 3) SEND_TO_TRANSFER()
    }
    async function SEND_TO_TRANSFER() {
        setAlertBox({ status: false })
        console.log(props.rezervation);
        const response = await window.$api.post('Payments/Transfer', {
            RezervationId: props.rezervation.Id,
            IsSms: isPhone ? 1 : 0,
            IsEmail: isEmail ? 1 : 0
        })
        if (response.message === 'OK') {
            setAlertBox({
                status: true,
                variant: 'success',
                message: 'Ödeme linki müşteriye başarıyla gönderildi.'
            })
        } else {
            setAlertBox({
                status: true,
                variant: 'danger',
                message: 'Ödeme linki müşteriye gönderemedik. Lütfen tekrar deneyin.'
            })
        }
        setIsLoading(false);
    }
    async function SEND_TO_IYZICO() {
        setAlertBox({ status: false })
        console.log(props.rezervation);
        const response = await window.$api.post('Payments/Iyzico', {
            RezervationId: props.rezervation.Id,
            IsSms: isPhone ? 1 : 0,
            IsEmail: isEmail ? 1 : 0
        })
        if (response.message === 'OK') {
            setAlertBox({
                status: true,
                variant: 'success',
                message: 'Ödeme linki müşteriye başarıyla gönderildi.'
            })
        } else {
            setAlertBox({
                status: true,
                variant: 'danger',
                message: 'Ödeme linki müşteriye gönderemedik. Lütfen tekrar deneyin.'
            })
        }
        setIsLoading(false);
    }
    async function SEND_TO_PAYPAL() {
        setAlertBox({ status: false })
        const response = await window.$api.post('Payments/Paypal', {
            RezervationId: props.rezervation.Id,
            IsSms: isPhone ? 1 : 0,
            IsEmail: isEmail ? 1 : 0
        })
        if (response.message === 'OK') {
            setAlertBox({
                status: true,
                variant: 'success',
                message: 'Ödeme linki müşteriye başarıyla gönderildi.'
            })
        } else {
            setAlertBox({
                status: true,
                variant: 'danger',
                message: 'Ödeme linki müşteriye gönderemedik. Lütfen tekrar deneyin.'
            })
        }
        setIsLoading(false);
    }
    return (
        <div className='my-4'>
            {
                alertBox.status ? <div className={`alert alert-${alertBox.variant}`}>
                    {alertBox.message}
                </div> : ''
            }
            <h6>Müşterinin Mailine veya Cep Telefonuna Ödeme Linki Yollayın.</h6>
            <p>Oluşturmak istediğiniz ödeme linki için aşağıdan Ödeme Yöntemi seçin.</p>
            <div className='text-center'>
                <img onClick={() => { setIsEmail(false); setIsPhone(false); setActivePayment(1) }} src='/images/payments/iyzico.png' className={`payment-icons ${activePayment === 1 ? 'active' : ''}`}></img>
                <img onClick={() => { setIsEmail(false); setIsPhone(false); setActivePayment(2) }} src='/images/payments/paypal.png' className={`payment-icons ${activePayment === 2 ? 'active' : ''}`}></img>
                <img onClick={() => { setIsEmail(false); setIsPhone(false); setActivePayment(3) }} src='/images/payments/havale-eft.png' className={`payment-icons ${activePayment === 3 ? 'active' : ''}`}></img>
            </div>
            {
                activePayment > 0 ?
                    <div className='mt-4'>
                        <h6>Ödeme linkini hangi platformdan gönderelim?</h6>
                        <div>
                            <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="toEmail"
                                    checked={isEmail}
                                    onChange={() => setIsEmail(!isEmail)}
                                    required=""
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="toEmail"
                                >Müşterinin mailine gönder.</label>
                            </div>
                            {
                                activePayment !== 2 ? <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="toPhone"
                                        checked={isPhone}
                                        onChange={() => setIsPhone(!isPhone)}
                                        required=""
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="toPhone"
                                    >Müşterinin telefon numarasına gönder. </label>
                                </div> : ''
                            }
                        </div>
                        {
                            isEmail || isPhone ? <button onClick={() => SEND_PAYMENT_TO()} disabled={isLoading} className='btn btn-block my-3 btn-secondary'>
                                {
                                    isLoading ? <Loading ></Loading> : ''
                                } <span>Ödeme Linki Gönder</span>
                            </button> : ''
                        }
                    </div> : ''
            }
            <hr></hr>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentActions)