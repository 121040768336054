import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap';
import { SET_REMOVE_WORKER, SET_ALERT_BOX } from '../../../../store/module/workers/action';
import Loading from '../../../shared/loading';
import EditWorker from '../EditWorker';

export const ListOfProcessingItem = (props) => {
    const [EditModal, setEditModal] = useState(false);
    const [RemoveModal, setRemoveModal] = useState(false);
    let [index, setIndex] = useState(-1);
    useEffect(() => {
        setIndex(props.list.findIndex(l => l.Id === props.item.Id)) // search yapıldığı için indexi bu şekilde bulunmalı
    }, [])
    function SET_REMOVE_WORKER() {
        props.SET_REMOVE_WORKER(index, props.item.Id);
    }
    function SET_REMOVE_MODAL() {
        props.SET_ALERT_BOX({
            variant: 'danger',
            status: false,
            message: ''
        })
        setRemoveModal(true)

    }
    return (
        <div className='row my-2 py-2 border-bottom'>
            <div className='col-3 my-2 col-md-1 col-lg-1 d-flex align-items-start flex-column'>
                <img className='avatar' src={props.item.ImagePath ? props.item.ImagePath.includes('base64') ? props.item.ImagePath : window.$api.image(props.item.ImagePath) : ''}></img>
            </div>
            <div className='col-9 my-2 col-md-4 col-lg-4 d-flex align-items-start flex-column'>
                <div> <i className='flaticon-381-success text-success'></i> {props.item.Fullname}</div>
                <div><small className='text-light'>{props.item.Phone}</small></div></div>
            <div className='col-3 my-2 col-md-2 col-lg-2 d-flex align-items-center'><strong className='text-secondary'></strong></div>
            <div className='col-6 my-2 col-md-4 col-lg-3 d-flex align-items-center'>
                {
                    props.activeUser.RoleId === 4 ? '' :
                        <div>
                            <button className='btn btn-warning btn-xs mx-1' onClick={() => setEditModal(true)}>Düzenle</button>
                            <button className='btn btn-danger btn-xs mx-1' onClick={() => SET_REMOVE_MODAL()}><i className='flaticon-381-trash-1'></i></button>
                        </div>
                }
            </div>
            <Modal centered className="fade" onHide={() => setEditModal(false)} show={EditModal}>
                <Modal.Header>
                    <Modal.Title>{props.item.Fullname} çalışanını güncelle</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setEditModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    <EditWorker index={index} editModal={EditModal} editWorker={props.item}></EditWorker>
                </Modal.Body>
            </Modal>
            <Modal centered className="fade" onHide={() => setRemoveModal(false)} show={RemoveModal}>
                <Modal.Header>
                    <Modal.Title>{props.item.Fullname} çalışanını kaldır</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setRemoveModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    {
                        props.alertBox.status === 'remove' ?
                            <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> :
                            <p>
                                {props.item.Fullname} çalışanını firmadan kaldırıyorsunuz. Bu işleme devam ederseniz çalışanınız tamamen kaldırılacaktır.
                                <br></br><br></br> <strong className='text-secondary'> Bunu yapmak istediğiniz Emin misiniz?</strong>
                            </p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={() => setRemoveModal(false)}
                        className="btn btn-light btn-xs"
                    >
                        Kapat
                    </button>
                    <button onClick={() => SET_REMOVE_WORKER()} className='btn btn-secondary btn-xs d-flex'>
                        {
                            props.isLoading ?
                                <Loading size="sm"></Loading>
                                : ''
                        } Evet, Çalışanı Kaldır

                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    alertBox: state.workers.alertBox,
    isLoading: state.workers.isLoadingAction,
    activeUser: state.user.activeUser.User

})

const mapDispatchToProps = { SET_REMOVE_WORKER, SET_ALERT_BOX }

export default connect(mapStateToProps, mapDispatchToProps)(ListOfProcessingItem)