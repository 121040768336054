import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { SET_LOGOUT } from '../../../../store/module/users/action';
import RadialDount from './RadialDount';
function dhm(t) {
    var cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor((t - d * cd) / ch),
        m = Math.round((t - d * cd - h * ch) / 60000),
        pad = function (n) {
            return n < 10 ? '0' + n : n
        }
    if (m === 60) {
        h++
        m = 0
    }
    if (h === 24) {
        d++
        h = 0
    }
    if (d > 0)
        return {
            variant: 'info',
            day: (d * 100) / 365,
            text: `${d}g`,
            isDead: false,
        }
    else if (h > 0)
        return {
            variant: 'warning',
            day: (d * 100) / 365,
            text: `${pad(h)}s ${pad(m)}dk`,
            isDead: false,
        }
    else
        return {
            variant: 'danger',
            day: (d * 100) / 365,
            text: `${pad(m)}dk`,
            isDead: false,
        }
}
export const RemainPackageOfCompany = (props) => {
    function SET_REMAIN_DATE(key = 'text') {
        const date = new Date(props.activeUser.CurrentDate).getTime();
        // active olan firmanın paket süresi dolmuş mu kontrolü yapılır. 
        const expiredAt = new Date(props.company.PackageFinishedAt).getTime();
        if (expiredAt < date) {
            SET_LOGOUT(props.history);
            return (0)
        } else {
            const remains = expiredAt - date;
            return (dhm(remains)[key])
        }
    }
    return (
        <div className="col-xl-12 col-sm-6">
            <div className="card text-center">
                <div className="card-body">
                    <div id="radialChart" className="radialChart">
                        <RadialDount remain={SET_REMAIN_DATE('day')} />
                    </div>
                    <h2>{SET_REMAIN_DATE()}.</h2>
                    <span className="fs-16 text-black">Kalan Paket Günün</span>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    company: state.company.activeCompany,
    activeUser: state.user.activeUser.User
})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RemainPackageOfCompany))