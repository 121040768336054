import React, { useState } from 'react'
import { connect } from 'react-redux'
import CompanyApplyBrand from './CompanyApplyBrand'
import CompanyMessages from './CompanyMessages'
import CompanyPackage from './CompanyPackage'
import CompanyPayments from './CompanyPayments'
import CompanyRemove from './CompanyRemove'
import CompanySettings from './CompanySettings'
import CompanySettingsTitle from './CompanySettingsTitle'

export const Main = (props) => {
    const [active, setActive] = useState('settings')
    function routes() {
        if (active === 'settings') return (<CompanySettings setActive={setActive}></CompanySettings>)
        else if (active === 'package') return (<CompanyPackage></CompanyPackage>)
        else if (active === 'apply-brand') return (<CompanyApplyBrand></CompanyApplyBrand>)
        else if (active === 'remove-company') return (<CompanyRemove></CompanyRemove>)
        else if (active === 'company-messages') return (<CompanyMessages></CompanyMessages>)
        else if (active === 'company-payments') return (<CompanyPayments></CompanyPayments>)
    }
    return (
        <>
            <CompanySettingsTitle active={active} setActive={setActive}></CompanySettingsTitle>
            {routes()}
        </>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Main)