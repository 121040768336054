import React from "react";

const Loading = ({ size, variant = 'white', description = '' }) => {
    const sm = size === 'sm'
    return (
        <div> <div className={sm ? `spinner-border spinner-border-sm text-${variant} mx-1` : `spinner-border text-${variant}`} role="status">
        </div>
        {
            description !== '' ?   <div className="mt-4">{description}</div> : ''
        }
        </div>
    );
};

export default Loading;
