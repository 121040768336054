import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../../shared/loading'
import ListOfProcessingItem from './ListOfProcessingItem'
import NoFound from '../../../shared/NoFound'
import { GET_WORKER_LIST, SET_LOADING_WORKER } from '../../../../store/module/workers/action'
export const ListOfProcessing = (props) => {
    const [FilteredListOfProcessing, setFilteredListOfProcessing] = useState(props.workers)
    let [search, setSearch] = useState('');
    useEffect(() => {
        // props.SET_LOADING_WORKER(true)
        props.GET_WORKER_LIST(props.company.Id);
    }, [])
    useEffect(() => {
        setFilteredListOfProcessing(props.workers)
        setSearch('')
    }, [props.isLoading])
    function SET_SEARCH(value) {
        setSearch(value)
        setFilteredListOfProcessing(props.workers.filter(pl => pl.Fullname.toLowerCase().includes(value.toLowerCase())))
    }
    function INIT_ITEMS() {
        if (props.isLoading) return (<div className='text-center my-4'><Loading description="Çalışanlarım yükleniyor.." variant="primary"></Loading></div>)
        else return (<div>
            <div className='justify-content-end row mb-4'>
                <div className='col-12 col-md-6 col-lg-8 col-xl-4'>
                    <div className="input-group search-area">
                        <input value={search} onInput={(e) => SET_SEARCH(e.target.value)} type="text" className="form-control" placeholder="Çalışan Arayın..." />
                        <span className="input-group-text">
                            <i className="flaticon-381-search-2"></i>
                        </span>
                    </div>
                </div>
            </div>
            <hr></hr>
            {
                FilteredListOfProcessing.length === 0 ?
                    <NoFound description="Herhangi bir çalışanınız bulunmamaktadır."></NoFound>
                    : <div>


                        {FilteredListOfProcessing.map((pl, i) => (
                            <ListOfProcessingItem list={props.workers} index={i} item={pl} key={i}></ListOfProcessingItem>
                        ))}
                        <div className='d-flex justify-content-end mt-1'>
                            <small> {FilteredListOfProcessing.length} adet çalışan bulundu.</small>
                        </div>
                    </div>
            }
        </div>)
    }
    return (
        <div className='card'>
            <div className='card-body'>
                {INIT_ITEMS()}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    workers: state.workers.workers,
    company: state.company.activeCompany,
    isLoading: state.workers.isLoading
})

const mapDispatchToProps = { GET_WORKER_LIST, SET_LOADING_WORKER }

export default connect(mapStateToProps, mapDispatchToProps)(ListOfProcessing)