import React from 'react'
import { connect } from 'react-redux'
import CustomerPackagesIndex from '../components/main-components/CustomerPackages/CustomerPackagesChild'
export const CustomerPackages = (props) => {
  return (
    <CustomerPackagesIndex></CustomerPackagesIndex>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPackages)