import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Checking from '../../components/shared/checking';
import ChooseCompanyPackage from '../../pages/ChooseCompanyPackage';
import Tracking from './Tracking';
import UseApp from './UseApp';

export const CheckCompanyPackage = (props) => {
    const date = new Date().getTime(props.activeUser.CurrentDate); // serverdan gelmeli
    const packageDate = new Date(props.activeCompany.PackageFinishedAt).getTime();
    if (date > packageDate) {
        return (
            <div>
                {
                    props.connectedRemain ?
                        props.activeCompany.IsVerify === 0 ? <Tracking></Tracking> :
                            <ChooseCompanyPackage></ChooseCompanyPackage>
                        :
                        <Checking description="Firma Paket Bilgisi alınıyor..." />

                }
            </div>
        )
    } else {

        return (
            <UseApp></UseApp>
        )
    }
}


const mapStateToProps = (state) => ({
    activeCompany: state.company.activeCompany,
    connectedRemain: state.company.remainOfActiveCompany.status,
    activeUser: state.user.activeUser.User
})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckCompanyPackage))