const INITIAL_STATE = {
    processingList: [],
    isLoading: false,
    isLoadingUpdate: false,
    isLoadingInsert: false,
    isLoadingRemove: false,
    alertBox: {
        status: 'null',
        variant: 'success',
        message: 'ok'
    }
}
export const ProcessReducer = (state = INITIAL_STATE, action) => {
    const list = state.processingList
    switch (action.type) {
        case "SET_PROCESSING_LIST":
            return { ...state, processingList: action.payload, isLoading: false }
        case "SET_PROCESSING_LOADING":
            return { ...state, [action.payload.key]: action.payload.value, alertBox: { status: 'null', message: 'ok', variant: 'success' } }
        case "SET_UPDATE_PROCESSING_LIST":
            list[action.payload.index] = { ...action.payload.data }
            return { ...state, processingList: list, isLoading: false, isLoadingUpdate: false, alertBox: { status: 'update', message: action.payload.message, variant: action.payload.variant } }
        case "SET_ALERT_BOX":
            return { ...state, alertBox: { status: action.payload.status, message: action.payload.message, variant: action.payload.variant } }
        case "SET_INSERT_PROCESSING_LIST":
            if (action.payload.variant === 'success')
                list.push(action.payload.data)
            return { ...state, processingList: list, isLoadingInsert: false, alertBox: { status: 'insert', message: action.payload.message, variant: action.payload.variant } }
        case "SET_REMOVE_PROCESSING_LIST":
            if (action.payload.Id !== -1) {
                const index = list.findIndex(l => l.Id === action.payload.data.id)
                list.splice(index, 1);
            }
            return { ...state, processingList: list, isLoadingRemove: false, alertBox: { status: 'remove', message: action.payload.message, variant: action.payload.variant } }
        default:
            return state
    }
}