import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../../shared/loading'
import ListOfProcessingItem from './ListOfProcessingItem'
import NoFound from '../../../shared/NoFound'
import Pagination from '../../../shared/pagination'


import { GET_CUSTOMER_LIST, SET_LOADING_CUSTOMER } from '../../../../store/module/customers/action'
export const ListOfProcessing = (props) => {
    const [FilteredListOfProcessing, setFilteredListOfProcessing] = useState(props.customers)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [intrvl, setIntrvl] = useState(-1);
    let [search, setSearch] = useState('');
    useEffect(() => {
        props.SET_LOADING_CUSTOMER(true)
        props.GET_CUSTOMER_LIST();
    }, [])
    useEffect(() => {
        setFilteredListOfProcessing(props.customers)
        // setSearch('')
    }, [props.isLoading])
    function CHANGED_PAGE(pageCount) {
        setPage(pageCount);
        props.GET_CUSTOMER_LIST(search, pageCount, limit);
    }
    function TOTAL_PAGE() {
        return Math.ceil(props.count / limit);
    }
    function SET_SEARCH(value) {
        setSearch(value)
        if (intrvl !== -1) clearTimeout(intrvl);
        const timout = setTimeout(() => {
            props.GET_CUSTOMER_LIST(value, 1, limit);
            setPage(1);
        }, 1500);
        setIntrvl(timout);
    }
    function INIT_ITEMS() {
        if (props.isLoading) return (<div className='text-center my-4'><Loading description="Müşterilerim yükleniyor.." variant="primary"></Loading></div>)
        else return (<div>
            <div className='justify-content-end row mb-4'>
                <div className={`col-12 col-md-${props.ShowAddCustomer ? 6 : 4} col-lg-${props.ShowAddCustomer ? 6 : 4} col-xl-${props.ShowAddCustomer ? 6 : 4}`}>
                    <div className="input-group search-area">
                        <input value={search} onInput={(e) => SET_SEARCH(e.target.value)} type="text" className="form-control" placeholder="Müşteri Arayın..." />
                        <span className="input-group-text">
                            <i className="flaticon-381-search-2"></i>
                        </span>
                    </div>
                </div>
            </div>
            <hr></hr>
            {
                FilteredListOfProcessing.length === 0 ?
                    <NoFound description="Herhangi bir müşteriniz bulunmamaktadır."></NoFound>
                    : <div>


                        {FilteredListOfProcessing.map((pl, i) => (
                            <ListOfProcessingItem list={props.customers} index={i} item={pl} key={i}></ListOfProcessingItem>
                        ))}
                        <div className='d-flex justify-content-end mt-1'>
                            <small> {props.count} adet müşteri bulundu.</small>
                        </div>
                    </div>
            }
            <Pagination changedPage={CHANGED_PAGE} totalPage={TOTAL_PAGE()} page={page}></Pagination>

        </div>)
    }
    return (
        <div className='card'>
            <div className='card-body'>
                {INIT_ITEMS()}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    customers: state.customers.customers,
    count: state.customers.count,
    company: state.company.activeCompany,
    isLoading: state.customers.isLoading
})

const mapDispatchToProps = { GET_CUSTOMER_LIST, SET_LOADING_CUSTOMER }

export default connect(mapStateToProps, mapDispatchToProps)(ListOfProcessing)