import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../../shared/loading'
import { SET_INSERT_PROCESSING, SET_ALERT_BOX } from '../../../../store/module/processing/action'
export const InsertProcessing = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const [form, setForm] = useState({
        Name: '',
        Detail: '',
        Duration: null,
        PriceTRY: null,
        PriceEUR: null,
        PriceUSD: null,
        Point: 0

    })
    function SEND_NEW_PROCESSING() {
        setSubmitted(true)
        if (form.Name === '' || form.Name.length > 50 || form.Detail.length > 140 || form.Point === '' || form.PriceTRY < 0 || form.PriceTRY === null || form.PriceUSD < 0 || form.PriceUSD === null || form.PriceEUR < 0 || form.PriceEUR === null || form.Duration < 0 || form.Duration === null)
            return props.SET_ALERT_BOX({
                status: 'insert',
                variant: 'danger',
                message: 'Tüm alanları eksiksiz doldurmanız gerekmektedir.'

            })
        props.SET_INSERT_PROCESSING(form)
    }
    useEffect(() => {
        if (props.alertBox.status === 'insert' && props.alertBox.variant === 'success') {
            setSubmitted(false);
            setForm({
                Name: '',
                Detail: '',
                Duration: null,
                PriceTRY: null,
                PriceEUR: null,
                PriceUSD: null,
                Point: 0

            })
        }
    }, [props.alertBox])
    return (
        <div className='card'>
            <div className='card-title p-4'>
                <h5>Yeni hizmet ekleyin</h5>
            </div>
            <div className='card-body'>
                <div className='alert alert-secondary'>
                    * Kayıt edeceğiniz hizmetler rezzervasyon oluşturulurken size işlemin süresini ve toplam ücretini otomatik dolduracaktır.
                </div>
                <div className='form-group my-4'>
                    <input value={form.Name} type="text" onInput={(e) => setForm({ ...form, Name: e.target.value })} placeholder='Hizmet Adı *' className='form-control'></input>
                    <div className='d-flex justify-content-end text-light'> <small>{form.Name.length}/50</small></div>
                    <div> <small>* Örn: Saç Kesimi, Boyama vs.</small></div>
                    <div> <small>* Hizmet Adı en fazla 50 karakter olmalıdır.</small></div>
                    {
                        form.Name.length < 1 && submitted ? (<div className='alert alert-danger mt-2'> <small>* Hizmet adı girmeniz zorunludur.</small></div>) : ''
                    }
                </div>
                <div className='form-group my-4'>
                    <textarea maxLength={140} value={form.Detail} rows="3" type="text" onInput={(e) => setForm({ ...form, Detail: e.target.value })} placeholder='Hizmet Açıklaması' className='form-control'></textarea>
                    <div className='d-flex justify-content-end text-light'> <small>{form.Detail.length}/140</small></div>
                    <div> <small>* Hizmete detay ekleyebilirsiniz.</small></div>
                    <div> <small>* Hizmete detay en fazla 140 karakter olmalıdır.</small></div>

                </div>
                <div className='form-group my-4'>
                    <div className="input-group mb-3 ">
                        <input value={form.Duration} type="number" onInput={(e) => setForm({ ...form, Duration: Number(e.target.value) })} placeholder='Hizmet Süresi (örn. 30dk) *' className='form-control'></input>
                        <span className="input-group-text">dk.</span>
                    </div>
                    <div> <small>* Hizmet süresini dakika cinsinden yazınız.</small></div>
                    {
                        form.Duration < 0 && submitted ? (<div className='alert alert-danger mt-2'> <small>* Hizmet süresi 0 dakikadan küçük olamaz.</small></div>) : ''
                    }
                    {
                        form.Duration === null && submitted ? (<div className='alert alert-danger mt-2'> <small>* Hizmet süresi girilmesi zorunludur.</small></div>) : ''
                    }
                </div>
                <div className='alert alert-secondary'>
                    * Türk Lirası (₺), EURO (€) ve Dolar ($) biriminden ayrı ayrı fiyat belirtmelisiniz.
                </div>
                <div className='form-group my-4'>
                    <label>Hizmetin Türk Lirası (₺) fiyatını girin.</label>
                    <div className="input-group mb-3 "><span className="input-group-text">₺</span>
                        <input value={form.PriceTRY} type="number" onInput={(e) => setForm({ ...form, PriceTRY: Number(e.target.value) })} className="form-control" placeholder='Hizmet Ücreti (₺) *' />
                    </div>
                    <div> <small>* Hizmetinize TÜRK LİRASI ücret tanımlayabilirsiniz.</small></div>
                    {
                        form.PriceTRY < 0 && submitted ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti 0 TL'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        form.PriceTRY === null && submitted ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti girilmesi zorunludur.</small></div>) : ''
                    }

                </div>
                <div className='form-group my-4'>
                    <label>Hizmetin Euro (€) fiyatını girin.</label>
                    <div className="input-group mb-3 "><span className="input-group-text">€</span>
                        <input value={form.PriceEUR} type="number" onInput={(e) => setForm({ ...form, PriceEUR: Number(e.target.value) })} className="form-control" placeholder='Hizmet Ücreti (€) *' />
                    </div>
                    <div> <small>* Hizmetinize EURO ücret tanımlayabilirsiniz.</small></div>
                    {
                        form.PriceEUR < 0 && submitted ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti 0 €'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        form.PriceEUR === null && submitted ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti girilmesi zorunludur.</small></div>) : ''
                    }

                </div>
                <div className='form-group my-4'>
                    <label>Hizmetin Dolar ($) fiyatını girin.</label>
                    <div className="input-group mb-3 "><span className="input-group-text">$</span>
                        <input value={form.PriceUSD} type="number" onInput={(e) => setForm({ ...form, PriceUSD: Number(e.target.value) })} className="form-control" placeholder='Hizmet Ücreti ($) *' />
                    </div>
                    <div> <small>* Hizmetinize DOLAR ücret tanımlayabilirsiniz.</small></div>
                    {
                        form.PriceUSD < 0 && submitted ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti 0 $'den küçük olamaz.</small></div>) : ''
                    }
                    {
                        form.PriceUSD === null && submitted ? (<div className='alert alert-danger mt-2'> <small>* Hizmet ücreti girilmesi zorunludur.</small></div>) : ''
                    }

                </div>

                <div className='form-group my-4'>
                    <input value={form.Point} type="number" onInput={(e) => setForm({ ...form, Point: Number(e.target.value) })} placeholder='Puan' className='form-control'></input>
                    <div> <small>* Hizmetinize müşterinin puan biriktirmesi için puan ekleyebilirsiniz.</small></div>
                    {
                        form.Point < 0 && submitted ? (<div> <small>* Puan 0 dakikadan küçük olamaz.</small></div>) : ''
                    }

                </div>
                {
                    props.alertBox.status === 'insert' ?
                        <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                }
                <div className='mt-4 d-flex justify-content-end'>
                    <button onClick={() => SEND_NEW_PROCESSING()} disabled={props.isLoading} className='btn btn-primary'>
                        {
                            props.isLoading ?
                                <Loading size="sm"></Loading>
                                : ''
                        } Kaydet
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.processing.isLoadingInsert,
    alertBox: state.processing.alertBox
})


const mapDispatchToProps = { SET_INSERT_PROCESSING, SET_ALERT_BOX }

export default connect(mapStateToProps, mapDispatchToProps)(InsertProcessing)