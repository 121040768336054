import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../../shared/loading'
import ListOfProcessingItem from './ListOfProcessingItem'
import NoFound from '../../../shared/NoFound'
import { GET_CUSTOMER_PACKAGES, SET_CUSTOMER_PACKAGES_LOADING } from '../../../../store/module/customerPackages/action'
export const ListOfProcessing = (props) => {
    const [FilteredListOfCustomerPackages, setFilteredListOfCustomerPackages] = useState([])
    let [search, setSearch] = useState('');
    useEffect(() => {
        props.SET_CUSTOMER_PACKAGES_LOADING({ key: 'isLoading', value: true })
        props.GET_CUSTOMER_PACKAGES('');
    }, [])
    useEffect(() => {
        // setFilteredListOfCustomerPackages(props.customerPackages || [])
        setSearch('')
    }, [props.isLoading])
    function SET_SEARCH(value) {
        setSearch(value)
        console.log("customer packages,", props.customerPackages);
        // setFilteredListOfCustomerPackages(props.customerPackages.filter(pl => pl.Detail.toLowerCase().includes(value.toLowerCase())))
    }
    function INIT_ITEMS() {
        if (props.isLoading) return (<div className='text-center my-4'><Loading description="Hizmetlerim yükleniyor.." variant="primary"></Loading></div>)
        else return (<div>
            <div className='justify-content-end row mb-4'>
                <div className='col-12 col-md-6 col-lg-8 col-xl-5'>
                    <div className="input-group search-area">
                        <input value={search} onInput={(e) => SET_SEARCH(e.target.value)} type="text" className="form-control" placeholder="Hizmet Arayın..." />
                        <span className="input-group-text">
                            <i className="flaticon-381-search-2"></i>
                        </span>
                    </div>
                </div>
            </div>
            <hr></hr>
            {
                FilteredListOfCustomerPackages.length === 0 ?
                    <NoFound description="Herhangi bir hizmetiniz bulunmamaktadır."></NoFound>
                    : <div>


                        {FilteredListOfCustomerPackages.map((pl, i) => (
                            <ListOfProcessingItem list={props.customerPackages} index={i} item={pl} key={i}></ListOfProcessingItem>
                        ))}
                        <div className='d-flex justify-content-end mt-1'>
                            <small> {FilteredListOfCustomerPackages.length} adet hizmetiniz bulundu.</small>
                        </div>
                    </div>
            }
        </div>)
    }
    return (
        <div className='card'>
            <div className='card-body'>
                {INIT_ITEMS()}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    customerPackages: state.customerPackages.customerPackagesList || [],
    company: state.company.activeCompany,
    isLoading: state.customerPackages.isLoading
})

const mapDispatchToProps = { GET_CUSTOMER_PACKAGES, SET_CUSTOMER_PACKAGES_LOADING }

export default connect(mapStateToProps, mapDispatchToProps)(ListOfProcessing)