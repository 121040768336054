import PropTypes from 'prop-types'
import { Suspense, useEffect } from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import CheckActiveCompany from './CheckActiveCompany'
import CheckUserLogin from './CheckUserLogin'
import CheckCompanyPackage from './CheckCompanyPackage'
import Index from '../../router'
import { CHECK_AUTOLOGIN_ON_LOCALSTORAGE } from '../../store/module/users/action'
import Checking from '../../components/shared/checking'
import Tracking from './Tracking'
import TawkTo from 'tawkto-react'

// checking auth da 3 kontrol yapılır
//  1. User Login edilir ve token bilgisi elimize ulaşılırsa 2. adıma geçilir. ulaşmazsa logine atılır
// 2. Active company var mı ? eğer active company mevcutsa otomatik o firma seçilir. Eğer active firma seçilmemişse 
// ve userın 1 den fazla firması varsa firma seçim ekranına gönderilir. Eğer active firma seçilmemiş olup da userın 1 firması varsa
// otomatik o firmaya bağlanır.
// 3. Active firma seçildikten sonra active firmanın paket bilgisi kontrol edilir. Paketi geçerli süresi varsa app açılır
// . Eğer paket geçerli değilse paket satın alma ekranına gönderilir.
let CheckingRoutes = (
    <Switch>
        <Route path='/checking-user' key={1} component={CheckUserLogin} />
        <Route path='/checking-company' key={2} component={CheckActiveCompany} />
        <Route path='/checking-package/:id' key={3} component={CheckCompanyPackage} />
    </Switch>
);
export const CheckingAuth = (props) => {
    useEffect(() => {
        var tawk = new TawkTo('62bd5e237b967b1179974b93', '1g6prffim')
        tawk.onStatusChange((status) => {
        })
    }, [])
    // localstorage'im dolumu değil mi kontrolü yapılıyor. Eğer içi boşsa logine gönderilir.
    props.CHECK_AUTOLOGIN_ON_LOCALSTORAGE(props.history);
    // serverden server saati istenir. Sebebi: paket süresi kontrolü için gereklidir. Her f5 yapıldığında süre yenilenir.
    const date = new Date(props.activeUser?.CurrentDate).getTime() || 0;
    // active olan firmanın paket süresi dolmuş mu kontrolü yapılır. 
    const expiredAt = new Date(props.company.PackageFinishedAt).getTime();
    if (expiredAt < date)
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {CheckingRoutes}
                </Suspense>
            </div>
        )
    else {
        return (
            props.connected ? props.company.IsVerify === 0 ? <Tracking></Tracking> : <Index></Index> : <Checking description="Firma Paket Bilgileri Alınıyor..."></Checking>
        )
    }
}


const mapStateToProps = (state) => ({
    company: state.company.activeCompany,
    activeUser: state.user.activeUser.User,
    connected: state.company.remainOfActiveCompany.status
})

const mapDispatchToProps = { CHECK_AUTOLOGIN_ON_LOCALSTORAGE }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckingAuth))