import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import Loading from '../../shared/loading'
import RezEvaluate from './RezEvaluate'

export const CreateRequest = ({ toggleTab, setLength, length }) => {
    const [createRequest, setCreateRequest] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState({ status: false, message: 'Yeni onay bekleyen rezervasyon talepleriniz bulunuyor. Hemen kontrol edin.', variant: 'success', icon: 'check-circle' })
    async function GET_CREATE_REZ_REQUEST() {
        setIsLoading(true);
        const response = await window.$api.get('Rezervations/CreateRequests');
        if (response.message === 'OK') {
            setLength(response.count)
            if (response.data !== null) {
                setCreateRequest(response.data);
                REZ_LENGTH_CONTROL(response.count);
            }
            else setCreateRequest([]);
        } else setCreateRequest([])
        setIsLoading(false);

    }
    useEffect(() => {
        console.log(showModal, "modal");
        if (showModal.status) {
            var audio = new Audio('/ring.mp3');
            audio.play();
        }
    }, [showModal.status])
    function REZ_LENGTH_CONTROL(lengthCount) {
        setTimeout(() => {
            if (0 < lengthCount && !showModal.status) setShowModal({ status: true, message: 'Yeni onay bekleyen rezervasyon talepleriniz bulunuyor. Hemen kontrol edin.', variant: 'success', icon: 'check-circle' });
        }, 1000);
    }
    function SET_REMOVE_ITEM() {
        setTimeout(() => {
            GET_CREATE_REZ_REQUEST();
        }, 2500);
    }
    function INTERVAL_GET_REZ_REQUEST() {
        GET_CREATE_REZ_REQUEST();
        console.log("create 1");
        setInterval(() => {
            console.log("create 2");
            GET_CREATE_REZ_REQUEST();
        }, 1000 * 60 * 5); // 5 dakika da bir request
    }
    useEffect(() => {
        INTERVAL_GET_REZ_REQUEST();
    }, [])
    return (
        <div
            className={`tab-pane fade  ${toggleTab === "createrequest" ? "active show" : ""
                }`}
            id="alerts"
            role="tabpanel"
        >
            <div className='mt-4 overflow-90-y'>
                {isLoading ? <div className='my-3 text-center'> <Loading variant='primary'></Loading> </div> : createRequest.map((item, i) => (
                    <RezEvaluate removeItem={SET_REMOVE_ITEM} type={1} key={i} variant="success" icon="fa-check" item={item}></RezEvaluate>
                )

                )}
            </div>
            <Modal centered className="fade" onHide={() => setShowModal({ status: false })} show={showModal.status}>
                <Modal.Header>
                    <Modal.Title> <i className='flaticon-381-warning'></i> Mesaj</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setShowModal({ status: false })}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <h1 className='mb-4'> <i className={`text-${showModal.variant} fas fa-${showModal.icon}`} style={{ fontSize: '72px' }}></i> </h1>
                        <div className='alert alert-secondary'>{showModal.message}</div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRequest)