import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ChooseCompany from '../../pages/ChooseCompany'
import Checking from '../../components/shared/checking'
import { SET_ACTIVE_COMPANY, GET_REMAIN_INFO_OF_ACTIVE_COMPANY, SET_REMOVE_COMPANY } from '../../store/module/company/action'
import { withRouter } from 'react-router-dom'
// bu sayfa firma bilgilerini api den çekilir. Apiden çekilirken kullanıcı x firmasına bağlanıyorsunuz gösterilir.
// eğer firma api bağlantısı başarılı dönerse paket kontrolü adımına gönderilir.
export const CheckActiveCompany = (props) => {
    const companies = props.activeUser.Companies;
    useEffect(() => {
        //  props.SET_REMOVE_COMPANY()
        if (companies.length === 1) {
            props.SET_ACTIVE_COMPANY(companies[0], props.history)
            props.GET_REMAIN_INFO_OF_ACTIVE_COMPANY(companies[0], props.history);
            // serverden server saati istenir. Sebebi: paket süresi kontrolü için gereklidir. Her f5 yapıldığında süre yenilenir.
            const date = new Date(props.activeUser?.User?.CurrentDate).getTime() || 0;
            // active olan firmanın paket süresi dolmuş mu kontrolü yapılır. 
            const expiredAt = new Date(companies[0].PackageFinishedAt).getTime();
            if (expiredAt < date) {
                 props.history.push(`/checking-package/${companies[0].Id}`)
            } else {
                 props.history.push(`/${companies[0].Id}`)
            }
        }
    }, [])
    if (props.connected) {
        return (
            <Checking description="Firma bilgisi alınıyor..."></Checking>
        )
    }
    else if (props.activeCompany.status) {
        return (
            <div>{props.activeCompany.Name}</div>
        )
    } else {
        return (
            <ChooseCompany></ChooseCompany>
        )
    }
}

const mapStateToProps = (state) => ({
    activeCompany: state.company.activeCompany,
    connected: state.company.connectedCompany,
    connectedRemain: state.company.connected,
    activeUser: state.user.activeUser
})

const mapDispatchToProps = { SET_ACTIVE_COMPANY, GET_REMAIN_INFO_OF_ACTIVE_COMPANY, SET_REMOVE_COMPANY }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckActiveCompany))