import React, { useState } from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Components
import Workers from "../components/main-components/chatBox/Workers";
import CancelRequest from "../components/main-components/chatBox/CancelRequest";
import CreateRequest from "../components/main-components/chatBox/CreateRequest";
import { Link } from "react-router-dom";

const ChatBox = ({ onClick, toggle }) => {
   const [toggleTab, settoggleTab] = useState(
      window.location.hash.slice(1) ? window.location.hash.slice(1) : "createrequest"
   );
   const [lengthCancel, setLengthCancel] = useState(0);
   const [lengthCreate, setLengthCreate] = useState(0);

   const dataToggle = [
      { href: "#createrequest", name: "Yeni Talepler", comp: "createrequest", length: lengthCreate },
      { href: "#cancelrequest", name: "İptal Talepleri", comp: "cancelrequest", length: lengthCancel },
   ];

   return (
      <div className={`chatbox ${toggle === "chatbox" ? "active" : ""}`}>
         <div className="chatbox-close" onClick={() => onClick()}></div>
         <div className="custom-tab-1">
            <ul className="nav nav-tabs">
               {dataToggle.map((data, i) => (
                  <li className="nav-item" key={i}>
                     <Link
                        className={`nav-link ${toggleTab === data.comp.toLocaleLowerCase()
                           ? "active"
                           : ""
                           }`}
                        to="#"
                        data-toggle="tab"
                        href={data.href}
                        onClick={() =>
                           settoggleTab(data.comp.toLocaleLowerCase())
                        }
                     >
                        <small> {data.name} ({data.length})</small>
                     </Link>
                  </li>
               ))}
            </ul>
            <div className="tab-content">
               <CancelRequest
                  PerfectScrollbar={PerfectScrollbar}
                  toggle={toggle}
                  setLength={setLengthCancel}
                  length={lengthCancel}
                  toggleTab={toggleTab}
               />
               <CreateRequest
                  PerfectScrollbar={PerfectScrollbar}
                  toggle={toggle}
                  setLength={setLengthCreate}
                  toggleTab={toggleTab}
                  length={lengthCreate}
               />
               <Workers
                  PerfectScrollbar={PerfectScrollbar}
                  toggle={toggle}
                  toggleTab={toggleTab}
               />
            </div>
         </div>
      </div>
   );
};

export default ChatBox;
