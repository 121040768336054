export function SET_PAYMENT_METHODS_LIST(payload) {
    return {
        type: 'SET_PAYMENT_METHODS_LIST',
        payload
    }
}
export function SET_PAYMENT_METHODS_LOADING(payload) {
    return {
        type: 'SET_PAYMENT_METHODS_LOADING',
        payload
    }
}
export function SET_ALERT_BOX(payload) {
    return {
        type: 'SET_ALERT_BOX',
        payload
    }
}
export function SET_REMOVE_PAYMENT_METHODS_LIST(payload) {
    return {
        type: 'SET_REMOVE_PAYMENT_METHODS_LIST',
        payload
    }
}
export function SET_UPDATE_PAYMENT_METHODS_LIST(payload) {
    return {
        type: 'SET_UPDATE_PAYMENT_METHODS_LIST',
        payload
    }
}
export function SET_INSERT_PAYMENT_METHODS_LIST(payload) {
    return {
        type: 'SET_INSERT_PAYMENT_METHODS_LIST',
        payload
    }
}

function SET_UPDATE_MAIN_LIST() {
    return (dispatch) => {
        dispatch(SET_PAYMENT_METHODS_LOADING({ key: 'isLoading', value: true }))
        setTimeout(() => {
            dispatch(SET_PAYMENT_METHODS_LOADING({ key: 'isLoading', value: false }))
        }, 1000);
    }
}

export function GET_PAYMENT_METHODS_LIST() {
    return async (dispatch) => {
        dispatch(SET_PAYMENT_METHODS_LOADING({ key: 'isLoading', value: true }))
        const response = await window.$api.get(`PaymentMethods/GetAll/Admin`);
        if (response.message === 'OK' && response.result === 'OK') {
            if (response.data === null) dispatch(SET_PAYMENT_METHODS_LIST([]))
            else dispatch(SET_PAYMENT_METHODS_LIST(response.data))
        } else
            dispatch(SET_PAYMENT_METHODS_LIST([]))
        dispatch(SET_PAYMENT_METHODS_LOADING({ key: 'isLoading', value: false }))

    }
}
export function SET_UPDATE_PAYMENT_METHODS(item, index) {
    return async (dispatch) => {
        dispatch(SET_PAYMENT_METHODS_LOADING({ key: 'isLoadingUpdate', value: true }))

        const response = await window.$api.put(`PaymentMethods/${item.Id}`, item);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_UPDATE_PAYMENT_METHODS_LIST({
                status: 'update',
                variant: 'success',
                message: `Tebrikler! ${item.BankName} ödeme yönteminiz başarıyla güncelledik.`,
                data: item,
                index
            }))
            setTimeout(() => {
                dispatch(SET_UPDATE_MAIN_LIST())
                dispatch(SET_PAYMENT_METHODS_LOADING({ key: 'isLoadingUpdate', value: false }))
            }, 2500);
        } else
            dispatch(SET_UPDATE_PAYMENT_METHODS_LIST({
                status: 'update',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Ödeme yönteminizi güncelleyemedik, lütfen bir süre sonra tekrar deneyin..`,
                data: item,
                index
            }))


    }
}
export function SET_INSERT_PAYMENT_METHODS(item,) {
    return async (dispatch, getState) => {
        const state = getState()
        dispatch(SET_PAYMENT_METHODS_LOADING({ key: 'isLoadingInsert', value: true }))

        const response = await window.$api.post(`PaymentMethods/Create`, item);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_INSERT_PAYMENT_METHODS_LIST({
                status: 'insert',
                variant: 'success',
                message: `Tebrikler! ${item.BankName} ödeme yönteminiz başarıyla eklendi.`,
                data: { ...item, Id: response.data },
            }))
            setTimeout(() => {
                dispatch(SET_UPDATE_MAIN_LIST())
                dispatch(SET_PAYMENT_METHODS_LOADING({ key: 'isLoadingInsert', value: false }))
            }, 2500);
        } else
            dispatch(SET_INSERT_PAYMENT_METHODS_LIST({
                status: 'insert',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Ödeme yönteminizi ekleyemedik, lütfen bir süre sonra tekrar deneyin..`,
                data: item,
            }))


    }
}
export function SET_REMOVE_PAYMENT_METHODS(index, id) {
    return async (dispatch, getState) => {
        const state = getState()
        dispatch(SET_PAYMENT_METHODS_LOADING({ key: 'isLoadingRemove', value: true }))

        const response = await window.$api.delete(`PaymentMethods/${id}`);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_REMOVE_PAYMENT_METHODS_LIST({
                status: 'remove',
                variant: 'success',
                message: `Tebrikler! Ödeme yönteminizi başarıyla kaldırdık..`,
                data: { index, id },
            }))
            setTimeout(() => {
                dispatch(SET_UPDATE_MAIN_LIST())
                dispatch(SET_PAYMENT_METHODS_LOADING({ key: 'isLoadingRemove', value: false }))
            }, 2500);
        } else
            dispatch(SET_REMOVE_PAYMENT_METHODS_LIST({
                status: 'remove',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Ödeme yönteminizi kaldıramadık, lütfen bir süre sonra tekrar deneyin..`,
                data: { index: -1, }
            }))


    }
}