import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import RoomDetail from '../components/main-components/Rooms/RoomDetail'

export const RoomDetailPage = () => {
  const roomId = useParams().roomId;
  return (
    <RoomDetail key={roomId}></RoomDetail>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RoomDetailPage)