const INITIAL_STATE = {
    workers: [],
    isLoadingAction: false,
    isLoading: false,
    alertBox: {
        status: false,
        message: '',
        variant: 'success'
    }
}
export const WorkersReducer = (state = INITIAL_STATE, action) => {
    const workers = state.workers;
    switch (action.type) {
        case 'SET_WORKER_LIST':
            return { ...state, workers: action.payload, isLoading: false }
        case 'SET_LOADING_WHEN_INSERTING':
            return { ...state, isLoadingAction: action.payload }
        case 'SET_LOADING_WORKER':
            return { ...state, isLoading: action.payload }
        case 'SET_THAT_WORKER_OF_INSERTED_TO_LIST':
            if (action.payload.alertBox.status && action.payload.alertBox.variant === 'success')
                workers.push(action.payload.data)
            return { ...state, workers, alertBox: action.payload.alertBox }
        case 'SET_THAT_WORKER_OF_UPDATED_TO_LIST':
            if (action.payload.alertBox.status && action.payload.alertBox.variant === 'success') {
                const p = workers.findIndex(w => w.Id === action.payload.data.Id);
                if (p !== -1)
                    workers[p] = action.payload.data;
            }

            return { ...state, workers, alertBox: action.payload.alertBox }
        case "SET_REMOVE_WORKER_LIST":
            if (action.payload.Id !== -1) {
                const index = workers.findIndex(l => l.Id === action.payload.data.id)
                console.log("worker is deleting", workers, action.payload, index);
                workers.splice(index, 1);
            }
            return { ...state, workers: workers, isLoadingAction: false, alertBox: { status: 'remove', message: action.payload.message, variant: action.payload.variant } }

        case 'SET_ALERT_BOX':
            return { ...state, alertBox: action.payload }
        default:
            return state
    }
}