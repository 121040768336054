import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import Loading from '../../shared/loading'
import NoFound from '../../shared/NoFound'
export const PackagesOfCustomerList = (props) => {
    const [customerOfPackageList, setCustomerOfPackageList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        if (props.SelectedProcessing.length === 0 || props.SelectedProcessing === null) return
        else {
            GET_CUSTOMER_PACKAGES()
        }
    }, [props.SelectedProcessing])
    async function GET_CUSTOMER_PACKAGES() {
        setCustomerOfPackageList([]);
        props.SetSelectedPackage({ Id: -1 })
        setIsLoading(true);
        const response = await window.$api.get(`PackagesOfCustomers/GetAll?isCompleted=1&currency=${props.currency}&customerId=${props.CustomerItem.Id}&processFilter=${props.SelectedProcessing.map(p => p.Id).join(',')}`)
        if (response.message === 'OK') {
            setCustomerOfPackageList(response.data || []);
        } else
            setCustomerOfPackageList([]);
        setIsLoading(false);
    }
    return (
        <div className='mt-5'>
            {
                isLoading ? <div className='text-center'> <Loading variant='primary'></Loading></div> :
                    customerOfPackageList.length === 0 ? <NoFound></NoFound> :
                        <div>
                            <h4 className='mb-4 d-flex justify-content-between align-items-center'>Müşterinin Sahip Olduğu Paketler
                                {
                                    props.SelectedPackage.Id !== -1 ? <button onClick={() => props.SetSelectedPackage({ Id: -1 })} className={`btn btn-sm float-right btn-primary btn-sm`}>
                                        Seçimi Kaldır
                                    </button> : ''
                                }
                            </h4>
                            {
                                props.SelectedProcessing.length === 0 || props.SelectedProcessing === null ? <div className='my-4 alert alert-secondary text-center'>Kullanıcı paketlerini görebilmeniz için hizmet seçmelisiniz.</div> :
                                    <div className='row'>
                                        {
                                            customerOfPackageList.map((item, index) => (
                                                <div className='col-12 col-md-6 col-lg-4'>
                                                    <div className={`card ${props.SelectedPackage.Id === item.Id ? 'bg-info-light' : ''}`} key={index}>
                                                        <div className='card-body text-center'>
                                                            <h6>{item.Detail}</h6>
                                                            <h2 className='text-primary'>{item.RemainCount}</h2>
                                                            <p>adet kaldı.</p>
                                                            <div className='mt-4'>
                                                                <button disabled={props.SelectedPackage.Id === item.Id} onClick={() => props.SetSelectedPackage(item)} className={`btn w-100 btn-${props.SelectedPackage.Id === item.Id ? 'success' : 'secondary'} btn-sm`}>
                                                                    {props.SelectedPackage.Id === item.Id ? <i className='fas fa-check-circle'></i> : 'Paketi Kulan'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                            }
                        </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PackagesOfCustomerList)