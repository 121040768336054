import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
//import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./assets/context/ThemeContext.js";

ReactDOM.render(
	<>
		<Provider store={store}>
			<HashRouter basename="/app">
				<ThemeContext>
					<App />
				</ThemeContext>
			</HashRouter>
		</Provider>
	</>,
	document.getElementById("root")
);

