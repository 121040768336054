import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Checking from '../components/shared/checking'
import { SET_ACTIVE_COMPANY, GET_REMAIN_INFO_OF_ACTIVE_COMPANY, } from '../store/module/company/action'
import { IntlDateTime } from '../plugins/SET_DATE_FORMATTER'
import { SET_LOGOUT } from '../store/module/users/action'
import { Redirect } from 'react-router-dom'
export const ChooseCompany = (props) => {
    useEffect(() => {
        const p = props.activeUser.Companies.map(c => String(c.Id)).indexOf(localStorage.getItem('company'))
        if (p !== -1) {
            const date = new Date(props.activeUser.User.CurrentDate).getTime(); // serverdan gelmeli
            const packageDate = new Date(props.activeUser.Companies[p].PackageFinishedAt).getTime();
            if (date < packageDate) {
                props.history.push(`/${localStorage.getItem('company')}`)
            } else {
                props.history.push(`/checking-package/${localStorage.getItem('company')}`)

            }
            localStorage.setItem('company', localStorage.getItem('company'));
            props.SET_ACTIVE_COMPANY(props.activeUser.Companies[p], props.history)
            if (props.activeUser.Companies[p].IsVerify === undefined)
                props.SET_LOGOUT(props.history, "İşyerinizin onayına ulaşamadık. Lütfen tekrar giriş yapmayı deneyin.", "danger");
            else if (props.activeUser.Companies[p].IsVerify === 1)
                props.GET_REMAIN_INFO_OF_ACTIVE_COMPANY(props.activeUser.Companies[p], props.history)

        }
    }, [])
    function SET_ACTIVE_COMPANY_IN_COMP(company) {
        props.SET_ACTIVE_COMPANY(company, props.history)
        if (company.IsVerify === undefined)
            props.SET_LOGOUT(props.history, "İşyerinizin onayına ulaşamadık. Lütfen tekrar giriş yapmayı deneyin.", "danger");
        else if (company.IsVerify === 1)
            props.GET_REMAIN_INFO_OF_ACTIVE_COMPANY(company, props.history)
        localStorage.setItem('company', company.Id);
        SET_LINK(company)
    }
    function SET_LINK(company) {
        const date = new Date(props.activeUser.User.CurrentDate).getTime();
        const expiredAt = new Date(company.PackageFinishedAt).getTime(); //new Date(company.expiredAt).getTime();
        if (date < expiredAt) {
            return `${company.Id}`
        } else
            return `checking-package/${company.Id}`
    }
    function SET_COMPANY_PACKAGE_FINISHED_AT(company) {
        const date = new Date(props.activeUser.User.CurrentDate).getTime();
        const expiredAt = new Date(company.PackageFinishedAt).getTime(); //new Date(company.expiredAt).getTime();
        if (date < expiredAt) {
            return (<div>
                <div className='text-center'><small className='text-secondary'>Paket Bitiş Tarihi</small></div>
                <span className='badge badge-secondary'>{IntlDateTime(expiredAt)}</span></div>)
        } else
            return (<span className='badge badge-danger'>Paket süreniz dolmuş!</span>)
    }
    const companies = props.activeUser.Companies
    const userCompanyId = props.activeUser.User.CompanyId
    let date = new Date().getTime(); // serverdan gelmeli
    let packageDate = new Date(props.activeCompany.PackageFinishedAt).getTime();
    if (companies.length === 1) { // eğer 1 tane firma bağlı ise otomatik firmaya bağlan
        date = new Date(props.activeUser.User.CurrentDate).getTime();
        packageDate = new Date(companies[0].PackageFinishedAt).getTime();
        if (packageDate < date) {
            <Redirect to={`/checking-package/${companies[0].Id}`}></Redirect>
        } else {
            <Redirect to={`/${companies[0].Id}`}></Redirect>
        }
    }
    else if (userCompanyId > 1 && companies.length < 1) { // eğer kullanıcının kayıtlı firması yoksa ve userCompanyId'si varsa otomatik sayfayı aç.
        <Redirect to={`/checking-package/${userCompanyId}`}></Redirect>
    }
    // birden fazla firma mevcut ise firma seçimi ekranını göster
    return (
        <div className='checking-container'>
            <div className='container'>
                <h2 className='text-white'>
                    <i className='fa fa-arrow-right'></i>
                    Firmanı Seç</h2>
                <p className='text-white'>Hangi firmanın rezzervasyonlarını görmek istersiniz? Seçtiğiniz firmanın rezzervasyon yönetim paneline yönlendirileceksiniz.</p>

                <div className='row justify-content-center align-items-center'>
                    {companies.map(function (company, i) {
                        return (<div key={i} className='col-6 col-lg-4 col-xl-3 my-4'>
                            <div className='choose-company-card card'>
                                <img src={window.$api.image(company.ImagePath)}></img>
                                {SET_COMPANY_PACKAGE_FINISHED_AT(company)}
                                <p>{company.Name}</p>
                                <div>
                                    <button onClick={() => SET_ACTIVE_COMPANY_IN_COMP(company)} className='btn btn-secondary btn-sm'>Firmaya Git</button></div>
                            </div>
                        </div>)
                    })}
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    activeUser: state.user.activeUser,
    activeCompany: state.company.activeCompany
})

const mapDispatchToProps = { SET_ACTIVE_COMPANY, GET_REMAIN_INFO_OF_ACTIVE_COMPANY, SET_LOGOUT }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseCompany))