import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { theme } from '../../../plugins/select.theme'

export const ProcessingSelectBox = (props) => {
    const [valueLoading, setValueLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setValueLoading(false);
        }, 250);
    }, [])
    function GUESS_PROCESSING_DURATION(e) {
        return e.map(function (a) { return a.Duration; })
            .reduce(function (a, b) { return a + b; })
    }
    function GUESS_PROCESSING_PRICE(e) {
        return e.map(function (a) { return a.PriceTRY; })
            .reduce(function (a, b) { return a + b; }).toFixed(2)
    }
    return (
        <div className='form-group my-4'>
            <label>{props.label}</label>
            {
                !valueLoading ?
                    <Select
                        value={props.ProcessesData}
                        defaultValue={props.ProcessesData}
                        placeholder={props.placeholder}
                        isMulti={true}
                        name="Fullname"
                        isLoading={props.ProcessesareLoading}
                        isSearchable={false}
                        onChange={props.onChange}
                        options={props.processList}
                        getOptionLabel={(option) => <div>    <i className="flaticon-041-graph"></i> {option.Name} ({option.Duration}dk.)</div>}
                        getOptionValue={(val) => val.Id}
                        theme={theme}
                    >
                    </Select> : ''
            }
            {
                props.ProcessesData !== null && props.ProcessesData.length !== 0 ?
                    <div>
                        <div className='alert alert-secondary mt-3'>
                            Seçmiş olduğunuz hizmetler ile işlemleriniz toplam süresi <strong className='text-success'>{GUESS_PROCESSING_DURATION(props.ProcessesData)}dk</strong> olacaktır.
                        </div>
                        <div className='alert alert-secondary mt-3'>
                            Seçmiş olduğunuz hizmetler ile işlemleriniz toplam ücreti <strong className='text-success'>₺{GUESS_PROCESSING_PRICE(props.ProcessesData)}</strong> olacaktır.
                        </div>
                        <div className='mt-2'><small>* Aşağıdan <span className='text-primary'>süreyi</span> ve <span className='text-primary'>ücretini</span> değiştirebilirsiniz.</small></div>
                        <div> <small>Hizmet sürelerini ve ücretlerini <Link className='text-primary' to={`/${props.company.Id}/process`} >'Hizmetlerim'</Link> sayfasından değiştirebilirsiniz.</small></div>
                    </div> : <div className='mt-2 text-danger'><small>* En az 1 adet hizmet seçmeniz gerekmektedir.</small></div>

            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    processList: state.processing.processingList,
    company: state.company.activeCompany,
    ProcessesareLoading: state.processing.isLoading,
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingSelectBox)