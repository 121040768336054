import React from 'react'
import { connect } from 'react-redux'
import InsertCustomerPackages from './components/InsertCustomerPackages'
import ListOfCustomerPackages from './components/ListOfCustomerPackages'

export const Processing = (props) => {

  return (
    <div className='container-fluid'>
      <div className='row justify-content-center align-items-start'>
        {
          props.activeUser.RoleId === 4 ? '' :
            <div className='col-12 col-lg-6 col-xl-5'>
              <InsertCustomerPackages></InsertCustomerPackages>
            </div>
        }
        <div className={`col-12 col-lg-${props.activeUser.RoleId === 4 ? '12':'6'} col-xl-${props.activeUser.RoleId === 4 ? '12':'7'}`}>
          <ListOfCustomerPackages></ListOfCustomerPackages>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  activeUser: state.user.activeUser.User
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Processing)