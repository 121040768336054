import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap';
import ReservationChart from './ReservationChart';
import Loading from '../../../shared/loading';

export const ResarvationStats = (props) => {
    return (
        <div className="card">
            <div className="card-header border-0 d-sm-flex d-block">
                <div className="me-auto mb-sm-0 mb-3">
                    <h4 className="card-title mb-2">Rezzervasyon İstatistiklerim</h4>
                    <span>Son 15 gün rezzervasyon istatistikleri </span>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="d-flex me-5">
                        <h3 className="mb-0 me-2">
                            {
                                props.companyStats.status ?
                                    props.companyStats.TotalRezCount
                                    : <Loading size="sm"></Loading>

                            }
                        </h3>
                        <span>Rezzervasyonlarım</span>
                    </div>
                    <div className="d-flex me-3">
                        <h3 className="mb-0 me-2">{props.rooms.length}</h3>
                        <span>Oda</span>
                    </div>
                    <Dropdown className="dropdown">
                        <Dropdown.Toggle as="div" className="btn-link i-false" >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item className="dropdown-item">15 Günlük</Dropdown.Item>
                            <Dropdown.Item className="dropdown-item">Aylık</Dropdown.Item>
                            <Dropdown.Item className="dropdown-item">Yıllık</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="card-body">
                <div id="reservationChart" className="reservationChart">
                    <ReservationChart />
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = (state) => ({
    rooms: state.rooms.roomsList,
    companyStats: state.company.companyStats

})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ResarvationStats)