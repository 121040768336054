import React from 'react'
import { connect } from 'react-redux'

export const Index = (props) => {
    return (
        <div>
            <div className='card'>
                <div className='card-body text-center'>Destek Soru-Cevap kısmı buraya çok yakında eklenecektir.</div>
                {/* <div className='card-title p-4'>Çalışanlarımı Nasıl Sisteme Ekleyebilirim?</div>
                <div className='card-title p-4'>Çalışanların Yetkileri Nasıl Çalışıyor?</div>
                <div className='card-title p-4'>Sisteme Müşteri Nasıl Ekleyebilirim?</div>
                <div className='card-title p-4'>Sisteme Oda Nasıl Ekleyebilirim?</div>
                <div className='card-title p-4'>Hizmetlerim Nedir?</div>
                <div className='card-title p-4'>Sisteme Hizmet Nasıl Ekleyebilirim?</div>
                <div className='card-title p-4'>Müşteri Etiketleri Nedir?</div>
                <div className='card-title p-4'>Müşteri Etiketleri Nasıl Ekleyebilirim?</div>
                <div className='card-title p-4'>Yeni Şube Açmak İstiyorum Ne Yapmalıyım?</div>
                <div className='card-title p-4'>Rezzervayon Nasıl Oluşturabilirim?</div>
                <div className='card-title p-4'>Rezzervasyon Nasıl Güncelleyebilirim?</div>
                <div className='card-title p-4'>İleri Tarihli Rezzervasyon Yapabilir Miyim?</div>
                <div className='card-title p-4'>Rezzervasyon İptal Edebilir Miyim?</div>
                <div className='card-title p-4'>Geçmiş Tarihe Rezzervasyon Oluşturabilir Miyim?</div>
                <div className='card-title p-4'>Rezzervasyon SMS'i Nasıl Gönderiliyor?</div>
                <div className='card-title p-4'>Rezzervasyon İptal Edildiğinde SMS gönderiliyor mu?</div>
                <div className='card-title p-4'>Paket Sürem Bitiyor Ne Yapmalıyım?</div> */}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    roomsList: state.rooms.roomsList,
    processingList: state.processing.processingList,
    workerList: state.workers.workers,
    remainOfActiveCompany: state.company.remainOfActiveCompany,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)