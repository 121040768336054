import axios from 'axios';
import { INITIAL_STATE } from '../../store/module/company/reducer'
export default class {

    constructor(settings = {
        errorStatus: [],
        loginUrl: '',
        loginRouterName: 'login',
        bearerToken: false,
        baseUrl: '',
        token: 'FAIL'
    }, history = {}, dispatch = {}) {
        this.dispatch = dispatch;
        this.router = history;
        this.token = localStorage.getItem('token') || 'FAIL';
        this.basename = "/app"
        this.BASE_URL = settings.baseUrl ? settings.baseUrl : '';
        this.errorStatus = settings.errorStatus ? settings.errorStatus : [] // must be array
        this.loginUrl = settings.loginUrl ? settings.loginUrl : ''
        this.loginRouterName = settings.loginRouterName ? settings.loginRouterName : 'login'
        this.bearerToken = settings.bearerToken ? settings.bearerToken : false
    }
    dataMethod = (data) => {
        if (data === undefined) return JSON.stringify(data)
        else if (typeof data.append === 'undefined') return JSON.stringify(data)
        else return data
    };
    get = async (url, data, withToken = true) => await this.request(this.config(url, this.dataMethod(data), withToken, 'get'))
    post = async (url, data, withToken = true) => await this.request(this.config(url, this.dataMethod(data), withToken,))
    put = async (url, data, withToken = true) => await this.request(this.config(url, this.dataMethod(data), withToken, 'put'))
    delete = async (url, data, withToken = true) => await this.request(this.config(url, this.dataMethod(data), withToken, 'delete'))
    login = async (data) => await this.request(this.config(this.loginUrl, this.dataMethod(data), false), true)
    token = () => localStorage.getItem('token') || 'FAIL'
    image = (data) => {
        if (!data) return ''
        if (data?.includes(this.BASE_URL)) return data
        else if (data.includes("base64")) return data
        return this.BASE_URL + data
    };
    request = (config) => {
        const vm = this
        return axios(config)
            .then(function (response) {
                return { result: 'OK', ...response.data }
            })
            .catch(function (error) {
                const err = JSON.parse(JSON.stringify(error))
                if (err.message === "Network Error") {
                    // window.location.href = "/app/login"
                    return { result: 'FAIL' }
                } if (vm.errorStatus.includes(err.status) || !error.response.data.success) {
                }
                return { ...error.response.data.error, result: 'FAIL' }
            });
    }
    config = (url, data, withToken = true, method = 'post') => {
        let token = localStorage.getItem('companyToken') ? localStorage.getItem('companyToken') : localStorage.getItem('token');
        if (token) if (token.includes('"')) token = token.replace(/"/g, '');
        const BASE_URL = `${this.BASE_URL}`
        let header = {
            'Content-Type': 'application/json'
        }
        if (withToken && localStorage.getItem('token')) {
            header = {
                'Content-Type': 'application/json',
                Authorization: `${this.bearerToken ? 'Bearer ' : ''}${token}`
            }
        } else if (withToken && window.token === 'FAIL') {
            this.router.push({ name: this.loginRouterName })
        }
        return {
            method,
            url: `${BASE_URL}${url}`,
            headers: header,
            data
        };
    }
}
