import React from 'react'
import { Pagination } from 'react-bootstrap';

function pagination(props) {
    const items = [];
    for (let number = 1; number <= props.totalPage; number++) {
        items.push(
            <Pagination.Item onClick={() => props.changedPage(number)} key={number} active={number === props.page}>
                {number}
            </Pagination.Item>
        );
    }
    return (
        <div>
            <Pagination
                className={`mt-4 pagination-primary`}
            >
                <li className="page-item page-indicator">
                    <a onClick={() => props.changedPage(props.page - 1)} className="page-link" href="javascript:void(0">
                        <i className="la la-angle-left" />
                    </a>
                </li>
                {items}
                <li className="page-item page-indicator">
                    <a onClick={() => props.changedPage(props.page + 1)} className="page-link" href="javascript:void(0">
                        <i className="la la-angle-right" />
                    </a>
                </li>
            </Pagination>
        </div>
    )
}

export default pagination