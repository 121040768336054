import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { GET_CUSTOMER_PACKAGES } from '../../../store/module/customerPackages/action';
import Loading from '../../shared/loading';
import CustomerCard from './CustomerCard';
import PackagesOfCustomerDetail from './PackagesOfCustomerDetail';
import RezzervationsOfCustomer from './RezzervationsOfCustomer';

export const Index = (props) => {
    const params = useParams();
    const customerId = Number(params.customerId)
    const [customer, setCustomer] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    async function GET_CUSTOMER() {
        setIsLoading(true);
        const response = await window.$api.get(`Customers/${customerId}`);
        console.log(response);
        if (response.message === 'OK' && response.result === 'OK') {
            setCustomer(response.data);
        } else {
            history.push('/error');
        }
        setIsLoading(false);
    }
    useEffect(() => {
        if (props.alertBox.status && props.alertBox.variant === 'success') {
            setTimeout(() => {
                GET_CUSTOMER();
            }, 1500);
        }
    }, [props.alertBox.status])
    useEffect(() => {
        GET_CUSTOMER();
    }, [])
    return (
        <div>
            {
                isLoading ? <div className='text-center my-4'><Loading variant='primary'></Loading></div> :
                    <div>
                        <CustomerCard updateCustomer={GET_CUSTOMER} customer={customer}></CustomerCard>
                        <RezzervationsOfCustomer Customer={customer} customerId={customerId}></RezzervationsOfCustomer>
                        <PackagesOfCustomerDetail user={props.customer} count={props.count} GET_CUSTOMER_PACKAGES={props.GET_CUSTOMER_PACKAGES} customerPackages={props.customerPackages} isLoading={props.isLoading} customer={customer}></PackagesOfCustomerDetail>
                    </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    alertBox: state.customers.alertBox,
    customerPackages: state.customerPackages.customerPackagesList,
    company: state.company.activeCompany,
    isLoading: state.customerPackages.isLoading,
    count: state.customerPackages.count
})

const mapDispatchToProps = { GET_CUSTOMER_PACKAGES }

export default connect(mapStateToProps, mapDispatchToProps)(Index)