export const INITIAL_STATE = {
    customerPackagesList: [],
    count: 0,
    isLoading: true,
    isLoadingUpdate: false,
    isLoadingInsert: false,
    isLoadingRemove: false,
    alertBox: { status: 'update', message: '', variant: '', }
}
export const CustomerPackagesReducer = (state = INITIAL_STATE, action) => {
    const list = state.customerPackagesList;
    switch (action.type) {
        case 'SET_CUSTOMER_PACKAGES':
            return { ...state, customerPackagesList: action.payload, isLoading: false }
        case 'SET_CUSTOMER_COUNT':
            return { ...state, count: action.payload }
        case 'SET_CUSTOMER_LOADING':
            return { ...state, [action.payload.key]: action.payload.value }
        case "SET_UPDATE_CUSTOMER_PACKAGES_LIST":
            list[action.payload.index] = { ...action.payload.data }
            return { ...state, customerPackagesList: list, isLoading: false, isLoadingUpdate: false, alertBox: { status: 'update', message: action.payload.message, variant: action.payload.variant } }
        case "SET_INSERT_CUSTOMER_PACKAGES_LIST":
            if (action.payload.variant === 'success')
                list.push(action.payload.data)
            return { ...state, customerPackagesList: list, isLoadingInsert: false, alertBox: { status: 'insert', message: action.payload.message, variant: action.payload.variant } }

        case "SET_REMOVE_CUSTOMER_PACKAGES_LIST":
            if (action.payload.Id !== -1) {
                const index = list.findIndex(l => l.Id === action.payload.data.id);
                list.splice(index, 1);
            }
            return { ...state, customerPackagesList: list, isLoadingRemove: false, alertBox: { status: 'remove', message: action.payload.message, variant: action.payload.variant } }

        default:
            return state
    }
}