import React from 'react'
import { connect } from 'react-redux'
import AddWorker from '../components/main-components/Workers/AddWorker'

export const AddRoomPage = (props) => {
  return (
    <div className='row justify-content-center'>
      <div className='col-12 col-md-8 col-lg-6 col-xl-6'>
        <div className='card'>
          <div className='card-title pt-4 px-4'>Yeni Çalışan Ekleyin</div>
          <div className='card-body'>
            <p className='alert alert-secondary'>* Firmanızdaki çalışanları buradan ekleyebilirsiniz.
              Eklediğiniz çalışanları bir odaya atayabilirsiniz ve rezzervasyonlarını takip edebilirsiniz.
            </p>
            <p className='alert alert-secondary'>* Firmanıza çalışan olarak "Personel" hesabı da ekleyebilirsiniz. 
            Personel sadece rezzervasyonları görüntüleyebilmekte herhangi bir işlem yapamamaktadır.
            </p>
            <AddWorker></AddWorker>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddRoomPage)