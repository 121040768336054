export function SET_PROCESSING_LIST(payload) {
    return {
        type: 'SET_PROCESSING_LIST',
        payload
    }
}
export function SET_PROCESSING_LOADING(payload) {
    return {
        type: 'SET_PROCESSING_LOADING',
        payload
    }
}
export function SET_ALERT_BOX(payload) {
    return {
        type: 'SET_ALERT_BOX',
        payload
    }
}
export function SET_REMOVE_PROCESSING_LIST(payload) {
    return {
        type: 'SET_REMOVE_PROCESSING_LIST',
        payload
    }
}
export function SET_UPDATE_PROCESSING_LIST(payload) {
    return {
        type: 'SET_UPDATE_PROCESSING_LIST',
        payload
    }
}
export function SET_INSERT_PROCESSING_LIST(payload) {
    return {
        type: 'SET_INSERT_PROCESSING_LIST',
        payload
    }
}

function SET_UPDATE_MAIN_LIST() {
    return (dispatch) => {
        dispatch(SET_PROCESSING_LOADING({ key: 'isLoading', value: true }))
        setTimeout(() => {
            dispatch(SET_PROCESSING_LOADING({ key: 'isLoading', value: false }))
        }, 1000);
    }
}

export function GET_PROCESSING_LIST() {
    return async (dispatch) => {
        const response = await window.$api.get(`Processes/GetAll`);
        if (response.message === 'OK' && response.result === 'OK') {
            if(response.data === null) dispatch(SET_PROCESSING_LIST([]))
            else dispatch(SET_PROCESSING_LIST(response.data))
        } else
            dispatch(SET_PROCESSING_LIST([]))
    }
}
export function SET_UPDATE_PROCESSING(item, index) {
    return async (dispatch) => {
        dispatch(SET_PROCESSING_LOADING({ key: 'isLoadingUpdate', value: true }))

        const response = await window.$api.put(`Processes/${item.Id}`, item);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_UPDATE_PROCESSING_LIST({
                status: 'update',
                variant: 'success',
                message: `Tebrikler! ${item.Name} ürününüz başarıyla güncelledik.`,
                data: item,
                index
            }))
            setTimeout(() => {
                dispatch(SET_UPDATE_MAIN_LIST())
                dispatch(SET_PROCESSING_LOADING({ key: 'isLoadingUpdate', value: false }))
            }, 2500);
        } else
            dispatch(SET_UPDATE_PROCESSING_LIST({
                status: 'update',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Hizmetinizi güncelleyemedik, lütfen bir süre sonra tekrar deneyin..`,
                data: item,
                index
            }))


    }
}
export function SET_INSERT_PROCESSING(item,) {
    return async (dispatch, getState) => {
        const state = getState()
        dispatch(SET_PROCESSING_LOADING({ key: 'isLoadingInsert', value: true }))

        const response = await window.$api.post(`Processes/Create`, item);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_INSERT_PROCESSING_LIST({
                status: 'insert',
                variant: 'success',
                message: `Tebrikler! ${item.Name} ürününüz başarıyla eklendi.`,
                data: {...item, Id: response.data},
            }))
            setTimeout(() => {
                dispatch(SET_UPDATE_MAIN_LIST())
                dispatch(SET_PROCESSING_LOADING({ key: 'isLoadingInsert', value: false }))
            }, 2500);
        } else
            dispatch(SET_INSERT_PROCESSING_LIST({
                status: 'insert',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Hizmetinizi ekleyemedik, lütfen bir süre sonra tekrar deneyin..`,
                data: item,
            }))


    }
}
export function SET_REMOVE_PROCESSING(index, id) {
    return async (dispatch, getState) => {
        const state = getState()
        dispatch(SET_PROCESSING_LOADING({ key: 'isLoadingRemove', value: true }))

        const response = await window.$api.delete(`Processes/${id}`);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_REMOVE_PROCESSING_LIST({
                status: 'remove',
                variant: 'success',
                message: `Tebrikler! Hizmetinizi başarıyla kaldırdık..`,
                data: {index, id},
            }))
            setTimeout(() => {
                dispatch(SET_UPDATE_MAIN_LIST())
                dispatch(SET_PROCESSING_LOADING({ key: 'isLoadingRemove', value: false }))
            }, 2500);
        } else
            dispatch(SET_REMOVE_PROCESSING_LIST({
                status: 'remove',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Hizmetinizi kaldıramadık, lütfen bir süre sonra tekrar deneyin..`,
                data: { index: -1,}
            }))


    }
}