import React, { useState, useEffect, useRef } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr'
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
//Import
import "react-datepicker/dist/react-datepicker.css";
import Statistics from './components/Statistics';
import DailyResarvationStatistic from './components/DailyResarvationStatistic';
import RemainPackageOfCompany from './components/RemainPackageOfCompany.js';
import ResarvationStats from './components/ResarvationStats';
import Room from '../Rooms/Room';

import { connect } from 'react-redux';
import { GET_COMPANY_STATS } from '../../../store/module/company/action';
import { JustDate } from '../../../plugins/SET_DATE_FORMATTER';
import NoFound from '../../shared/NoFound';
import Loading from '../../shared/loading';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
const AnalyticsDonut = loadable(() =>
	pMinDelay(import("./components/AnalyticsDonut"), 1000)
);



const Home = (props) => {
	const [date, setDateRange] = useState(null);
	const [gridCount, setGridCount] = useState(6);
	const [activeRoom, setActiveRoom] = useState(-1);
	const [showPrice, setShowPrice] = useState(true);
	const [roomConnected, setRoomConnected] = useState(false);
	const [CurrentDate, setCurrentDate] = useState(null);
	const dateRef = useRef(null);
	useEffect(() => {
		registerLocale('tr', tr)
		if (localStorage.getItem('price')) setShowPrice(true)
		const serverDate = props.activeUser.CurrentDate.split('T')[0];
		if (localStorage.getItem('date')) {
			setCurrentDate(JustDate(localStorage.getItem('date')))
			setDateRange(new Date(localStorage.getItem('date')));
			setTimeout(() => {
				setRoomConnected(true)
			}, 250);
		} else {
			setCurrentDate(JustDate(serverDate))
			setTimeout(() => {
				setRoomConnected(true)
			}, 250);
		}
		if (localStorage.getItem('activeRoom')) {
			if (Number(localStorage.getItem('activeRoom')) !== -1)
				setGridCount(12);
			setActiveRoom(Number(localStorage.getItem('activeRoom')))
		}
		if (!props.companyStats.status)
			props.GET_COMPANY_STATS()
	}, [])
	function SET_SHOW_PRICE() {
		const d = showPrice ? false : true
		console.log(d);
		localStorage.setItem('price', d);
		setShowPrice(d)
	}
	function SET_ACTIVE_ROOM(index) {
		if (index === -1) {
			setGridCount(props.rooms.length > 1 ? 6 : 12);
		} else {
			setGridCount(12);
		}
		setActiveRoom(index)
		localStorage.setItem('activeRoom', index);
	}
	function SET_DATE(day) {
		const date = new Date().setDate(new Date().getDate() + day)
		setCurrentDate(JustDate(date))
		setDateRange(date);
		localStorage.setItem('date', new Date(date).toISOString());
	}
	return (
		<>

			<div className="row">
				<div className='mb-4 d-flex justify-content-between flex-wrap'>
					<button onClick={() => { SET_DATE(-1) }} className='btn my-2 btn-secondary'>
						<i className='flaticon-005-back-arrow'></i>	Dünün Rezzervasyonları</button>
					<button onClick={() => { SET_DATE(0) }} className='btn my-2 btn-primary'>
						<i className='flaticon-006-brightness'></i> Bugünün Rezzervasyonları</button>
					<button onClick={() => { SET_DATE(+1) }} className='btn my-2 btn-info'>
						<i className='flaticon-381-stopwatch-2'></i> Yarının Rezzervasyonları</button>
					<button onClick={() => { SET_DATE(+2) }} className='btn my-2 btn-warning'>
						<i className='flaticon-381-stopwatch-2'></i> {JustDate(new Date().setDate(new Date().getDate() + 2))} Rezzervasyonları</button>
				</div>

				<div className='row align-items-center justify-content-between'>
					<div className='col-12 col-lg-6'>
						<h2 onClick={() => dateRef.current.setOpen(true)} className='rez-input'>{CurrentDate}</h2>
					</div>
					<div className='col-6 col-md-4 col-lg-3'>
						<div>
							<button onClick={() => SET_SHOW_PRICE()} className='btn btn-primary'><i className={`la-money-bill mx-1 la ${gridCount === 12 ? 'active' : ''}`}></i>
								Ücretleri {!showPrice ? 'Göster' : 'Gizle'}
							</button>
						</div>
					</div>
					<div className='col-6 col-md-3'>
						<div>
							<i onClick={() => setGridCount(12)} className={`la-square la  grid-icon ${gridCount === 12 ? 'active' : ''}`}></i>
							{props.rooms.length > 1 ? <i onClick={() => setGridCount(6)} className={`ti-layout-grid2  grid-icon ${gridCount === 6 ? 'active' : ''}`}></i> : ''}
							{props.rooms.length > 2 ? <i onClick={() => setGridCount(4)} className={`ti-layout-grid3  grid-icon ${gridCount === 4 ? 'active' : ''}`}></i> : ''}
							{props.rooms.length > 3 ? <i onClick={() => setGridCount(3)} className={`ti-layout-grid4 grid-icon ${gridCount === 3 ? 'active' : ''}`}></i> : ''}
						</div></div>
					<div className='col-12 col-lg-8'>
						<DatePicker
							dateFormat="dd.MM.yyyy"
							ref={dateRef}
							selected={date}
							className="form-control d-none"
							maxDate={new Date().setDate(new Date().getDate() + 365)}
							calendarStartDay={1}
							locale="tr"
							placeholderText='Rezzervasyon Tarihini Seçin'
							onChange={(update) => {
								setCurrentDate(JustDate(update))
								setDateRange(update);
								localStorage.setItem('date', update);
							}}
							withPortal
						/>
					</div>
				</div>
				<div className='overflow-y-scroll'>
					{
						roomConnected && props.rooms.length > 0 ? <ButtonGroup size='sm'>
							<Button onClick={() => SET_ACTIVE_ROOM(-1)} variant={activeRoom === -1 ? 'info' : 'primary'}>Hepsini Göster</Button>
							{
								props.roomLoading ? <div className='text-center'> <Loading variant='primary' description='Odalar Yükleniyor...'></Loading> </div> :
									props.rooms.length === 0 ?
										'' :
										props.rooms.map((room, i) => (
											<Button variant={activeRoom === i ? 'info' : 'primary'} onClick={() => SET_ACTIVE_ROOM(i)}>{room.Description}</Button>
										))
							}
						</ButtonGroup> : ''
					}
				</div>
				<div className='d-flex mb-4 flex-wrap justify-content-between align-items-center'>
				</div>
				{
					roomConnected ? <div className='row'>
						{
							props.roomLoading ? <div className='text-center'> <Loading variant='primary' description='Odalar Yükleniyor...'></Loading> </div> :
								props.rooms.length === 0 ?
									<div className='my-4'>
										<NoFound description="Oda bulunamadı."></NoFound>
										<div className='mt-4 text-center'>
											<Link to={`/${props.company.Id}/add-room`}> <button className='btn btn-primary btn-sm'>+ Hemen Yeni Oda Oluşturun</button></Link>
										</div>
									</div> :
									props.rooms.map((room, i) => {
										if (((i === activeRoom && activeRoom !== -1) || activeRoom == -1))
											return <Room activeRoom={activeRoom}  key={i} date={date === null ? props.activeUser.CurrentDate : date} gridCount={gridCount} showPrice={showPrice} room={room}></Room>
									}
									)
						}
					</div> : ''
				}

				<div className="col-xl-3 col-xxl-4">
					<div className="row">
						<DailyResarvationStatistic companyStats={props.companyStats}></DailyResarvationStatistic>
						<RemainPackageOfCompany></RemainPackageOfCompany>
					</div>
				</div>
				<div className="col-xl-9 col-xxl-8">
					<ResarvationStats></ResarvationStats>
				</div>
			</div>
			<Statistics companyStats={props.companyStats}></Statistics>

		</>
	)
}
const mapStateToProps = (state) => ({
	rooms: state.rooms.roomsList,
	activeUser: state.user.activeUser.User,
	companyStats: state.company.companyStats,
	company: state.company.activeCompany,
	roomLoading: state.rooms.roomIsLoading
})

const mapDispatchToProps = { GET_COMPANY_STATS }

export default connect(mapStateToProps, mapDispatchToProps)(Home)