import React, { useState } from 'react'
import { connect } from 'react-redux'
import { SET_CREATE_IMAGE } from '../../../plugins/SET_CREATE_IMAGE';
import Loading from '../../shared/loading';
import { SET_UPDATE_ACTIVE_USER } from '../../../store/module/users/action';
import EditWorker from '../Workers/EditWorker';
import { Modal } from 'react-bootstrap';

export const CompanySettings = (props) => {
    const [EditModal, setEditModal] = useState(false);
    const [LocalImage, setLocalImage] = useState({ uri: '', file: [] });
    const [isLoadingAction, setIsLoadingAction] = useState(false);
    let [alertBox, setAlertBox] = useState({ status: false, message: '', variant: 'danger', type: '' });
    function CLEAR_ALERT(time = 3500) {
        setTimeout(() => {
            setAlertBox({
                variant: 'danger',
                status: false,
                message: '',
                type: ''
            })
        }, time);
    }
    async function SET_UPDATE_USER(fd, data, image) {
        setEditModal(false);
        CLEAR_ALERT(0);
        setIsLoadingAction(true)
        const response = await window.$api.put(`Users/${props.user.Id}`, fd);
        if (response.message === 'OK' && response.result === 'OK') {
            props.SET_UPDATE_ACTIVE_USER({
                ...data,
                ImagePath: image.status ? image.uri : props.user.ImagePath
            })
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Kullancı bilgileriniz başarıyla değiştirildi.',
                type: 'info'
            })
        } else if (response.message === 'REGISTERED') {
            setAlertBox({
                status: true,
                variant: 'danger',
                message: `"${data.Phone}" numarası daha önce sisteme kayıt edilmiş görünüyor. Lütfen farklı bir numara ile kayıt oluşturun.`,
                type: 'info'
            })
            setEditModal(true);
        } else {
            setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Kullancı bilgilerinizi değiştiremedik! Lütfen tekrar deneyiniz.',
                type: 'info'
            })
            setEditModal(true);
        }
        CLEAR_ALERT()
        setIsLoadingAction(false)
    }
    function SET_INPUT_FILE() {
        document.getElementById('logo-file').click();
    }
    async function SET_IMAGE(file) {
        const image = await SET_CREATE_IMAGE(file);
        const fd = new FormData();
        fd.append("Image", image.file);
        CLEAR_ALERT(0);
        setIsLoadingAction(true)
        const response = await window.$api.put(`Users/${props.user.Id}`, fd);
        if (response.message === 'OK' && response.result === 'OK') {
            setLocalImage(image)
            props.SET_UPDATE_ACTIVE_USER({
                ...props.user,
                ImagePath: image.uri
            })
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Profil resminiz başarıyla değiştirildi.',
                type: 'image'
            })
        } else {
            setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Profil resminizi değiştiremedik! Lütfen tekrar deneyiniz.',
                type: 'image'
            })
        }
        CLEAR_ALERT()
        setIsLoadingAction(false)
    }
    return (
        <div>
            <div className='card'>
                {
                    alertBox.status && alertBox.type === 'info' ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                }
                <div className='card-title p-4'>
                    Bilgilerim
                </div>
                <div className='card-body row'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <small> Üye Numarası</small>
                        <p className='text-primary'>{5000000000 + props.user.Id}</p>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <small>Adınız Soyadınız</small>
                        <p className='text-primary'>{props.user.Fullname}</p>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div className='col-12 col-md-12 col-lg-12'>
                            <small>Telefon Numaranız</small>
                            <p className='text-primary'>{props.user.Phone}</p>
                        </div>
                    </div>
                    <div className='my-3 d-flex justify-content-end'>
                        <button onClick={() => setEditModal(true)} className='btn btn-secondary' disabled={isLoadingAction}>
                            {
                                isLoadingAction ? <Loading size="sm"></Loading> : ''
                            }
                            Bilgileri Değiştir </button>
                    </div>
                    <div className='alert alert-secondary'>
                        * Çalışan bilgilerinizi buradan güncelleyebilirsiniz. Şifre değiştirme işlemlerinizi bir aşağıdaki bölümden değiştirebilirsiniz.
                    </div>
                </div>
                <Modal centered className="fade" size='lg' onHide={() => setEditModal(false)} show={EditModal}>
                    <Modal.Header>
                        <Modal.Title>Hesabımı Güncelle</Modal.Title>
                        <button
                            className="btn btn-close"
                            onClick={() => setEditModal(false)}
                        >

                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <EditWorker SET_UPDATE_ACTIVE_USER={SET_UPDATE_USER} index={-1} editModal={EditModal} editWorker={props.user}></EditWorker>
                    </Modal.Body>
                </Modal>
            </div>
            <div className='card'>
                <div className='card-title p-4'>
                    Profil Resminiz
                </div>
                <div className='card-body'>
                    {
                        alertBox.status && alertBox.type === 'image' ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                    }
                    <div className=' d-flex align-items-center'>
                        <img src={LocalImage.uri === '' ? window.$api.image(props.user.ImagePath) : LocalImage.uri} style={{ width: '100px' }}></img>
                        <button onClick={() => SET_INPUT_FILE()} disabled={isLoadingAction} className='btn btn-secondary mx-4'>
                            {
                                isLoadingAction ? <Loading size="sm"></Loading> : ''
                            } <i className='flaticon-090-upload mx-2'></i> Yükle
                        </button>
                        <input accept='image/*' type="file" onChange={(e) => SET_IMAGE(e.target.files[0])} className='d-none' id='logo-file'></input>
                    </div>
                    <div className='alert alert-secondary mt-4'>
                        * Profil resminizde Uygunsuz resimler otomatik kaldırılacaktır.
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-title p-4'>
                    Firma Bilgileri
                </div>
                <div className='card-body row'>
                    <div className='col-12 col-md-6 col-lg-2'>
                        <img src={window.$api.image(props.activeCompany.ImagePath)} style={{ width: '100px' }}></img>

                    </div>
                    <div className='col-12 col-md-6 col-lg-2'>
                        <small> Üye İşyeri Numarası</small>
                        <p className='text-primary'>{1000000000 + props.company.Id}</p>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <small> Firma Adı</small>
                        <p className='text-primary'>{props.company.Name}</p>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div className='col-12 col-md-12 col-lg-12'>
                            <small> Firma Adresi</small>
                            <p className='text-primary'>{props.company.Address}</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <small> Departman</small>
                            <p className='text-primary'>{props.company.Department.Name}</p>
                        </div>
                    </div>
                    <div className='alert alert-secondary'>
                        * Firma Bilgilerinde herhangi bir değişiklikte 'Yardım Merkezine' bağlanarak başvurabilirsiniz. Sağ alttan yardım merkezine ulaşabilirsiniz.
                    </div>
                </div>
            </div>
            {/* <div onClick={() => props.setActive('remove-company')} >
                <div className={`widget-stat border card cursor-pointer`}>
                    <div className="card-body p-4">
                        <h3><i className={`flaticon-381-add text-danger`}></i></h3>
                        <h4 className="card-title">Firmayı Sil</h4>
                        <div className="progress mb-2">
                            <div className={`progress-bar progress-animated bg-danger w-100`}></div>
                        </div>
                        <small>Firma kapatma başvurusunu buradan yapabilirsiniz.</small>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

const mapStateToProps = (state) => ({
    company: state.company.remainOfActiveCompany,
    activeCompany: state.company.activeCompany,
    user: state.user.activeUser.User

})

const mapDispatchToProps = { SET_UPDATE_ACTIVE_USER }

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettings)