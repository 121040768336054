import React from 'react'
import { connect } from 'react-redux'
import SupportIndex from '../components/main-components/Support/Index'
export const Index = (props) => {
  return (
    <div><SupportIndex></SupportIndex></div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)