const INITIAL_STATE = {
    activeUser: { Companies: [], User: { ImagePath: "" } },
    loginAlert: { status: false, message: "", variant: '' },
    isLogged: false,
    isLoadingInLoginPage: false,
    userIsExternal: false,
    userExternalDomain: null,
    appLoading: true
}
export const UserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_ACTIVE_USER":
            window.token = action.payload.data.Token;
            const history = action.payload.history
            console.log("history", history, document.location.hash);
            if (document.location.hash.includes('/external-login')) {
                state.userIsExternal = true;
                console.log("buradayımmmm", document.location.href,document.location.host +'/'+ document.location.hash + history.location.search);
                state.userExternalDomain = new URL(document.location.href)

            }
            setTimeout(() => {
                history.push('/checking-user')
            }, 2000);
            return { ...state, appLoading: false, isLogged: true, activeUser: action.payload.data, loginAlert: { status: true, message: "Login has been done succesfully", variant: 'success' }, loginAlertMessage: "Login has been done succesfully.", isLoadingInLoginPage: false };
        case "SET_UPDATE_ACTIVE_USER":
            return { ...state, activeUser: { Companies: state.activeUser.Companies, User: { ...state.activeUser.User, ...action.payload } } };
        case "SET_INVALID_LOGIN":
            return { ...state, appLoading: false, isLogged: false, activeUser: { Companies: [], User: { ImagePath: "" } }, loginAlert: { status: true, message: action.payload, variant: 'danger' }, isLoadingInLoginPage: false };
        case "SET_LOADING_ON_LOGIN_PAGE":
            return { ...state, isLoadingInLoginPage: action.payload, loginAlert: { status: false, message: "", variant: "" } };
        case "SET_LOGOUT":
            return { ...state, appLoading: false, isLogged: false, activeUser: { Companies: [], User: { ImagePath: "" } }, loginAlert: { status: true, message: action.payload.message, variant: action.payload.variant }, isLoadingInLoginPage: false }
        case "SET_USER_IS_EXTERNAL":
            console.log("burda");
            return { ...state, userIsExternal: true, userExternalDomain: action.payload }
        default:
            return state
    }
}