import Api from './api'
const install = (history, dispatch) => {
    
    window.$api = new Api({
        errorStatus: [401, 403, 500, 400, 404],
        loginUrl: "Users/Login",
        loginRouterName: "/login",
        bearerToken: true,
        //baseUrl: "http://172.20.10.2:5002/",
        baseUrl: "https://api.rezzervasyon.com/",
        //baseUrl: "http://192.168.1.9:5002/",
        //baseUrl: "http://localhost:5002/",
        Authorization: "Authorization",
        result: "cevap",
        token: 'FAIL'
    }, history, dispatch)
}

export default {
    install
}