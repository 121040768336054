import { lazy, Suspense, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import ExportApi from './plugins/Api/ExportApi'
// action
import { CHECK_INITIAL_AUTOLOGIN } from './store/module/users/action'
/// Style
import "./assets/vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./assets/css/style.css";

// default layout auth is required in there
//import Default from './layouts/actions/CheckUserLogin';
import CheckingApp from './layouts/actions/CheckingAuth';
import Checking from './components/shared/checking';
const SignUp = lazy(() => import('./pages/Registration'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./pages/Login')), 500);
    });
});
const ExternalLogin = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./pages/ExternalLogin')), 500);
    });
});

// 1. adım login olunur token alınır.
// 2. adım login değilse logine gönderilir.
// 3. Adım Eğer kullanıcı login olmuşsa checkingAuth kontrol edilir.

function App(props) {
    ExportApi.install(props.history, useDispatch());
    ExportApi.token = "fasdadsladka"
    useEffect(() => {
        props.CHECK_INITIAL_AUTOLOGIN(props.history, true);
    }, []); // every route change will be work.
    let routes = (
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/external-login' component={ExternalLogin} />
            <Route path='/page-register' component={SignUp} />
            <Route path='/page-forgot-password' component={ForgotPassword} />
        </Switch>
    );

    if (props.appLoading) {
        return (
            <Checking description="Uygulama Yükleniyor."></Checking>
        )
    }
    else if (props.isLogged) {
        return (
            <CheckingApp></CheckingApp> // layout template
        );
    }
    else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.user.isLogged, // control of logged in user
        appLoading: state.user.appLoading, // it's true when app is loading and user control.
        company: state.company.activeCompany // it has an active company when app will be open.
    };
};

export default withRouter(connect(mapStateToProps, { CHECK_INITIAL_AUTOLOGIN })(App));

