
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CHECK_AUTOLOGIN_ON_LOCALSTORAGE } from '../../store/module/users/action'
import Checking from '../../components/shared/checking'
function Default(props) {
    setTimeout(() => {
        if (props.activeUser.roleId === 2) {
            props.history.push('/checking-package')
        } else {
            props.history.push('/checking-company')
        }
    }, 2000);
    useEffect(() => {
        console.log(props.history, "check user login");
        props.CHECK_AUTOLOGIN_ON_LOCALSTORAGE(props.history);
    }, [props.history]); // every route change will be work.

    return (
        <div>
            <Checking description="Kullanıcı Bilgisi Kontrol Ediliyor.."></Checking>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        activeUser: state.user.activeUser,
    }
}

export default withRouter(connect(mapStateToProps, { CHECK_AUTOLOGIN_ON_LOCALSTORAGE })(Default))