import { applyMiddleware, combineReducers, compose, createStore, } from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import { UserReducer } from './module/users/reducer'
import { CompanyReducer } from './module/company/reducer'
import { PackageReducer } from './module/packages/reducer'
import { RoomsReducer } from './module/rooms/reducer'
import { ProcessReducer } from './module/processing/reducer'
import { WorkersReducer } from './module/workers/reducer';
import { CustomersReducer } from './module/customers/reducer';
import { CustomersTagReducer } from './module/customersTag/reducer';
import { SettingsReducer } from './module/settings/reducer';
import { reducer as reduxFormReducer } from 'redux-form';
import { CustomerPackagesReducer } from './module/customerPackages/reducer';
import { PaymentMethodsReducer } from './module/paymentMethods/reducer';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    user: UserReducer,
    company: CompanyReducer,
    package: PackageReducer,
    posts: PostsReducer,
    auth: AuthReducer,
    processing: ProcessReducer,
    rooms: RoomsReducer,
    workers: WorkersReducer,
    customers: CustomersReducer,
    customersTag: CustomersTagReducer,
    customerPackages: CustomerPackagesReducer,
    settings: SettingsReducer,
    paymentMethods: PaymentMethodsReducer,
    todoReducers,
    form: reduxFormReducer,

});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
