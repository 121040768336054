import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../shared/loading';

export const CompanyMessages = (props) => {
    const [BirthdayMessage, setBirthdayMessage] = useState('');
    const [CompanyMessagesList, setCompanyMessagesList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let [alertBox, setAlertBox] = useState({ status: false, message: '', variant: 'danger', type: '' });
    function SEND_UPDATE_COMPANY_MESSAGE(type) {
        if (type === 1) {
            SEND_BIRTHDAY()
        }
    }
    function CLEAR_ALERT(time = 3500) {
        setTimeout(() => {
            setAlertBox({
                variant: 'danger',
                status: false,
                message: '',
                type: ''
            })
        }, time);
    }
    async function SEND_BIRTHDAY() {
        const birthday = CompanyMessagesList.find(d => d.Type === 1);
        if (!BirthdayMessage.includes('{CUSTOMER_NAME}') || !BirthdayMessage.includes(props.company.Name))
            return setAlertBox({
                variant: 'danger',
                status: true,
                message: 'İş yerinizin adı ve müşteri adı mesajın içeriğinde bulunmak zorundadır. Lütfen mesajı kontrol ediniz.',
            })
        CLEAR_ALERT(1)
        setIsLoading(true)
        if (birthday !== undefined) {
            const response = await window.$api.put(`CompanyMessages/${birthday.Id}`, {
                Content: BirthdayMessage
            })
            if (response.message === 'OK' && response.result === 'OK') {
                setAlertBox({
                    variant: 'success',
                    status: true,
                    message: 'Doğum günü mesajınız başarıyla değiştirildi.',
                    type: 'birthday'
                })
            } else {
                setAlertBox({
                    variant: 'danger',
                    status: true,
                    message: 'Doğum günü mesajınızı değiştiremedik! Lütfen tekrar deneyiniz.',
                    type: 'birthday'
                })
            }
            setIsLoading(false)
            CLEAR_ALERT()
        } else {
            const response = await window.$api.post(`CompanyMessages/Create`, {
                Content: BirthdayMessage,
                Type: 1
            })
            if (response.message === 'OK' && response.result === 'OK') {
                setCompanyMessagesList([...CompanyMessagesList, {
                    Content: BirthdayMessage,
                    Type: 1,
                    Id: response.data
                }])
                setAlertBox({
                    variant: 'success',
                    status: true,
                    message: 'Doğum günü mesajınız başarıyla eklendi.',
                    type: 'birthday'
                })
            } else {
                setAlertBox({
                    variant: 'danger',
                    status: true,
                    message: 'Doğum günü mesajınızı ekleyemedik! Lütfen tekrar deneyiniz.',
                    type: 'birthday'
                })
            }
            setIsLoading(false)
            CLEAR_ALERT()
        }
    }
    async function GET_COMPANY_MESSAGE_LIST() {
        const response = await window.$api.get('CompanyMessages/GetAll');
        if (response.message === 'OK' && response.result === 'OK') {
            if (response.data !== null) {
                const birthday = response.data.find(d => d.Type === 1)
                if (birthday !== undefined);
                setBirthdayMessage(birthday.Content);
                setCompanyMessagesList(response.data);
            }
            else setCompanyMessagesList([])
        } else setCompanyMessagesList([])

    }
    useEffect(() => {
        GET_COMPANY_MESSAGE_LIST();
    }, [])
    return (
        <div>
            <div className='card'>
                <div className='card-title pt-4 px-4'>
                    Doğum Günü Mesajları
                </div>
                <div className='card-body'>
                    {
                        alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                    }
                    <h4> Doğum Günü Mesajı Nasıl Oluşturulur? </h4>
                    <div className='alert alert-primary mt-4'>
                        Doğum günü mesajını eklerken aşağıdaki <button className='btn btn-success btn-xs'>MÜŞTERİ ADI</button> ve <button className='btn btn-success btn-xs'>FİRMA ADI</button> butonlarına
                        tıklayarak müşteriye özel isim ve firma adınızı kullanmak zorunludur. Müsterinin isimi {'{CUSTOMER_NAME}'} yazan yere gelecektir.
                        <div className='mt-4'>
                            <p className='mt-4'><strong>Girilen Mesaj: </strong> Merhaba {'{CUSTOMER_NAME}'}, doğum gününüz kutlu olsun!! Bugün {props.company.Name} firmasından sadece size özel olarak rezervasyonunuz %25 indirim ile sizleri bekliyor.</p>
                            <p className='mt-4'><strong>Müşteriye Gönderilen Mesaj: </strong> Merhaba {props.user.Fullname}, doğum gününüz kutlu olsun!! Bugün {props.company.Name} firmasından sadece size özel olarak rezervasyonunuz %25 indirim ile sizleri bekliyor.</p>
                        </div>
                    </div>
                    <div className=' d-flex align-items-center'>
                        <textarea value={BirthdayMessage} onInput={(e) => setBirthdayMessage(e.target.value)} placeholder='Doğum Günü Mesajını buraya ekleyin.' className='form-control'></textarea>
                    </div>
                    <div className='mt-4'>
                        <button disabled={BirthdayMessage.includes('{CUSTOMER_NAME}')} onClick={() => setBirthdayMessage(BirthdayMessage + ' {CUSTOMER_NAME} ')} className='btn btn-success mx-2'> MÜŞTERİ ADI </button>
                        <button disabled={BirthdayMessage.includes(props.company.Name)} onClick={() => setBirthdayMessage(BirthdayMessage + ' ' + props.company.Name + ' ')} className='btn btn-success mx-2'> FİRMA ADI </button>
                    </div>
                    <div className=' d-flex align-items-end justify-content-end'>
                        <button disabled={isLoading} onClick={() => SEND_UPDATE_COMPANY_MESSAGE(1)} className='btn btn-secondary'>
                            {
                                isLoading ? <Loading size="sm"></Loading> : ''
                            } Kaydet

                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.activeUser.User,
    company: state.company.activeCompany
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyMessages)