export const INITIAL_STATE = {
    activeCompany: { status: false, PackageFinishedAt: "1999-05-05" },
    connectedCompany: false,
    connected: false,
    remainOfActiveCompany: { status: false },
    companyStats: { status: false },
    

}
export const CompanyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_ACTIVE_COMPANY":
            return { ...state, activeCompany: { ...action.payload, status: true, }, connectedCompany: false }
        case "SET_UPDATE_ACTIVE_COMPANY":
            return { ...state, activeCompany: { ...action.payload, status: true, }, connectedCompany: false }
        case "SET_REMOVE_COMPANY":
        return { ...state, activeCompany: { status: false, PackageFinishedAt: "1999-06-05" }, companyStats: { status: false }, connectedCompany: false, connected: false }
        case "SET_REMAIN_INFO_OF_ACTIVE_COMPANY":
            return { ...state, remainOfActiveCompany: { ...action.payload, status: true, connected: true } }
        case "SET_UPDATE_REMAIN_OF_COMPANY":
            return { ...state, remainOfActiveCompany: { ...action.payload, status: true, connected: true } }
        case "SET_UPDATE_EXPIRED_PACKAGE_COMPANY":
            return { ...state, activeCompany: { ...state.activeCompany, PackageFinishedAt: action.payload }, connectedCompany: false }
        case "SET_COMPANY_STATS":
            return { ...state, companyStats: { ...action.payload, status: true } }
        case "SET_REFRESH_APP":
            return { ...state, remainOfActiveCompany: { ...state.remainOfActiveCompany, status: false } }
        default:
            return state
    }
}