import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import Loading from '../../shared/loading'
import { GET_PAYMENT_METHODS_LIST, SET_ALERT_BOX, SET_UPDATE_PAYMENT_METHODS, SET_INSERT_PAYMENT_METHODS, SET_REMOVE_PAYMENT_METHODS } from '../../../store/module/paymentMethods/action'
import { isValidIBANNumber } from '../../../plugins/IBAN_CHECKHER'
export const CompanyPayments = (props) => {
    const [iyzicoInfo, setIyzicoInfo] = useState({ Id: -1, Key: '', Secret: '' })
    const [paypalInfo, setPaypalInfo] = useState({ Id: -1, Key: '', Secret: '' })
    const [eft, setEft] = useState({ Id: -1, BankName: '', IBAN: '', ReceiverName: '' })
    const [inputAlert, setInputAlert] = useState([]);
    const [eftModal, setEftModal] = useState(false);
    useEffect(() => {
        SET_PAYMENTS();
        props.GET_PAYMENT_METHODS_LIST();
    }, [])
    useEffect(() => {
        if (!props.isLoading) {
            setTimeout(() => {
                SET_PAYMENTS();
            }, 200);
        }
    }, [props.isLoading])
    useEffect(() => {
        if ((props.alertBox.status === 'insert' || props.alertBox.status === 'update') && props.alertBox.variant === 'success') {
            setEft({ Id: -1, BankName: '', IBAN: '', ReceiverName: '' })
            setTimeout(() => {
                setEftModal(false);
            }, 2500);
        }
    }, [props.alertBox])
    function SET_PAYMENTS() {
        console.log("eveett payments", props.paymentMethodsList);
        if (props.paymentMethodsList.length !== 0) {
            setIyzicoInfo(props.paymentMethodsList.find(pm => pm.Type === 1) || { Id: -1, Key: '', Secret: '' })
            setPaypalInfo(props.paymentMethodsList.find(pm => pm.Type === 2) || { Id: -1, Key: '', Secret: '' })
        }
    }
    function SET_NEW_IYZICO_PAYPAL(info, Type) {
        setTimeout(() => {
            props.SET_INSERT_PAYMENT_METHODS({
                BankName: '',
                IBAN: '',
                ReceiverName: '',
                Secret: info.Secret,
                Key: info.Key,
                Type
            });
        }, 200);
    }
    function SET_UPDATE_IYZICO_PAYPAL(info, Type) {
        setTimeout(() => {
            props.SET_UPDATE_PAYMENT_METHODS({
                Id: info.Id,
                BankName: '',
                IBAN: '',
                ReceiverName: '',
                Secret: info.Secret,
                Key: info.Key,
                Type
            });
        }, 200);
    }
    function SET_NEW_EFT() {
        setInputAlert([]);
        setTimeout(() => {
            if (eft.BankName === '' || !isValidIBANNumber(eft.IBAN) || eft.ReceiverName === '') {
                const arr = [];
                if (eft.BankName === '') {
                    arr.push('Banka adı girilmesi zorunludur.');
                }
                if (eft.ReceiverName === '') {
                    arr.push('Hesap Sahibi girilmesi zorunludur.');
                }
                if (!isValidIBANNumber(eft.IBAN)) {
                    arr.push('Girilen IBAN numarası geçersiz. Lütfen IBAN bilgilerinizi kontrol edin.');
                }
                setInputAlert(arr);
                return;
            }
            props.SET_INSERT_PAYMENT_METHODS({
                BankName: eft.BankName,
                IBAN: eft.IBAN,
                ReceiverName: eft.ReceiverName,
                Secret: '',
                Key: '',
                Type: 3
            });
        }, 200);
    }
    function SET_UPDATE_EFT() {
        setInputAlert([]);
        setTimeout(() => {
            if (eft.BankName === '' || !isValidIBANNumber(eft.IBAN) || eft.ReceiverName === '') {
                const arr = [];
                if (eft.BankName === '') {
                    arr.push('Banka adı girilmesi zorunludur.');
                }
                if (eft.ReceiverName === '') {
                    arr.push('Hesap Sahibi girilmesi zorunludur.');
                }
                if (!isValidIBANNumber(eft.IBAN)) {
                    arr.push('Girilen IBAN numarası geçersiz. Lütfen IBAN bilgilerinizi kontrol edin.');
                }
                setInputAlert(arr);
                return;
            }
            props.SET_UPDATE_PAYMENT_METHODS({
                ...eft, Secret: '',
                Key: '',
                Type: 3

            });
        }, 100);
    }
    return (
        <div>
            <div className='card my-4'>
                <div className='card-title p-4'>Iyzico Bağlantıları</div>
                <div className='card-body'>
                    {
                        props.alertBox.status === 'update' ?
                            <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                    }
                    {
                        props.alertBox.status === 'insert' ?
                            <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                    }
                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <div className='form-group'>
                                <label>Iyzico API Anahtarı</label>
                                <input placeholder='Iyzico api anahtarı girin...' className='form-control' value={iyzicoInfo.Key} onInput={(e) => setIyzicoInfo({ ...iyzicoInfo, Key: e.target.value })}></input>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className='form-group'>
                                <label>Iyzico Secret Anahtarı</label>
                                <input placeholder='Iyzico secret anahtarı girin...' className='form-control' value={iyzicoInfo.Secret} onInput={(e) => setIyzicoInfo({ ...iyzicoInfo, Secret: e.target.value })}></input>
                            </div>
                        </div>
                        <div className='col-12 d-flex justify-content-end mt-4'>
                            {
                                iyzicoInfo.Id === -1 ? <button disabled={props.isLoadingInsert} onClick={() => SET_NEW_IYZICO_PAYPAL(iyzicoInfo, 1)} className='btn btn-secondary'>Kaydet</button>
                                    : <button disabled={props.isLoadingUpdate} onClick={() => SET_UPDATE_IYZICO_PAYPAL(iyzicoInfo, 1)} className='btn btn-secondary'>Gönder</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='card my-4'>
                <div className='card-title p-4'>PayPal Bağlantıları {props.isLoading ? 't' : 'f'}</div>
                <div className='card-body'>
                    {
                        props.alertBox.status === 'update' ?
                            <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                    }
                    {
                        props.alertBox.status === 'insert' ?
                            <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                    }
                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <div className='form-group'>
                                <label>PayPal API Anahtarı</label>
                                <input placeholder='PayPal api anahtarı girin...' className='form-control' value={paypalInfo.Key} onInput={(e) => setPaypalInfo({ ...paypalInfo, Key: e.target.value })}></input>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className='form-group'>
                                <label>PayPal Secret Anahtarı</label>
                                <input placeholder='PayPal secret anahtarı girin...' className='form-control' value={paypalInfo.Secret} onInput={(e) => setPaypalInfo({ ...paypalInfo, Secret: e.target.value })}></input>
                            </div>
                        </div>
                        <div className='col-12 d-flex justify-content-end mt-4'>
                            {
                                paypalInfo.Id === -1 ? <button disabled={props.isLoadingInsert} onClick={() => SET_NEW_IYZICO_PAYPAL(paypalInfo, 2)} className='btn btn-secondary'>Kaydet</button>
                                    : <button disabled={props.isLoadingUpdate} onClick={() => SET_UPDATE_IYZICO_PAYPAL(paypalInfo, 2)} className='btn btn-secondary'>Gönder</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='card my-4'>
                <div className='card-title p-4'>EFT/Havale Bağlantıları <button onClick={() => {
                    setEft({ Id: -1, BankName: '', IBAN: '', ReceiverName: '' });
                    setEftModal(true)
                }} className='btn btn-secondary btn-xs'>+ EFT/Havale Ekle</button></div>
                <div className='card-body'>
                    {props.isLoading ? <div className='text-center'><Loading variant='primary'></Loading></div> : props.paymentMethodsList.filter(pm => pm.Type === 3).length === 0 ? <div className='text-center mt-4'>EFT/Havale bilgisi bulunmadı.</div> :
                        <div className='row'>
                            {
                                props.paymentMethodsList.filter(pm => pm.Type === 3).map(other => (
                                    <div className="col-12 my-1">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-lg-3">
                                                        <div><small>Banka Adı</small></div>
                                                        {other.BankName}
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <div><small>IBAN Sahibi</small></div>
                                                        {other.ReceiverName}
                                                    </div>
                                                    <div className="col-12 col-lg-4">
                                                        <div><small>IBAN</small></div>
                                                        {other.IBAN}
                                                    </div>
                                                    <div className="col-12 col-lg-2">
                                                        <button onClick={() => {
                                                            setEft(other);
                                                            setEftModal(true);
                                                        }} className="btn btn-xs btn-primary">
                                                            Düzenle
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>}

                </div>
            </div>
            <Modal centered className="fade" onHide={() => setEftModal(false)} show={eftModal}>
                <Modal.Header>
                    <Modal.Title> <i className='flaticon-381-warning'></i> EFT/Havale İşlemleri</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setEftModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    {
                        inputAlert.length > 0 ? <div className='mb-4 alert alert-danger'>
                            <ul>
                                {
                                    inputAlert.map((input, i) => (<li key={i}>
                                        {i + 1}. {input}
                                    </li>))
                                }
                            </ul>
                        </div> : ''
                    }
                    {
                        props.alertBox.status === 'update' ?
                            <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                    }
                    {
                        props.alertBox.status === 'insert' ?
                            <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> : <></>
                    }
                    <div className='row'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>Banka Adı</label>
                                <input placeholder='Banka Adını girin...' className='form-control' value={eft.BankName} onInput={(e) => setEft({ ...eft, BankName: e.target.value })}></input>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>Hesap Sahibi</label>
                                <input placeholder='Hesap sahibini girin...' className='form-control' value={eft.ReceiverName} onInput={(e) => setEft({ ...eft, ReceiverName: e.target.value })}></input>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label>IBAN</label>
                                <input placeholder='TR' className='form-control' value={eft.IBAN} onInput={(e) => setEft({ ...eft, IBAN: e.target.value })}></input>
                            </div>
                            <div className='mt-2 alert alert-secondary'>
                                IBAN numaranızı "TR bölge kodu" ile birlikte ekleyiniz. Diğer ülkelerdeki ibanlar için ülke kodunu girerek devam edebilirsiniz.
                            </div>
                        </div>
                        <div className='col-12 d-flex justify-content-end mt-4'>
                            {
                                eft.Id === -1 ? <button disabled={props.isLoadingInsert} onClick={() => SET_NEW_EFT()} className='btn btn-primary'>Kaydet</button>
                                    : <button disabled={props.isLoadingUpdate} onClick={() => SET_UPDATE_EFT()} className='btn btn-secondary'>Güncelle</button>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    paymentMethodsList: state.paymentMethods.paymentMethodsList,
    isLoading: state.paymentMethods.isLoading,
    isLoadingInsert: state.paymentMethods.isLoadingInsert,
    isLoadingUpdate: state.paymentMethods.isLoadingUpdate,
    alertBox: state.paymentMethods.alertBox,
    isLoadingRemove: state.paymentMethods.isLoadingRemove,
})

const mapDispatchToProps = { GET_PAYMENT_METHODS_LIST, SET_ALERT_BOX, SET_INSERT_PAYMENT_METHODS, SET_REMOVE_PAYMENT_METHODS, SET_UPDATE_PAYMENT_METHODS }

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPayments)