import React from 'react'
import { connect } from 'react-redux'
import WorkersChild from '../components/main-components/Workers/WorkersChild'

export const Workers = (props) => {
  return (
    <div>
      <h3>Çalışanlarım</h3>
      <WorkersChild></WorkersChild>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Workers)