import React from 'react'
import { connect } from 'react-redux'
import Index from '../components/main-components/CustomerDetail/Index'

export const CustomerDetail = (props) => {
    return (
        <Index></Index>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail)