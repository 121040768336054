 import React from "react";
 import { connect } from 'react-redux';
 function Footer (props) {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © Designed &amp; Developed by{" "}
          <a href="http://Eralp Software.com/" target="_blank" rel="noreferrer">
            Eralp Software
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default connect()(Footer);
