const INITIAL_STATE = {
    alertModal: { state: false, title: '', component: '', persistent: false }
}
export const SettingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_ALERT_MODAL":
            return { ...state, alertModal: action.payload };
        default:
            return state
    }
}