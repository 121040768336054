import React from 'react'
import { connect } from 'react-redux'
import ProfileSettings from '../components/main-components/Profile/ProfileSettings'
export const Profile = (props) => {
  return (
    <ProfileSettings></ProfileSettings>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)