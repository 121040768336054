export function GET_WORKER_LIST() {
    return async (dispatch) => {
        const response = await window.$api.get(`Users/GetAll`)
        if (response.message === 'OK' && response.result === 'OK') {
            if(response.data === null) dispatch(SET_WORKER_LIST([]))
            else dispatch(SET_WORKER_LIST(response.data))
        } else dispatch(SET_WORKER_LIST())
    }
}

function SET_WORKER_LIST(data = []) {
    return {
        type: 'SET_WORKER_LIST',
        payload: data === null ? [] : data
    }
}
export function SET_LOADING_WORKER(data) {
    return {
        type: 'SET_LOADING_WORKER',
        payload: data
    }
}
export function SEND_NEW_WORKER_TO_DB(fd, data) {
    return async (dispatch) => {
        dispatch(SET_LOADING_WHEN_INSERTING(true))
        const response = await window.$api.post(`Users/Create`, fd);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_THAT_WORKER_OF_INSERTED_TO_LIST({ ...data, Id: response.data }, {
                status: true,
                variant: 'success',
                message: 'Kullanıcı hesabını başarıyla oluşturduk! Hesabına giriş yapabilir.'
            }))
            dispatch(SET_RESET_LOADING())
        } else if (response.message === 'REGISTERED') {
            dispatch(SET_THAT_WORKER_OF_INSERTED_TO_LIST({}, {
                status: true,
                variant: 'danger',
                message: `"${data.Phone}" numarası daha önce sisteme kayıt edilmiş görünüyor. Lütfen farklı bir numara ile kayıt oluşturun.`
            }))
        } else if (response.message === 'NOPACKAGERIGHT') {
            dispatch(SET_THAT_WORKER_OF_INSERTED_TO_LIST({}, {
                status: true,
                variant: 'warning',
                message: `Firmanızın yeni çalışan ekleme hakkı dolmuş görünüyor. Paketini yükseltebilir veya Ek paket satın alabilirsin.`
            }))
        }
        else {
            dispatch(SET_THAT_WORKER_OF_INSERTED_TO_LIST({}, {
                status: true,
                variant: 'danger',
                message: 'Kullanıcı kaydı gerçekleştirelemedi. Lütfen tekrar deneyin!'
            }))
        }
        dispatch(SET_LOADING_WHEN_INSERTING(false))

    }
}
export function SEND_UPDATE_WORKER_TO_DB(fd, data) {
    return async (dispatch) => {
        dispatch(SET_LOADING_WHEN_INSERTING(true))
        const response = await window.$api.put(`Users/${data.Id}`, fd);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_THAT_WORKER_OF_UPDATED_TO_LIST({ ...data, Id: response.data }, {
                status: true,
                variant: 'success',
                message: 'Kullanıcı hesabını başarıyla güncelledik! Hesabına güncel şekilde giriş yapabilir.'
            }))
            dispatch(SET_RESET_LOADING())
        } else if (response.message === 'REGISTERED') {
            dispatch(SET_THAT_WORKER_OF_UPDATED_TO_LIST({}, {
                status: true,
                variant: 'danger',
                message: `"${data.Phone}" numarası daha önce sisteme kayıt edilmiş görünüyor. Lütfen farklı bir numara ile kayıt oluşturun.`
            }))
        }
        else {
            dispatch(SET_THAT_WORKER_OF_UPDATED_TO_LIST({}, {
                status: true,
                variant: 'danger',
                message: 'Kullanıcı kaydı gerçekleştirelemedi. Lütfen tekrar deneyin!'
            }))
        }
        dispatch(SET_LOADING_WHEN_INSERTING(false))

    }
}


function SET_THAT_WORKER_OF_INSERTED_TO_LIST(data, alertBox) {
    return {
        type: 'SET_THAT_WORKER_OF_INSERTED_TO_LIST',
        payload: { data, alertBox }
    }
}

function SET_THAT_WORKER_OF_UPDATED_TO_LIST(data, alertBox) {
    return {
        type: 'SET_THAT_WORKER_OF_UPDATED_TO_LIST',
        payload: { data, alertBox }
    }
}

function SET_LOADING_WHEN_INSERTING(data) {
    return {
        type: 'SET_LOADING_WHEN_INSERTING',
        payload: data
    }
}

export function SET_ALERT_BOX(payload) {
    return {
        type: 'SET_ALERT_BOX',
        payload
    }
}

export function SET_REMOVE_WORKER_LIST(payload) {
    return {
        type: 'SET_REMOVE_WORKER_LIST',
        payload
    }
}

export function SET_REMOVE_WORKER(index, id) {
    return async (dispatch) => {
        dispatch(SET_LOADING_WHEN_INSERTING(true))

        const response = await window.$api.delete(`Users/${id}`);
        if (response.message === 'OK' && response.result === 'OK') {
            dispatch(SET_REMOVE_WORKER_LIST({
                status: 'remove',
                variant: 'success',
                message: `Tebrikler! Çalışanı başarıyla kaldırdık..`,
                data: { index, id },
            }))
            dispatch(SET_RESET_LOADING())
        } else
            dispatch(SET_REMOVE_WORKER_LIST({
                status: 'remove',
                variant: 'danger',
                message: `Bir problem ile karşılaştık! Çalışanı kaldıramadık, lütfen bir süre sonra tekrar deneyin..`,
                data: { index: -1, }
            }))
    }
}

function SET_RESET_LOADING(params) {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(SET_ALERT_BOX({
                variant: 'danger',
                status: false,
                message: ''
            }))
            dispatch(SET_LOADING_WORKER(true))
            setTimeout(() => {
                dispatch(SET_LOADING_WORKER(false))

            }, 1500);
        }, 2500);
    }
}