import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { SET_CREATE_IMAGE } from '../../../plugins/SET_CREATE_IMAGE';
import Loading from '../../shared/loading';
import { SET_UPDATE_ACTIVE_COMPANY, SET_UPDATE_REMAIN_OF_COMPANY } from '../../../store/module/company/action';
import CompanyApplyBrand from './CompanyApplyBrand';

export const CompanySettings = (props) => {
    const [RemindTime, setRemindTime] = useState(0);
    const [LocalImage, setLocalImage] = useState({ uri: '', file: [] });
    const [IsBirthdayMessage, setIsBirthdayMessage] = useState(false);
    const [isLoadingAction, setIsLoadingAction] = useState(false);
    let [alertBox, setAlertBox] = useState({ status: false, message: '', variant: 'danger', type: '' });
    useEffect(() => {
        setRemindTime(props.company.RemindTime);
        setIsBirthdayMessage(props.company.IsBirthdayMessage === 1);
    }, [])
    function CLEAR_ALERT(time = 3500) {
        setTimeout(() => {
            setAlertBox({
                variant: 'danger',
                status: false,
                message: '',
                type: ''
            })
        }, time);
    }
    async function SET_UPDATE_COMPANY(data) {
        const fd = new FormData();
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const element = data[key];
                fd.append(key, element);
            }
        }
        CLEAR_ALERT(0);
        setIsLoadingAction(true)
        const response = await window.$api.put(`Companies/${props.company.Id}`, fd);
        if (response.message === 'OK' && response.result === 'OK') {
            props.SET_UPDATE_REMAIN_OF_COMPANY({
                ...props.company,
                ...data
            })
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Ek hizmetleriniz başarıyla değiştirildi.',
                type: 'other-action'
            })
        } else {
            setAlertBox({
                variant: 'danger',
                status: true,
                message: 'Ek hizmetlerinizi değiştiremedik! Lütfen tekrar deneyiniz.',
                type: 'other-action'
            })
        }
        CLEAR_ALERT()
        setIsLoadingAction(false)
    }
    function SET_INPUT_FILE() {
        document.getElementById('logo-file').click();
    }
    async function SET_IMAGE(file) {
        const image = await SET_CREATE_IMAGE(file);
        const fd = new FormData();
        fd.append("companyImg", image.file);
        CLEAR_ALERT(0);
        setIsLoadingAction(true)
        const response = await window.$api.put(`Companies/${props.company.Id}`, fd);
        if (response.message === 'OK' && response.result === 'OK') {
            setLocalImage(image)
            props.SET_UPDATE_ACTIVE_COMPANY({
                ...props.activeCompany,
                ImagePath: image.uri
            })
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'İşyeri logonuz başarıyla değiştirildi.',
                type: 'image'
            })
        } else {
            setAlertBox({
                variant: 'danger',
                status: true,
                message: 'İşyeri Logonuzu değiştiremedik! Lütfen tekrar deneyiniz.',
                type: 'image'
            })
        }
        CLEAR_ALERT()
        setIsLoadingAction(false)
    }
    return (
        <div>
            <div className='card'>
                <div className='card-title p-4'>
                    Bilgilerim
                </div>
                <div className='card-body row'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <small> Üye İşyeri Numarası</small>
                        <p className='text-primary'>{1000000000 + props.company.Id}</p>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <small> Firma Adı</small>
                        <p className='text-primary'>{props.company.Name}</p>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div className='col-12 col-md-12 col-lg-12'>
                            <small> Firma Adresi</small>
                            <p className='text-primary'>{props.company.Address}</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <small> Departman</small>
                            <p className='text-primary'>{props.company.Department.Name}</p>
                        </div>
                    </div>
                    <div className='alert alert-secondary'>
                        * Firma Bilgilerinde herhangi bir değişiklikte 'Yardım Merkezine' bağlanarak başvurabilirsiniz. Sağ alttan yardım merkezine ulaşabilirsiniz.
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-title p-4'>
                    İşyeri Logosu
                </div>
                <div className='card-body'>
                    {
                        alertBox.status && alertBox.type === 'image' ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                    }
                    <div className=' d-flex align-items-center'>
                        <img src={LocalImage.uri === '' ? window.$api.image(props.activeCompany.ImagePath) : LocalImage.uri} style={{ width: '100px' }}></img>
                        <button onClick={() => SET_INPUT_FILE()} disabled={isLoadingAction} className='btn btn-secondary mx-4'>
                            {
                                isLoadingAction ? <Loading size="sm"></Loading> : ''
                            } <i className='flaticon-090-upload mx-2'></i> Yükle
                        </button>
                        <input accept='image/*' type="file" onChange={(e) => SET_IMAGE(e.target.files[0])} className='d-none' id='logo-file'></input>
                    </div>
                    <div className='alert alert-secondary mt-4'>
                        * Firma Logosu değişikliğinde Uygunsuz resimler otomatik kaldırılacaktır.
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-title p-4'>
                    Ek Hizmetler
                </div>
                <div className='card-body row'>
                    <div className='col-12'>
                        {
                            alertBox.status && alertBox.type === 'other-action' ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                        }
                    </div>
                    <div className='col-12'>
                        <h4> <i className='flaticon-039-goal mx-2'></i> Doğum Günü Mesajı</h4>
                        <p className='text-primary'>
                            Dilerseniz firmanız adına müşterilerinize doğum günlerinde SMS gönderiyoruz. İsterseniz bu özelliği kapatabilirsiniz.
                            <br></br>
                        </p>
                        <div className='alert alert-secondary'>
                            {
                                IsBirthdayMessage ?
                                    "* 'Gönderme' butonuna basarak doğum gününde SMS gönderme özelliğini kapatabilirsiniz." :
                                    "* 'Gönder' butonuna basarak doğum gününde SMS gönderme özelliğini açabilirsiniz."
                            }
                        </div>
                        <div className='mt-2 d-flex align-items-center'>
                            <button onClick={() => !IsBirthdayMessage ? SET_UPDATE_COMPANY({
                                isBirthdayMessage: 1
                            }, setIsBirthdayMessage(!IsBirthdayMessage)) : ''} className={`btn mx-3 btn-sm btn-${IsBirthdayMessage ? 'success' : 'white'}`} disabled={isLoadingAction}>
                                {
                                    isLoadingAction ? <Loading size="sm"></Loading> : ''
                                }
                                Gönder </button>
                            <button onClick={() => IsBirthdayMessage ? SET_UPDATE_COMPANY({
                                isBirthdayMessage: -1
                            }, setIsBirthdayMessage(!IsBirthdayMessage)) : ''} className={`btn mx-3 btn-sm btn-${!IsBirthdayMessage ? 'danger' : 'white'}`} disabled={isLoadingAction}>
                                {
                                    isLoadingAction ? <Loading size="sm"></Loading> : ''
                                }
                                Gönderme</button>
                        </div>
                    </div>
                    <div className='col-12 mt-4'>
                        <h4> <i className='flaticon-088-time mx-2'></i> Rezervasyon Hatırlatma Mesajı</h4>
                        <p className='text-primary'>
                            Dilerseniz firmanız adına rezervasyonunuza sizin belirlediğiniz dakika kadar önce SMS gönderiyoruz. İsterseniz bu özelliği kapatabilirsiniz.
                        </p>
                        <div className='alert alert-secondary mt-4'>
                            {
                                RemindTime < 1 ?
                                    "Hatırlatma özelliğini açmak için aşağıdaki 'Rezervasyon Hatırlatma Mesajını Aç' butonuna basın sonrasında süreyi ayarladıktan sonra kaydedin." :
                                    "* Hatırlatma Mesajını 0 ve 0 dan küçük ayarladığınızda otomatik bu özellik kapatılacaktır veya aşağıdan 'Rezervasyon Hatırlatma Mesajını Kapat' butonuna basıp kaydedebilirsiniz."
                            }
                        </div>
                        {
                            RemindTime < 1 ? <div className='mt-2'>
                                <button onClick={() => setRemindTime(15)} className='btn btn-secondary btn-sm'>Rezervasyon Hatırlatma Mesajını Aç</button>
                            </div> :
                                <div className='mt-2'>
                                    <div className="input-group mb-3 ">
                                        <input value={RemindTime} onInput={(e) => setRemindTime(e.target.value)} type="number" className='form-control' ></input>
                                        <span className="input-group-text">dk.</span>
                                    </div>
                                    <div className='mt-2'>
                                        <button onClick={() => setRemindTime(0)} className='btn btn-danger btn-sm'>Rezervasyon Hatırlatma Mesajını Kapat</button>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className='d-flex justify-content-end mt-4'>
                        <button onClick={() => SET_UPDATE_COMPANY({
                            remindTime: RemindTime,
                            isBirthdayMessage: IsBirthdayMessage ? 1 : -1
                        })} className='btn btn-secondary' disabled={isLoadingAction}>
                            {
                                isLoadingAction ? <Loading size="sm"></Loading> : ''
                            } Kaydet</button>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-title p-4'>Şube Başvurusu</div>
                <div className='card-body'>
                    <CompanyApplyBrand></CompanyApplyBrand>
                </div>
            </div>
            {/* <div onClick={() => props.setActive('remove-company')} >
                <div className={`widget-stat border card cursor-pointer`}>
                    <div className="card-body p-4">
                        <h3><i className={`flaticon-381-add text-danger`}></i></h3>
                        <h4 className="card-title">Firmayı Sil</h4>
                        <div className="progress mb-2">
                            <div className={`progress-bar progress-animated bg-danger w-100`}></div>
                        </div>
                        <small>Firma kapatma başvurusunu buradan yapabilirsiniz.</small>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

const mapStateToProps = (state) => ({
    company: state.company.remainOfActiveCompany,
    activeCompany: state.company.activeCompany

})

const mapDispatchToProps = { SET_UPDATE_ACTIVE_COMPANY, SET_UPDATE_REMAIN_OF_COMPANY }

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettings)