import React from 'react'
import Loading from './loading'
export default function checking(props) {
    return (
        <div className='checking-container'>
            <div className='card bg-info checking-card p-4'>
                <p className='text-center text-white'>{props.description}</p>
                <div className='mt-4'>
                    <Loading></Loading>
                </div>
            </div>
        </div>
    )
}
