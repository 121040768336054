const INITIAL_STATE = {
    customers: [],
    count: 0,
    isLoadingAction: false,
    isLoading: false,
    alertBox: {
        status: false,
        message: '',
        variant: 'success'
    }
}
export const CustomersReducer = (state = INITIAL_STATE, action) => {
    const customers = state.customers;
    switch (action.type) {
        case 'SET_CUSTOMER_LIST':
            return { ...state, customers: action.payload.data, count: action.payload.count, isLoading: false }
        case 'SET_LOADING_WHEN_INSERTING_CUSTOMER':
            return { ...state, isLoadingAction: action.payload }
        case 'SET_LOADING_CUSTOMER':
            return { ...state, isLoading: action.payload }
        case 'SET_THAT_CUSTOMER_OF_INSERTED_TO_LIST':
            if (action.payload.alertBox.status && action.payload.alertBox.variant === 'success')
                customers.push(action.payload.data)
            return { ...state, customers, alertBox: action.payload.alertBox }
        case 'SET_THAT_CUSTOMER_OF_UPDATED_TO_LIST':
            if (action.payload.alertBox.status && action.payload.alertBox.variant === 'success') {
                const p = customers.findIndex(w => w.Id === action.payload.data.Id);
                if (p !== -1)
                    customers[p] = action.payload.data;
            }

            return { ...state, customers, alertBox: action.payload.alertBox }
        case "SET_REMOVE_CUSTOMER_LIST":
            if (action.payload.Id !== -1) {
                const index = customers.findIndex(l => l.Id === action.payload.data.id);
                customers.splice(index, 1);
            }
            return { ...state, customers: customers, isLoadingAction: false, alertBox: { status: 'remove', message: action.payload.message, variant: action.payload.variant } }

        case 'SET_ALERT_BOX':
            return { ...state, alertBox: action.payload }
        default:
            return state
    }
}