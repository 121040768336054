const INITIAL_STATE = {
    roomsList: [],
    roomStats: [],
    roomIsLoading: true,
    roomStatsIsLoading: true,
    alertBox: {
        status: 'null',
        variant: 'success',
        message: 'ok'
    }
}
export const RoomsReducer = (state = INITIAL_STATE, action) => {
    const roomsList = state.roomsList
    const roomStats = state.roomStats
    switch (action.type) {
        case "SET_PROCESSING_LOADING":
            return { ...state, [action.payload.key]: action.payload.value, alertBox: { status: 'null', message: 'ok', variant: 'success' } }
        case 'SET_ROOMS_LIST':
            return {
                ...state, roomsList: action.payload.map(p => {
                    return {
                        ...p,
                        roomStats: { status: false }
                    }
                }), roomIsLoading: false
            };
        case 'SET_ADD_ROOM':
            roomsList.push(action.payload)
            return { ...state, roomsList, roomIsLoading: false };
        case 'SET_ROOM_STATS':
            roomStats.push(action.payload);
            return { ...state, roomStats, roomStatsIsLoading: false };
        case 'SET_UPDATE_ROOM_W_INDEX':
            const p = action.payload.index
            roomsList[p] = { ...roomsList[p], ...action.payload.data }
            return { ...state, roomsList, roomIsLoading: false };
        default:
            return state
    }
}