import React from 'react'
import { connect } from 'react-redux'
import ProcessingChild from '../components/main-components/Processing/ProcessingChild'

export const Processing = (props) => {
  return (
    <div>
      <h3>Hizmetlerim</h3>
      <ProcessingChild></ProcessingChild>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Processing)