import React from 'react'
import { connect } from 'react-redux'
import CustomersTagChild from '../components/main-components/CustomersTag/ProcessingChild'

export const CustomersTag = (props) => {
  return (
    <div>
      <h3>Müşteri Etiketlerim</h3>
      <CustomersTagChild></CustomersTagChild>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersTag)