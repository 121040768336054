import React, { useState } from 'react'
import currency from '../../../plugins/SET_CURRENCY';
import { Button, Modal } from 'react-bootstrap';

export default function CardItemOfRezervation({ item, visiblePrice, GET_DETAIL }) {
    const [showNote, setShowNote] = useState(false)
    const [modalNote, setModalNote] = useState(false)
    function SET_NOTE_MODAL(note) {
        setModalNote(note);
        setShowNote(true);
    }
    function CONVERT_TO_TR_DATE(date) {
        return new Date(date).toLocaleDateString('tr-TR', { day: '2-digit', month: 'long', year: 'numeric' })
    }
    function CONVERT_TO_TIME(n) {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return (rhours < 10 ? `0${rhours}` : rhours) + ":" + (rminutes < 10 ? `0${rminutes}:00` : rminutes + ':00');
    }

    console.log(item);
    return (
        <div>
            <div className='row my-2 py-2 border-bottom' onClick={(e) => GET_DETAIL(e)}>
                <div className='col-9 my-2 d-flex align-items-start flex-column'>
                    <h4 className={`${item.IsCompleted === 1 ? 'text-success' : item.IsCanceled === 1 ? 'text-danger' : 'text-secondary'}`}>
                        <i className={`mx-2 ${item.IsCompleted === 1 ? 'fas fa-check' : item.IsCanceled === 1 ? 'fas fa-times' : 'fas fa-clock'}`}></i>
                        {CONVERT_TO_TR_DATE(item.RezervationDate)} {CONVERT_TO_TIME(item.RezervationTime)}</h4>
                    <div><small className='text-light'>tarihinde rezervasyonu gerçekleştirildi.</small></div></div>
                <div className='col-3 my-2 col-md-2 col-lg-3  d-flex align-items-start flex-column'>
                    <h4> <span className={`${item.IsCompleted === 1 ? 'text-success' : item.IsCanceled === 1 ? 'text-danger' : 'text-secondary'} ${!visiblePrice ? 'blured' : ''}`}>{currency(item.Price, item.Currency)}</span></h4>
                    <div><small className='text-light'>yaklaşık {item.Duration}dk. sürdü.</small></div>
                </div>
                <div className='col-9 my-2 col-md-9 col-lg-9 d-flex align-items-center'>
                    {
                        item.ProcessesData.map(pData => (
                            <span className='badge badge-info mx-2'>{pData.Name}</span>
                        ))
                    }
                </div>
                <div className='col-6 my-2 col-md-3 col-lg-3 d-flex align-items-center'>
                    <div> {
                        item.IsCompleted === 1 ?
                            <span className='badge badge-success mx-2'>İşlem tamamlandı.</span> :
                            item.IsCanceled === 1 ?
                                <span className='badge badge-danger mx-2'>İşlem iptal edildi.</span> :
                                item.IsCreateRequest === 1 ?
                                    <span className='badge badge-info mx-2'>Yeni rezervasyon talebi.</span> :
                                    item.IsCancelRequest === 1 ?
                                        <span className='badge badge-warning mx-2'>İptal talebi edildi.</span> :
                                        <span className='badge badge-secondary mx-2'>Randevu bekliyor.</span>
                    }</div>
                    {item.Note !== '' ? <Button id='note-of-rezervation' style={{ zIndex: 100 }} onClick={() => SET_NOTE_MODAL(item.Note)} title="Rezervasyon notu bulunmaktadır." size='sm' className='mx-1' variant='info'><i id='note-of-rezervation' className='fas fa-envelope'></i></Button> : ''}
                    {item.CancelText !== '' ? <Button id='note-of-rezervation' style={{ zIndex: 100 }} onClick={() => SET_NOTE_MODAL(item.CancelText)} title="Rezervasyon iptal talep notu bulunmaktadır." size='sm' className='mx-1' variant='danger'><i id='note-of-rezervation' className='fas fa-notes-medical'></i></Button> : ''}
                </div>
            </div >
                <Modal centered className="fade" show={showNote}>
                    <Modal.Header>
                        <Modal.Title>Bilgilendirme</Modal.Title>
                        <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setShowNote(false)}
                        >

                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{modalNote}</p>
                    </Modal.Body>
                </Modal>
        </div>
    )
}
