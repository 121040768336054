import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Logo from '../../components/shared/logo'
import { SET_REMOVE_COMPANY } from '../../store/module/company/action'
import { SET_LOGOUT } from '../../store/module/users/action'
export const Tracking = (props) => {
    function SET_REMOVE_COMPANY_IN_COMP(logout = false) {
        props.SET_REMOVE_COMPANY();
        localStorage.removeItem('company');
        if (!logout)
            props.history.push('/checking-company');
    }
    function LOGOUT() {
        SET_REMOVE_COMPANY_IN_COMP(true);
        props.SET_LOGOUT(props.history)
    }
    return (
        <div className='checking-container'>
            <div className='card'>
                <div className='text-center card-body mt-5'>
                    <h1 className=' my-5'><i className='flaticon-381-add-1 text-secondary'></i></h1>
                    <h4>Merhaba {props.user.Fullname},</h4>
                    <h3 className='text-secondary'>Firmanız Onay Sürecindedir.</h3>
                    <div className='alert alert-secondary my-5'>
                        <p>
                            Rezzervasyon uygulaması firma başvurunuz onay sürecindedir. Onay süreci tamamlandıktan sonra uygulamamızı kullanabileceksiniz!
                        </p>                
                        <p>
                            Onay süreci için sizin ile iletişime geçilecektir.
                        </p> 
                        <p>
                            - Rezzervasyon App
                        </p> 
                    </div>
                    <div className='mt-5'>
                        {
                            props.companyList.length > 1 ?
                                <button onClick={() => SET_REMOVE_COMPANY_IN_COMP()} className='btn btn-secondary mx-2'>Firma Değiştir</button> : ''
                        }
                        <button onClick={() => LOGOUT()} className='btn btn-primary mx-2'>Çıkış Yap</button>
                    </div>
                    <div className='mt-5'>
                        <Logo></Logo>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    companyList: state.user.activeUser.Companies,
    user: state.user.activeUser.User
})

const mapDispatchToProps = {
    SET_REMOVE_COMPANY, SET_LOGOUT
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tracking))