import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap';
import EditProcessing from './EditProcessing';
import Currency from '../../../../plugins/SET_CURRENCY'
import { SET_REMOVE_CUSTOMERS_TAG } from '../../../../store/module/customersTag/action';
import Loading from '../../../shared/loading';
export const ListOfProcessingItem = (props) => {
    const [EditModal, setEditModal] = useState(false);
    const [RemoveModal, setRemoveModal] = useState(false);
    let [index, setIndex] = useState(-1);
    useEffect(() => {
        setIndex(props.list.findIndex(l => l.Id === props.item.Id)) // search yapıldığı için indexi bu şekilde bulunmalı
    }, [])
    function SET_REMOVE_CUSTOMERS_TAG() {
        props.SET_REMOVE_CUSTOMERS_TAG(index, props.item.Id);
    }
    return (
        <div className='row justify-content-between my-2 py-2 border-bottom'>
            <div className='col-9 my-2 col-md-4 col-lg-4 d-flex align-items-start flex-column'>
                <div> <i className='flaticon-381-success text-success'></i> {props.item.Name}</div>
            </div>
            <div className='col-6 my-2 col-md-4 col-lg-3 d-flex align-items-center'>
                {
                    props.item.CompanyId !== 0 ?
                    <>
                        <button className='btn btn-warning btn-xs mx-1' onClick={() => setEditModal(true)}>Düzenle</button>
                        <button className='btn btn-danger btn-xs mx-1' onClick={() => setRemoveModal(true)}><i className='flaticon-381-trash-1'></i></button>

                    </> : <i className='text-primary'>RezzervasyonApp tarafından oluşturulmuştur.</i>
                }
            </div>
            <Modal centered className="fade" onHide={() => setEditModal(false)} show={EditModal}>
                <Modal.Header>
                    <Modal.Title>{props.item.Name} hizmetini güncelle</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setEditModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    <EditProcessing index={index} item={props.item}></EditProcessing>
                </Modal.Body>
            </Modal>
            <Modal centered className="fade" onHide={() => setRemoveModal(false)} show={RemoveModal}>
                <Modal.Header>
                    <Modal.Title>{props.item.Name} hizmetini kaldır</Modal.Title>
                    <button
                        className="btn btn-close"
                        onClick={() => setRemoveModal(false)}
                    >

                    </button>
                </Modal.Header>
                <Modal.Body>
                    {
                        props.alertBox.status === 'remove' ?
                            <div className={`alert alert-${props.alertBox.variant}`}> {props.alertBox.message}</div> :
                            <p>
                                {props.item.Name} hizmetinizi kaldırmak üzeresiniz. Bu işleme devam ederseniz hizmetiniz tamamen kaldırılacaktır.
                                <br></br><br></br> <strong className='text-secondary'> Bunu yapmak istediğiniz Emin misiniz?</strong>
                            </p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={() => setRemoveModal(false)}
                        className="btn btn-light btn-xs"
                    >
                        Kapat
                    </button>
                    <button onClick={() => SET_REMOVE_CUSTOMERS_TAG()} className='btn btn-secondary btn-xs d-flex'>
                        {
                            props.isLoading ?
                                <Loading size="sm"></Loading>
                                : ''
                        } Evet, Hizmeti Kaldır

                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    alertBox: state.customersTag.alertBox,
    isLoading: state.customersTag.isLoadingRemove,

})

const mapDispatchToProps = { SET_REMOVE_CUSTOMERS_TAG }

export default connect(mapStateToProps, mapDispatchToProps)(ListOfProcessingItem)