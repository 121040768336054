const INITIAL_STATE = {
    activePackage: {},
    connectedPackage: true
}
export const PackageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_ACTIVE_USER":
            window.$api.token = action.payload.token; // set token on global
            const history = action.payload.history
            setTimeout(() => {
                history.push('/')
            }, 1000);
            return { ...state,  appLoading: false, isLogged: true, activeUser: action.payload.data, loginAlert: { status: true, message: "Login has been done succesfully", variant: 'success' }, loginAlertMessage: "Login has been done succesfully.", isLoadingInLoginPage: false };
        case "SET_INVALID_LOGIN":
            return { ...state, appLoading: false, isLogged: false, activeUser: {}, loginAlert: { status: true, message: action.payload, variant: 'danger' }, isLoadingInLoginPage: false };
        case "SET_LOADING_ON_LOGIN_PAGE":
            return { ...state, isLoadingInLoginPage: action.payload, loginAlert: { status: false, message: "", variant: "" } };
        default:
            return state
    }
}