import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../../shared/loading';
import AsyncSelect from 'react-select/async';
import { theme } from '../../../plugins/select.theme';
import { GET_CUSTOMERS_TAG_LIST, SET_REMOVE_CUSTOMERS_TAG } from '../../../store/module/customersTag/action';
import InsertProcessing from '../CustomersTag/components/InsertProcessing';
import { Modal } from 'react-bootstrap';
export const CustomerTags = (props) => {
    const [CustomerTagModal, setCustomerTagModal] = useState(false);
    const [isLoading, setLoading] = useState(true);
    let [alertBox, setAlertBox] = useState({ status: false, message: '', variant: 'danger' });
    const [customerTags, setCustomerTags] = useState([])
    async function GET_CUSTOMER_TAGS() {
        setLoading(true);
        const response = await window.$api.get(`CustomerTags/GetAll?customerId=${props.customer.Id}`);
        if (response.message === 'OK' && response.result === 'OK') {
            if (response.data === null)
                setCustomerTags([]);
            else
                setCustomerTags(response.data);
        } else
            setCustomerTags([]);
        setLoading(false);
    }
    function CLEAR_ALERT() {
        setTimeout(() => {
            setAlertBox({
                variant: 'danger',
                status: false,
                message: ''
            })
        }, 3500);
    }
    async function SET_CUSTOMER(data) {
        setAlertBox({
            variant: 'danger',
            status: false,
            message: ''
        })
        setLoading(true);
        if (customerTags.find(t => t.TagId === data.Id) === undefined) {
            const response = await window.$api.post('CustomerTags/Create', {
                "CustomerId": props.customer.Id,
                "TagId": data.Id
            })
            if (response.message === 'OK' && response.result === 'OK') {
                setCustomerTags([...customerTags, {
                    "CustomerId": props.customer.Id,
                    "TagId": data.Id,
                    "Name": data.Name,
                    "CustomerTagId": response.data
                }])
                setAlertBox({
                    variant: 'success',
                    status: true,
                    message: 'Müşteri etiketiniz başarıyla eklendi.'
                })
            }
            setLoading(false);
            CLEAR_ALERT()

        }
    }
    async function SET_REMOVE_CUSTOMERS_TAG(id, index) {
        setAlertBox({
            variant: 'danger',
            status: false,
            message: ''
        })
        setLoading(true);
        const response = await window.$api.delete(`CustomerTags/${id}`);
        if (response.message === 'OK' && response.result === 'OK') {
            const d = [...customerTags];
            d.splice(index, 1)
            setCustomerTags(d);
            setAlertBox({
                variant: 'success',
                status: true,
                message: 'Müşteri etiketiniz başarıyla kaldırıldı.'
            })
        }
        setLoading(false);
        CLEAR_ALERT()

    }
    async function GET_TAGS(search, callback) {
        const response = await window.$api.get(`Tags/GetAll?searchKey=${search}`);
        if (response.message === 'OK' && response.result === 'OK') {
            callback(response.data)
        } else
            callback([])
    }


    useEffect(() => {
        GET_CUSTOMER_TAGS();
    }, [])
    return (
        <div>
            {
                isLoading ? <div className='text-center'>
                    <Loading variant='primary' description='Müşteri Etiketleri Yükleniyor...'></Loading>
                </div> :
                    <div>
                        {
                            alertBox.status ? <div className={`alert alert-${alertBox.variant}`}> {alertBox.message}</div> : ''
                        }
                        <h5 className='my-4'>Müşteri Etiketleri</h5>
                        <div className='row'>
                            {
                                customerTags.map((tag, i) => (
                                    <div className='col-6 col-lg-4'>
                                        <span className='badge badge-primary light m-2 d-flex align-items-center justify-content-between'>
                                            {tag.Name} {
                                                props.activeUser.RoleId === 4 ? '' : <button onClick={() => SET_REMOVE_CUSTOMERS_TAG(tag.CustomerTagId, i)} className='btn-close btn-xs text-white'></button>
                                            }
                                        </span>
                                    </div>
                                ))
                            }
                        </div>


                        {
                            props.activeUser.RoleId === 4 ? '' :
                                <div>
                                    <div className='alert alert-secondary my-4'>
                                        Müşteriye yeni etiket eklemek için aşağıdan etiket arayıp seçmelisiniz.
                                    </div>
                                    <div className='form-group my-4'>

                                        <label>Etiket Seçin</label>
                                        <AsyncSelect
                                            theme={theme}
                                            placeholder="Etiket Arayın.."
                                            cacheOptions
                                            getOptionLabel={(opt) => opt.Name}
                                            noOptionsMessage={() => 'Etiket Arayın..'}
                                            loadOptions={GET_TAGS}
                                            defaultOptions
                                            onChange={(e) => SET_CUSTOMER(e)}
                                        />
                                        <div> <small>* Eklemek istediğiniz müşteri etiketlerini buradan arayarak ekleyebilirsiniz.</small></div>
                                    </div>
                                    <hr></hr>
                                    <div className='alert alert-primary'>
                                        <div className='text-primary'>Eklemek istediğiniz etiket firmanızda bulunmuyorsa aşağıdan firmanıza yeni etiket ekleyebilirsiniz.</div>
                                        <div className='text-center my-4'>
                                            <button onClick={() => setCustomerTagModal(true)} className='btn btn-primary'>Yeni Etiket Ekle</button>
                                        </div>
                                        <div className='text-secondary text-center'>Etiketi ekledikten sonra eklenen etiketi müşteriye yeniden arayarak ekleyebilirsiniz.</div>

                                    </div>
                                </div>
                        }
                        <Modal centered className="fade" onHide={() => setCustomerTagModal(false)} show={CustomerTagModal}>
                            <Modal.Header>
                                <Modal.Title>Firmaya Yeni Etiket Ekle</Modal.Title>
                                <button
                                    className="btn btn-close"
                                    onClick={() => setCustomerTagModal(false)}
                                >
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                <InsertProcessing modal={true} success={setCustomerTagModal}></InsertProcessing>
                            </Modal.Body>
                        </Modal>
                    </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    activeUser: state.user.activeUser.User
})

const mapDispatchToProps = { GET_CUSTOMERS_TAG_LIST }

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTags)