import React from 'react'
import { connect } from 'react-redux'
import RemainPackageOfCompany from '../Dashboard/components/RemainPackageOfCompany'

export const CompanyPackage = (props) => {
    return (
        <div>
            <div className='card p-4'>
                <h5 className="bg-secondary p-2 px-4 rounded text-white">{props.remainOfActiveCompany.PackageRemain.Name}</h5>
                <div className="row mt-5">
                    <div className="col-6 col-lg-3 col-xl-3">
                        <div className="card p-3 d-flex align-items-center justify-content-center flex-column">
                            <h2 className={props.remainOfActiveCompany.PackageRemain.BranchCount < 2 ? 'text-danger' : props.remainOfActiveCompany.PackageRemain.BranchCount < 4 ? 'text-warning' : 'text-success'}>{props.remainOfActiveCompany.PackageRemain.BranchCount}</h2>
                            <p className="text-center"><small>Kalan Şube</small></p>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-xl-3">
                        <div className="card p-3 d-flex align-items-center justify-content-center flex-column">
                            <h2 className={props.remainOfActiveCompany.PackageRemain.RoomCount < 2 ? 'text-danger' : props.remainOfActiveCompany.PackageRemain.RoomCount < 4 ? 'text-warning' : 'text-success'}>{props.remainOfActiveCompany.PackageRemain.RoomCount}</h2>
                            <p className="text-center"><small>Kalan Oda</small></p>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-xl-3">
                        <div className="card p-3 d-flex align-items-center justify-content-center flex-column">
                            <h2 className={props.remainOfActiveCompany.PackageRemain.RezervationCount < 10 ? 'text-danger' : props.remainOfActiveCompany.PackageRemain.RezervationCount < 25 ? 'text-warning' : 'text-success'}>{props.remainOfActiveCompany.PackageRemain.RezervationCount}</h2>
                            <p className="text-center"><small>Kalan Rezzervasyon</small></p>
                        </div>
                    </div>
                    <div className="col-6 col-lg-3 col-xl-3">
                        <div className="card p-3 d-flex align-items-center justify-content-center flex-column">
                            <h2 className={props.remainOfActiveCompany.PackageRemain.SmsCount < 10 ? 'text-danger' : props.remainOfActiveCompany.PackageRemain.SmsCount < 25 ? 'text-warning' : 'text-success'}>{props.remainOfActiveCompany.PackageRemain.SmsCount}</h2>
                            <p className="text-center"><small>Kalan SMS</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-4 row justify-content-center'>
                <div className='col-12 col-md-6 col-lg-4'>
                    <RemainPackageOfCompany></RemainPackageOfCompany>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    remainOfActiveCompany: state.company.remainOfActiveCompany,

})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPackage)