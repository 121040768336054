import React, { useEffect, useState } from 'react'
import Loading from '../../shared/loading';
import CardItemOfRezervation from './CardItemOfRezervation';
import { Button } from 'react-bootstrap';
import EditRezervation from '../Rezervation/EditRezervation';
import Pagination from '../../shared/pagination'

export default function RezzervationsOfCustomer({ customerId, Customer }) {

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingDetail, setIsLoadingDetail] = useState(true);
    const [rezervationDetailModal, setRezervationDetailModal] = useState(false);
    const [visiblePrice, setVisiblePrice] = useState(true);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);
    const [detailRezervation, setDetailRezervation] = useState(null);
    async function GET_REZERVATIONS_OF_CUSTOMER(activePage = 1) {
        setIsLoading(true);
        const response = await window.$api.get(`Rezervations/ByCustomer?limit=${limit}&page=${activePage}&customerId=${customerId}`);
        console.log(response);
        if (response.message === 'OK') {
            setItems(response.data || []);
            setCount(response.count);
        }

        setIsLoading(false);
    }
    async function GET_DETAIL(item, event) {
        if (event.target.id === 'note-of-rezervation') return
        setRezervationDetailModal(false);
        setIsLoading(true);
        const response = await window.$api.get(`Rezervations/${item.Id}`);
        console.log(response);
        if (response.message === 'OK') {
            if (response.data.Id > 0) {
                const data = {
                    ...response.data,
                    Customer: Customer,
                    Room: await GET_ROOM(response.data.RoomId)
                }
                setDetailRezervation(data);
                console.log(data, "detaaaaiillll");
                setRezervationDetailModal(true);
            }
        }
        setIsLoading(false);
    }
    async function CHANGED_PAGE(page) {
        console.log(page);
        await setPage(page);
        GET_REZERVATIONS_OF_CUSTOMER(page)
    }
    async function GET_ROOM(roomId) {
        const response = await window.$api.get(`Rooms/${roomId}`);
        if (response.message === 'OK') return response.data || [];
        else return {}


    }
    function TOTAL_PAGE() {
        return Math.ceil(count / limit);
    }
    useEffect(() => {
        GET_REZERVATIONS_OF_CUSTOMER();
    }, [])



    return (
        <div className='card' id='listRezervation'>
            <div className='card-title p-4'>{Customer.Fullname} isimli müşteriniz <strong>{count} adet</strong> randevusu bulunuyor.</div>
            <div className='card-body'>
                {
                    isLoading ? <div className='text-center my-4'><Loading variant='primary'></Loading></div> :
                        items.length === 0 ? <div className='text-center'>Herhangi bir veri bulunmadı.</div> :
                            <div>
                                <div className='d-flex justify-content-end my-4'>
                                    <Button size='sm' onClick={() => setVisiblePrice(!visiblePrice)} variant={visiblePrice ? 'danger' : 'success'}>
                                        <i className={`fas fa-eye${visiblePrice ? '-slash' : ''}`}></i>
                                        Ücretleri {visiblePrice ? 'Gizle' : 'Göster'}</Button>
                                </div>
                                {
                                    items.map(item => (
                                        <CardItemOfRezervation GET_DETAIL={(e) => GET_DETAIL(item, e)} visiblePrice={visiblePrice} item={item}></CardItemOfRezervation>
                                    ))
                                }
                                <Pagination changedPage={CHANGED_PAGE} totalPage={TOTAL_PAGE()} page={page}></Pagination>

                            </div>
                }
                {detailRezervation !== null ? <EditRezervation
                    CustomerNotChangable={true}
                    room={detailRezervation.Room}
                    times={[]}
                    item={{ timeData: { rez: detailRezervation }, isDisabled: false }}
                    index={detailRezervation}
                    RezervationModal={rezervationDetailModal}
                    setRezervationModal={setRezervationDetailModal}
                    GET_REZERVATION_BY_ROOM={() => GET_REZERVATIONS_OF_CUSTOMER(page)}
                ></EditRezervation> : ''}

            </div>
        </div>
    )
}
